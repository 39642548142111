import * as React from 'react';

import {
    FlatList,
    View,
    Text,
    Image,
    ImageBackground,
    Share,
    ScrollView
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Icon,
    Button
} from 'react-native-elements';
import compaApi from '../apis/compa';
import theme, { themeVariables } from '../theme/styles';
import IconTextItem from '../components/IconTextItem';
import PointListItem from '../components/PointListItem';
import { useDispatch, useSelector } from 'react-redux';
import * as Sharing from 'expo-sharing';
import * as Location from 'expo-location';

const browserNavigator = navigator;


function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var deg2rad = function (deg) {
        return deg * (Math.PI / 180);
    }
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
}
  
  

export default function GrupoScreen ({ navigation, route }) {
    const group = route.params && route.params.group ? route.params.group : {};
    const dispatch = useDispatch();    
    const [location, setLocation] = React.useState(null);
    const [orderedPoints, setOrderedPoints] = React.useState([]);

    React.useEffect(() => {
        (async () => {
          let { status } = await Location.requestPermissionsAsync();
          if (status !== 'granted') {
            return;
          }
    
          let location = await Location.getCurrentPositionAsync({});
          setLocation(location);
        })();
    }, []);

    React.useEffect(() => {
        console.log('LOCATION', location);
        if (location && location.coords && Array.isArray(group.points)) {
          // latitud, longitud // en point igual
          console.log(group.points);
          let _points = group.points.slice(0);
          _points.forEach(p => {
            p.distance = Math.sqrt(Math.pow(location.coords.latitude - p.latitud, 2) + Math.pow(location.coords.longitude - p.longitud, 2));
          });
          _points.sort((a, b) => {
            return a.distance - b.distance;
          });
          console.log('_points', _points);
          setOrderedPoints(_points);
        } else {
            setOrderedPoints(group.points);
        }
      }, [location]);

    const onShare = async (group) => {
        // FIXME
        try {
            let message = 'CompaApp - Te comparto este grupo de compra: ' + group.nombre;
            const shareData = {
                title: 'CompaApp',
                text: message,
                // url: 'https://developer.mozilla.org',
            };
            /* const result = await Share.share({
                message: message,
                // url: url
            });
            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            } */
            await window.navigator.share(shareData);
        } catch (error) {
            // setShowShareUrl(true);
            console.log('share error', error);
            // alert('Compartir no está soportado en este navegador ... ' + JSON.stringify(error));
            // alert(browserNavigator.share);
        }
    }



    return (
        <View style={theme.screenContainer}>
            <ScrollView>
                <View>
                    <ImageBackground
                        source={{
                            uri: compaApi.generateImageUri(group.imagen) ? compaApi.generateImageUri(group.imagen) :require('../assets/logo.png')
                        }}
                        style={{
                            width: '100%',
                            height: 200
                        }}
                    >
                        <View 
                            style={{
                                shadowColor: '#999',
                                shadowOffset: { width: 0, height: 2 },
                                shadowOpacity: 0.5,
                                shadowRadius: 5,
                                elevation: 7,
                                width: 70,
                                height: 70,
                                position: 'absolute',
                                bottom: 20,
                                left: 26,
                                borderRadius: themeVariables.largeBorderRadius
                            }}
                        >
                            <Image 
                                source={{
                                    uri: compaApi.generateImageUri(group.logo) ? compaApi.generateImageUri(group.logo) :require('../assets/logo.png')
                                }}
                                style={{
                                    width: 70,
                                    height: 70,
                                    borderRadius: themeVariables.largeBorderRadius
                                }}
                            />
                        </View>
                    </ImageBackground> 
                </View>
                <View style={[theme.screenContainer, { paddingHorizontal: themeVariables.gutter }]}>
                    <Text style={[theme.header1, { marginBottom: 10, fontWeight: 400 }]}>{ group.nombre }</Text>
                    <Text style={[theme.leadingText, { marginBottom: 10 }]}>{ group.descripcion }</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                        {
                            group.pago_online && 
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 24 }}>
                                    <Icon
                                        name={'attach-money'}
                                        size={16} 
                                        color={'#139e30'}
                                    />
                                    <Text style={[theme.normalText, { color: '#139e30', fontSize: 12, marginLeft: 3 }]}>Pago Online</Text>
                                </View>
                        }
                        {
                            group.envios && 
                                <View style={{ flexDirection: 'row' }}>
                                    <Icon
                                        name={'truck'}
                                        size={16}
                                        type='font-awesome'
                                        color={'#911107'}
                                    />
                                    <Text style={[theme.normalText, { color: '#911107', fontSize: 12, marginLeft: 7 }]}>Envios hasta punto de retiro</Text>
                                </View>
                        }
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 16 }}>
                        <Button 
                            accessibilityRole="button"
                            type='clear'
                            icon={
                                <Icon
                                    name='share'
                                    size={themeVariables.actionIconsSize}
                                    color={themeVariables.textColor}
                                />
                            }
                            onPress={() => {
                                onShare(group)
                            }}
                            containerStyle={{
                                marginRight: 24
                            }}
                        />
                        {
                            group.telefono &&
                                <Button 
                                    type='clear'
                                    icon={
                                        <Icon
                                            name='whatsapp'
                                            type='font-awesome'
                                            size={themeVariables.actionIconsSize}
                                            color={themeVariables.textColor}
                                        />
                                    }
                                    onPress={() => {
                                        let tel = group.telefono;
                                        if (group.telefono.indexOf('+549') !== 0) {
                                            tel = '+549' + tel;
                                        }
                                        Linking.openURL('https://wa.me/' + tel);
                                    }}
                                />
                        }
                    </View>
                    {
                        (group.contacto || group.telefono || group.ubicacion) &&
                            <View>
                                <Text style={theme.header4}>Datos de contacto</Text>
                                { group.contacto && <IconTextItem iconName='person' text={group.contacto} /> }
                                { group.email && <IconTextItem iconName='mail' text={group.email} /> }
                                { group.telefono && <IconTextItem iconName='phone' text={group.telefono} /> }
                                { group.ubicacion && <IconTextItem iconName='map-marker' iconType='font-awesome' text={group.ubicacion} /> }
                            </View>
                    }
                    <View>
                        <Text style={theme.header4}>Elige tu punto de retiro</Text>
                        <FlatList
                            data={orderedPoints}
                            renderItem={({ item, index, separators }) => (
                                <PointListItem
                                    navigation={navigation}
                                    item={item}
                                    distanceInKm={location ? getDistanceFromLatLonInKm(location.coords.latitude, location.coords.longitude, item.latitud, item.longitud) : null}
                                    extraData={location}
                                />
                            )}
                            keyExtractor={item => item.id.toString()}
                        />

                    </View>
                </View>
            </ScrollView>
        </View>
    );
}