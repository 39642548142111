import { onAction } from './middleware/event-sync';
import compaApi from '../apis/compa';
import { tokenSet, TOKEN_LOADED } from './token.js';
import { getAllOrders, setResultOrders } from './orders.js';
import { loadOrdersFromDevice } from './localOrders';

export const AUTH = 'AUTH';
export const AUTH_LOADING = `[${AUTH}] loading`;
export const AUTH_LOADED = `[${AUTH}] loaded`;

export const AUTH_SET_USER = `[${AUTH}] set user`;

export const AUTH_SET_NOTIFICATION_READED = `[${AUTH}] set notification readed`;

export const AUTH_SET_REGISTER_RESULT = `[${AUTH}] set register result`;

export const AUTH_SET_REMEMBER_RESULT = `[${AUTH}] set remember result`;
export const AUTH_SET_REMEMBER_CHANGE_RESULT = `[${AUTH}] set remember change result`;

export const AUTH_SET_LOADING = `[${AUTH}] set loading`;

export const AUTH_SET_LOGIN_LOADING = `[${AUTH}] set login loading`;

export const AUTH_SET_RESULT = `[${AUTH}] set result`;

export const authLoaded = () => ({ type: AUTH_LOADED, payload: null });
export const authSetResult = (result) => ({
    type: AUTH_SET_RESULT,
    payload: result
});
export const authSetUser = (user) => ({ type: AUTH_SET_USER, payload: user });
export const authSetUserNotificationsReaded = () => ({ type: AUTH_SET_NOTIFICATION_READED, payload: null });

export const authLoading = (isLoading) => ({
    type: AUTH_SET_LOADING,
    payload: isLoading
});
export const authLoginLoading = (isLoading) => ({
    type: AUTH_SET_LOGIN_LOADING,
    payload: isLoading
});

export const authSetRegisterResult = (result) => ({
    type: AUTH_SET_REGISTER_RESULT,
    payload: result
});

export const authSetRememberResult = (result) => ({
    type: AUTH_SET_REMEMBER_RESULT,
    payload: result
});
export const authSetRememberChangeResult = (result) => ({
    type: AUTH_SET_REMEMBER_CHANGE_RESULT,
    payload: result
});
// Esta implementacion es muy propia de esta APP
// Mi recomnedacion el desacoplar la logica del workflow

export const doLogout = (user, password) => {
    return (dispatch) => {
        dispatch(authSetResult(null));
        dispatch(authSetUser(null));
        dispatch(tokenSet(null));
        dispatch(authLoading(false));
        dispatch(setResultOrders({}));
    };
}


export const doLogin = (user, password) => {
    return (dispatch) => {
        dispatch(authLoading(true));
        compaApi.auth
            .login({ email: user, password: password})
            .then((response) => {
                if (response.access_token) {
                    console.log('login response', response);
                    dispatch(authSetResult(null));
                    dispatch(authSetUser(response.user));
                    dispatch(tokenSet(response.access_token));
                    dispatch(authLoading(false));

                    //Load user orders
                    dispatch(getAllOrders());
                } else {
                    dispatch(authSetResult(response));
                    dispatch(authLoading(false));
                }
            })
            .catch((err) => {
                console.log('aca ..... -->', err);
                // dispatch(authSetResult(err));
                dispatch(authLoading(false));
                let errResponse = {
                    status: 'error', 
                    message: 'Ocurrió un problema, por favor vuelva a intentarlo más tarde.'
                }
                if (err && err.error === 'Unauthorized') {
                    errResponse.message = 'Email o contraseña incorrectas.';
                }
                dispatch(authSetResult(errResponse));
                // [TODO]
            });
    };
};

let recentlyRegister = false;

export const doRegister = (registerData) => {
    console.log('rdata', registerData);
    return (dispatch) => {
        dispatch(authLoading(true));

        compaApi.auth
            .register(registerData)
            .then((response) => {
                recentlyRegister = true;
                console.log('response register', response);
                dispatch(authSetUser(response.user));
                dispatch(tokenSet(response.access_token));
                dispatch(authSetRegisterResult(response));
                dispatch(authLoading(false));
                dispatch(authLoaded());
            })
            .catch(() => {
                dispatch(authLoading(false));
            });
    };
};

export const doRecoverPassword = (recoverData) => {
    return (dispatch) => {
        dispatch(authLoading(true));
        compaApi.auth
            .recoverPassword(recoverData)
            .then((response) => {
                console.log('response recover password', response);
                dispatch(authSetRememberResult(response));
                dispatch(authLoading(false));
            })
            .catch(() => {
                dispatch(authLoading(false));
                dispatch(authSetRememberResult({ error: 'error' }));
            });
    };
};

export const changePasswordWithCode = (changeData) => {
    return (dispatch) => {
        dispatch(authLoading(true));
        compaApi.auth
            .changePasswordWithCode(changeData)
            .then((response) => {
                console.log('response changePasswordWithCode', response);
                dispatch(authSetRememberChangeResult(response));
                dispatch(authLoading(false));
            })
            .catch(() => {
                dispatch(authLoading(false));
                dispatch(authSetRememberChangeResult({ error: 'error' }));
            });
    };
};

export const doRetoken = () => {
    return (dispatch) => {
        compaApi.auth
            .retoken()
            .then((response) => {
                console.log('retoken response', response);
                if (response && response.access_token) {
                    if (response) {
                        console.log('retoken response', response);
                        dispatch(authSetUser(response.user));
                        dispatch(tokenSet(response.access_token));
                        dispatch(getAllOrders());
                    }
                    dispatch(authLoading(false));
                    console.log('AUTH LOADED');
                    dispatch(authLoaded());
                } else {
                    dispatch(authLoading(false));
                    dispatch(authSetResult(response));
                    console.log('AUTH LOADED');
                    dispatch(authLoaded());
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(authLoaded());
                console.log('AUTH LOADED');
                dispatch(authSetResult(null));
            });
    };
};

export const getUserInformation = () => {
    return async (dispatch) => { };
};

export const updateUserInformation = (data, uid) => {
    return async (dispatch) => {};
};

export const updateDoNotShow = (user, serie) => {
    return async (dispatch) => {};
};


export const updateSeriesList = (user, serie, remove) => {
    return async (dispatch) => { };
};

export const addUserPoints = (user, point, rewardKey) => {
    return async (dispatch) => {};
};


const initialState = {
    user: null,
    loaded: false,
    login_loading: false,
    loading: false,
    result: null,
    rememberResult: null,
    rememberChangeResult: null
};


export function AuthReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case AUTH_SET_REMEMBER_CHANGE_RESULT:
            return { ...state, rememberChangeResult: payload };
        case AUTH_SET_REMEMBER_RESULT:
            return { ...state, rememberResult: payload };
        case AUTH_SET_RESULT:
            return { ...state, result: payload };
        case AUTH_SET_USER:
            return { ...state, user: payload };
        case AUTH_LOADED:
            return { ...state, loaded: true };
        case AUTH_SET_LOADING:
            return { ...state, loading: payload };
        case AUTH_SET_LOGIN_LOADING:
            return { ...state, login_loading: payload };
        case AUTH_SET_NOTIFICATION_READED:
            let _user = JSON.parse(JSON.stringify(state.user));
            if (Array.isArray(_user.notificaciones)) {
                _user.notificaciones.forEach(n => {
                    n.leida = true;
                });
            }
            return {
                ...state,
                user: _user
            }
        default:
            return state;
    }
}


// Debería llegar el token en el payload?
const checkToken = onAction(TOKEN_LOADED, (store, action) => {
    const { token } = store.getState().token;
    if (token) {
        console.log('DO RETOKEN', !recentlyRegister);
        if (!recentlyRegister) {
            store.dispatch(doRetoken());
        } else {
            setTimeout(() => {
                recentlyRegister = false;
            }, 1500);
        }
    } else {
        console.log('AUTH LOADED');
        store.dispatch(authLoaded());
    }
});

export const authMiddleware = [checkToken];
