import * as React from 'react';

import {
    View,
    Text,
    FlatList,
    ScrollView
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Icon,
    Button,
    Input
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import IconTextItem from '../../components/IconTextItem';
import HorarioItem from '../../components/HorarioItem';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPointOrders, getPointClaims, getPointPayments, getPointProducts, setDeliveredResult, getPointCampaings } from '../../redux/pointManagement';

import { getAllGroups } from '../../redux/groups';

import ProductListItem from '../../components/ProductListItem';

import UserOrderListItem from '../../components/PointManagement/UserOrderListItem';
import PointPaymentListItem from '../../components/PointManagement/PointPaymentListItem';
import { ToastAndroid } from 'react-native';
import ClaimListItem from '../../components/Claims/ClaimListItem';
import dayjs from 'dayjs';
import RNPickerSelect from 'react-native-picker-select';
import { map } from 'lodash';

export default function PointScreen({ navigation, route, changeTab }) {

    const point = route.params && route.params.point ? route.params.point : null;
    if (point && typeof point.horarios === 'string') {
        point.horarios = JSON.parse(point.horarios);
    }

    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const groups = useSelector(state => state.groups.groups);
    const pointOrders = useSelector(state => state.pointManagement.orders);
    const pointOrdersLoaded = useSelector(state => state.pointManagement.loadedOrders);
    const pointProducts = useSelector(state => state.pointManagement.products);
    const pointProductsLoaded = useSelector(state => state.pointManagement.loadedProducts);
    const pointClaims = useSelector(state => state.pointManagement.claims);
    const pointClaimsLoaded = useSelector(state => state.pointManagement.loadedClaims);
    const pointPayments = useSelector(state => state.pointManagement.payments);
    const pointPaymentsLoaded = useSelector(state => state.pointManagement.loadedPayments);
    const pointCampaings = useSelector(state => state.pointManagement.campaings);
    const pointCampaingsLoading = useSelector(state => state.pointManagement.isLoadingCampaings);

    const balance = calculateBalance(pointOrders);

    const [ currentPoint, setCurrentPoint ] = React.useState(null);
    const [ currentCampaing, setCurrentCampaing ] = React.useState(null);

    const [ pedidosCollapse, setPedidosCollapse ] = React.useState(true);
    const [ pagosCollapse, setPagosCollapse ] = React.useState(true);
    const [ reclamosCollapse, setReclamosCollapse ] = React.useState(true);
    const [ productosCollapse, setProductosCollapse ] = React.useState(true);

    const [ customerFilter, setCustomerFilter ] = React.useState('');


    React.useEffect(() => {
        dispatch(getPointCampaings());
        setTimeout(() => {
            dispatch(getAllPointOrders());
            setTimeout(() => {
                dispatch(getPointProducts());
                dispatch(getPointClaims());
                dispatch(getPointPayments()); 
            }, 300);
        }, 150);
        if (point) {
            setCurrentPoint(point);
        } else {
            let _point = null;
            if (groups && groups.length) {
                for (let index = 0; index < groups.length; index++) {
                    const group = groups[index];
                    _point = group.points && group.points.find(p => p.id == user.point_id);
                    if (_point) {
                        setCurrentPoint(_point);
                        break;
                    } else {
                    } 

                }
            } else {
                
            } 
        }
    }, []);

    React.useEffect(() => {
        if (currentCampaing && currentCampaing.id > 0) {
            setTimeout(() => {
                dispatch(getAllPointOrders(currentCampaing.id));
                setTimeout(() => {
                    dispatch(getPointProducts(currentCampaing.id));
                    dispatch(getPointClaims(currentCampaing.id));
                    dispatch(getPointPayments(currentCampaing.id)); 
                }, 300);
            }, 150);
        }
    }, [currentCampaing]);

    console.log("point", point, groups);


    return (
        <ScrollView style={{ paddingHorizontal: themeVariables.gutter, flex: 1, backgroundColor: 'white' }}>
            <Text style={theme.header4}>Balance actual: $ {balance}</Text>
            {
                pointCampaings && pointCampaings && pointCampaings.length > 0 &&
                    <View>
                        <RNPickerSelect
                            onValueChange={(value) => {
                                // setMetodoDePago(value)
                                setCurrentCampaing(pointCampaings.find(c => c.id == value))
                            }}
                            placeholder={{
                                label: 'Seleccione una campaña',
                                value: null,
                                color: 'red',
                            }}
                            style={{
                            inputWeb: {
                                fontSize: 16,
                                paddingHorizontal: 10,
                                paddingVertical: 8,
                                borderWidth: 1,
                                borderColor: '#999',
                                borderRadius: 8,
                                color: '#333',
                                paddingRight: 30, // to ensure the text is never behind the icon
                            }
                            }}
                            items={pointCampaings.map((c) => ({ label: c.nombre, value: c.id }))}
                        />
                    </View>
            }
            <View>

                <Text 
                    style={[theme.header4, { display: 'flex', flexDirection: 'row', alignItems: 'center'}]}
                    onPress={() => {
                        setPedidosCollapse(!pedidosCollapse);
                    }}
                >
                    <Text>Pedidos de la campaña </Text>
                    { pointPaymentsLoaded && (pointOrders.length > 0 || currentCampaing) &&
                        <Text> (Cierre el: { currentCampaing ? dayjs(currentCampaing.fin).format('DD/MM/YYYY') : dayjs(pointOrders[0].campaña.fin).format('DD/MM/YYYY') })</Text>
                    }
                    <Icon 
                        name={pedidosCollapse ? 'keyboard-arrow-down' : 'keyboard-arrow-up'}
                        color={themeVariables.mainComplementaryColor}
                        style={{ alignSelf: 'center' }}
                    />
                </Text>
                {
                    (
                        (pointOrders && pointOrders.length > 0 && pointOrders[0].campaña.fin >= dayjs().format('YYYY-MM-DD')) ||
                        (currentCampaing && currentCampaing.fin >= dayjs().format('YYYY-MM-DD'))
                    ) &&
                        <Button
                            title='Día de entrega'
                            containerStyle={[theme.buttonSuccessContainerStyle, { marginBottom: 30, borderRadius: 30 }]}
                            buttonStyle={[theme.buttonSuccessButtonStyle, { paddingVertical: 16, paddingRight: 26, fontWeight: 'bold' }]}
                            titleStyle={[theme.buttonSuccessTitleStyle]}
                            onPress={() => {
                                navigation.push('DeliveryOverviewScreen');
                            }}
                        />

                }
                { pointOrdersLoaded && !pedidosCollapse && 
                    <View>
                        <Input 
                            label="Filtrar por usuario"
                            placeholder="Escriba un nombre o parte de el"
                            value={customerFilter}
                            onChangeText={(text) => setCustomerFilter(text)}
                            inputContainerStyle={{ borderWidth: 1, borderColor: '#999' }}
                        />
                        {/* item.users.name */}
                        <FlatList
                            data={customerFilter ? pointOrders.filter(o => (o.users && o.users.name) ? o.users.name.match(new RegExp('\\w*' + customerFilter + '\\w*', 'gi')) : false) : pointOrders}
                            renderItem={({ item, index, separators }) => {
                                return (
                                    <UserOrderListItem
                                        navigation={navigation}
                                        item={item}
                                    />
                                )
                            }}
                            keyExtractor={(item, index) => index.toString()}
                        /> 
                    </View>
                }
                { pointPaymentsLoaded && pointOrders.length === 0 && !pedidosCollapse &&
                    <Text>No has recibido ningún pedido todavía.</Text>
                }
                <Text 
                    style={[theme.header4, { display: 'flex', flexDirection: 'row', alignItems: 'center'}]}
                    onPress={() => {
                        setPagosCollapse(!pagosCollapse);
                    }}
                >
                    Pagos
                    <Icon 
                        name={pagosCollapse ? 'keyboard-arrow-down' : 'keyboard-arrow-up'}
                        color={themeVariables.mainComplementaryColor}
                        style={{ alignSelf: 'center' }}
                    />
                </Text>
                { pointPaymentsLoaded && !pagosCollapse && 
                    <FlatList
                        data={pointPayments}
                        renderItem={({ item, index, separators }) => {
                            return (
                                <PointPaymentListItem
                                    navigation={navigation}
                                    item={item}
                                />
                            )
                        }}
                        keyExtractor={(item, index) => index.toString()}
                    /> 
                }
                { pointPaymentsLoaded && pointPayments.length === 0 && !pagosCollapse && 
                    <Text>No has recibido ningún pago todavía.</Text>
                }
                
                <Text 
                    style={[theme.header4, { display: 'flex', flexDirection: 'row', alignItems: 'center'}]}
                    onPress={() => {
                        setReclamosCollapse(!reclamosCollapse);
                    }}
                >
                    Reclamos
                    <Icon 
                        name={reclamosCollapse ? 'keyboard-arrow-down' : 'keyboard-arrow-up'}
                        color={themeVariables.mainComplementaryColor}
                        style={{ alignSelf: 'center' }}
                    />
                </Text>
                { pointClaimsLoaded && pointClaims && !reclamosCollapse &&
                    <FlatList
                        data={pointClaims}
                        renderItem={({ item, index, separators }) => {
                            return (
                                <ClaimListItem
                                    item={item}
                                    navigation={navigation}
                                    admin={true}
                                ></ClaimListItem>
                            )
                        }}
                        keyExtractor={item => item.id.toString()}
                    /> 
                }
                {
                    pointClaimsLoaded && pointClaims.length === 0 && !reclamosCollapse &&
                        <Text>No se han realizados reclamos todavía.</Text>
                }
                
                <Text 
                    style={[theme.header4, { display: 'flex', flexDirection: 'row', alignItems: 'center'}]}
                    onPress={() => {
                        setProductosCollapse(!productosCollapse);
                    }}
                >
                    Productos de la campaña
                    <Icon 
                        name={productosCollapse ? 'keyboard-arrow-down' : 'keyboard-arrow-up'}
                        color={themeVariables.mainComplementaryColor}
                        style={{ alignSelf: 'center' }}
                    />
                </Text>
                {pointProductsLoaded && pointProducts && !productosCollapse &&
                    <FlatList
                        data={pointProducts}
                        renderItem={({ item, index, separators }) => {
                            return (
                                <ProductListItem
                                    navigation={navigation}
                                    item={item}
                                />
                            )
                        }}
                        keyExtractor={item => item.id.toString()}
                    />
                }

                {/* {
                    currentPoint &&
                        <>
                            <Text style={theme.header1}>{currentPoint.nombre}</Text>
                            <View>
                                {
                                    (currentPoint.datos_responsable || currentPoint.telefono || currentPoint.ubicacion) &&
                                        <Text style={theme.header4}>Datos de contacto</Text>
                                }
                                { currentPoint.datos_responsable && <IconTextItem iconName='person' text={currentPoint.datos_responsable} /> }
                                { currentPoint.telefono && <IconTextItem iconName='phone' text={currentPoint.telefono} /> }
                                { currentPoint.ubicacion && <IconTextItem iconName='map-marker' iconType='font-awesome' text={currentPoint.ubicacion} />}   
                            </View>
                        </>
                }

                { currentPoint && currentPoint.horarios && 
                    <View>
                        <Text style={theme.header4}>Horarios</Text>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flex: 1 }}>
                                <HorarioItem label={'Lun'} time={currentPoint.horarios.lun} />
                                <HorarioItem label={'Mar'} time={currentPoint.horarios.mar} />
                                <HorarioItem label={'Mie'} time={currentPoint.horarios.mie} />
                                <HorarioItem label={'Jue'} time={currentPoint.horarios.jue} />
                                <HorarioItem label={'Vie'} time={currentPoint.horarios.vie} />
                            </View>
                            <View style={{ flex: 1 }}>
                                <HorarioItem label={'Sab'} time={currentPoint.horarios.sab} />
                                <HorarioItem label={'Dom'} time={currentPoint.horarios.dom} />
                            </View>
                        </View>
                    </View>
                } */}
            </View>
        </ScrollView>
    );
}

// TODO: mover esto al server side si implementamos cantidad_posible
function calculateBalance(orders) {
    let total = 0;
    orders.forEach(order => {
        if (order.estado == 0) {
            total -= order.precio_total;
        } else {
            total += order.precio_total
        }
    });
    return total;

}
