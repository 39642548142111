import * as React from 'react';
import { View } from 'react-native';

import {
    Text,
    TextInput
} from 'react-native';
import {
    Button,
    Icon,
    CheckBox,
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setPaymentType, uploadReceipt, setCartPaymentResult } from '../../redux/cart';
import MercadoPagoInfo from '../../components/MercadoPagoInfo'
import * as DocumentPicker from 'expo-document-picker';
import Toast from 'react-native-toast-message';

export default function FormOfPaymentScreen({ navigation, route }) {


    const order = useSelector(state => state.cart.order);
    const point = useSelector(state => state.cart.point);
    const group = useSelector(state => state.cart.group);
    const paymentType = useSelector(state => state.cart.paymentType);
    const paymentResult = useSelector(state => state.cart.paymentResult);
    const paymentLoading = useSelector(state => state.cart.paymentLoading);

    const [file, setFile] = React.useState(null);
    const [amount, setAmount] = React.useState(0);

    const dispatch = useDispatch();

    const totalizadorPrecio = (accumulator, currentValue) => accumulator + currentValue.precio;

    React.useEffect(() => {
        if (paymentResult && !paymentLoading) {
            Toast.show({
                type: 'info',
                text1: 'CompaApp - Pagos',
                text2: paymentResult
            });
            setTimeout(() => {
                // limpiamos el result para futuros usos
                dispatch(setCartPaymentResult(null));
                navigation.navigate('Cart');
            }, 500);
        }
    }, [paymentResult, paymentLoading]);

    return (
        <View>
            {
                paymentType == "mercadoPago" &&
                    <View style={{ marginVertical: 10, paddingHorizontal: 15 }}>
                        <Text style={theme.header4}>Transfiera por MercadoPago</Text>
                        <MercadoPagoInfo
                            data={{ cbu: 123, link: "google.com" }}
                        />
                    </View>
            }

            {
                paymentType == "bank" &&
                    <View style={{ marginVertical: 10, paddingHorizontal: 15 }}>
                        <Text style={theme.header4}>Realizar transferencia</Text>
                        <Text>
                            <Text style={{ fontWeight: 'bold' }}>CBU / CVU: </Text>
                            <Text selectable>123456789</Text>
                        </Text>
                    </View>
            }
            { 
                paymentType == "cash" &&
                    <View style={{ paddingHorizontal: 15 }}>
                        <Text style={theme.header4}>Confirmar pago en efectivo</Text>
                    </View>
            }




            {(paymentType == "bank" || paymentType == "mercadoPago"|| paymentType == "cash") &&
                <View style={{ marginVertical: 10, paddingHorizontal: 15 }}>
                    <Text style={{ fontWeight: 'bold' }}>Ingrese el monto del pago</Text>
                    <TextInput
                        keyboardType='numeric'
                        style={[theme.loginInputStyle, { paddingLeft: 5, borderColor: '#CCC', borderWidth: 1, backgroundColor: 'white' }]}
                        placeholderTextColor={themeVariables.mainColor}
                        onChangeText={(text) => setAmount(text)}
                        value={amount}
                        maxLength={15}  //setting limit of input
                    />
                </View>
            }


            {(paymentType == "bank" || paymentType == "mercadoPago") &&
                <Text style={{ fontWeight: 'bold', paddingHorizontal: 15 }}>{ file ? file.name : "Cargar el archivo del comprobante de pago" }</Text>}

            {(paymentType == "bank" || paymentType == "mercadoPago") &&
                <Button
                    title='seleccionar archivo'
                    containerStyle={[theme.buttonSuccessContainerStyle, { paddingHorizontal: 10 }]}
                    buttonStyle={[theme.buttonSuccessButtonStyle, { height: 44, backgroundColor: 'rgb(32, 137, 220)' }]}
                    titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                    /* icon={
                        <Icon
                            name='arrow-back'
                            color='white'
                            size={14}
                            style={{ paddingRight: 4 }}
                        />
                    } */
                    onPress={() => {
                        DocumentPicker.getDocumentAsync().then((f) => {
                            let file = {
                                name: f.name,
                                uri: f.uri
                            }
                            setFile(file);
                        })
                    }}
                />
            }

            <View style={{ paddingHorizontal: 15 }}>
                <Text style={{ fontSize: 20, marginTop: 10 }}>
                    <Text>Total pedido: </Text>
                    <Text style={{ fontWeight: 'bold', color: 'red' }}>$ {order ? order.linea_pedidos.reduce(totalizadorPrecio, 0) : 0}</Text>
                </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <Button
                    title='Seguir comprando'
                    type="clear"
                    containerStyle={[theme.buttonSuccessContainerStyle, { width: '49%', paddingLeft: '1%'}]}
                    buttonStyle={[theme.buttonSuccessButtonStyle, { height: 44 }]}
                    titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                    icon={
                        <Icon
                            name='arrow-back'
                            color='white'
                            size={14}
                            style={{ paddingRight: 4 }}
                        />
                    }
                    onPress={() => {
                        navigation.pop();
                    }}
                />
                {(paymentType == "bank" || paymentType == "mercadoPago") &&
                    <Button
                        title='confirmar pago'
                        containerStyle={[theme.buttonSuccessContainerStyle, { width: '50%'}]}
                        buttonStyle={[theme.buttonSuccessButtonStyle, { backgroundColor: themeVariables.mainComplementaryColor, height: 44 }]}
                        titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                        icon={
                            <Icon
                                name='arrow-forward'
                                color='white'
                                size={14}
                                style={{ paddingRight: 4 }}
                            />
                        }
                        onPress={() => {
                            dispatch(uploadReceipt(order.id, paymentType,amount,file));
                        }}
                    />
                }
                { paymentType == "cash" &&
                    <Button
                        title='Confirmar pago en efectivo'
                        containerStyle={[theme.buttonSuccessContainerStyle, { width: '45%', paddingLeft: 10 }]}
                        buttonStyle={[theme.buttonSuccessButtonStyle, { backgroundColor: themeVariables.mainComplementaryColor, height: 44 }]}
                        titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                        icon={
                            <Icon
                                name='arrow-forward'
                                color='white'
                                size={14}
                                style={{ paddingRight: 4 }}
                            />
                        }
                        onPress={() => {
                            dispatch(uploadReceipt(order.id, paymentType,amount,null));
                        }}
                        loading={paymentLoading}
                    />
                }
            </View>
        </View>
    );
}