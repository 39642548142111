import * as React from 'react';
import {
    View, Text
} from 'react-native';
import {
    Icon
} from 'react-native-elements';
import theme, { themeVariables } from '../theme/styles';
export default function IconTextItem ({ iconName, iconType, text }) {
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
            <Icon 
                name={iconName}
                size={themeVariables.detailIconSize}
                color={themeVariables.mainColor}
                type={iconType}
                containerStyle={{
                    width: 28,
                    marginRight: 8
                }}
            />
            <Text style={[theme.normalText]}>{ text }</Text>
        </View>
    );
}