import * as React from 'react';
import { useState } from 'react';
import { FlatList, Text, View, StyleSheet, Image, TextInput } from 'react-native';;
import {
    Button
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import compaApi from '../../apis/compa';
import { useDispatch, useSelector } from 'react-redux';
import { addToClaim, getAllClaims } from '../../redux/claims';

export default function AddLineToClaimListItem({ item, navigation }) {
    const dispatch = useDispatch();
    const [description, setDescription] = useState('');
    return (
        <View style={{ flexDirection: 'column', marginBottom: 15, paddingBottom: 15, borderBottomWidth: 1, borderBottomColor: '#CCC', paddingTop: 4 }}>
            <View style={{ flexDirection: 'row' }}>
                <Image
                    source={{
                        uri: compaApi.generateImageUri(item.producto_campaña.logo) ? compaApi.generateImageUri(item.producto_campaña.logo) : require('../../assets/logo.png')
                    }}
                    style={{
                        width: 75,
                        height: 75,
                        borderRadius: themeVariables.lowBorderRadius
                    }}
                />
                <View>
                    <Text style={[theme.header5, { marginTop: 0 }]}>{item.producto_campaña.nombre}</Text>
                    <Text style={theme.leadingText}>Pedido: {item.cantidad_pedido}</Text>
                </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ width: 75, height: 75 }}></View>
                <View>
                    <TextInput
                        style={{ height: 40, borderColor: 'gray', borderWidth: 1 }}
                        onChangeText={text => setDescription(text)}
                        value={description}
                    />
                    <Button
                        title='Agregar al reclamo'
                        containerStyle={[theme.buttonSuccessContainerStyle]}
                        buttonStyle={[theme.buttonSuccessButtonStyle]}
                        titleStyle={[theme.buttonSuccessTitleStyle]}
                        onPress={() => {
                            dispatch(addToClaim(item.id,description));
                            dispatch(getAllClaims());
                            navigation.pop();
                        }}
                    />
                </View>
            </View>
        </View>
    );
}
