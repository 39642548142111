// Create an instance.
export default class GroupManagementApi {
    constructor(baseUrl, network) {
        this.baseUrl = baseUrl;
        this.token = null;
        this.network = network;
    }

    //   getAllGroupOrders() {
    //     return new Promise((resolve, reject) => {
    //       this.network.get(this.baseUrl + '/points/admin/pedidos', null, this.token).then((json) => {
    //         resolve(json);
    //       })
    //         .catch((error) => {
    //           reject(error);
    //         });
    //     });
    //   }
    
    getGroup() {
        return new Promise((resolve, reject) => {
            this.network.get(this.baseUrl + '/grupos/admin/grupo', null, this.token).then((json) => {
                resolve(json);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
    getGroupCampaigns() {
        return new Promise((resolve, reject) => {
            this.network.get(this.baseUrl + '/grupos/admin/campanas' , null, this.token).then((json) => {
                resolve(json);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    replaceProduct(data) {
        return new Promise((resolve, reject) => {
            this.network.post(this.baseUrl + '/grupos/admin/reemplazarProducto', data, this.token).then((json) => {
                resolve(json);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
    changeStock(data) {
        return new Promise((resolve, reject) => {
            this.network.post(this.baseUrl + '/grupos/admin/cambiarStock', data, this.token).then((json) => {
                resolve(json);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    //   getPointClaims() {
    //     return new Promise((resolve, reject) => {
    //       this.network.get(this.baseUrl + '/points/admin/reclamos', null, this.token).then((json) => {
    //         resolve(json);
    //       })
    //         .catch((error) => {
    //           reject(error);
    //         });
    //     });
    //   }
    //   approveClaimLine(data) {
    //     return new Promise((resolve, reject) => {
    //       this.network.post(this.baseUrl + '/points/admin/reclamos/aprobarlinea', data, this.token).then((json) => {
    //         resolve(json);
    //       })
    //         .catch((error) => {
    //           reject(error);
    //         });
    //     });
    //   }
}