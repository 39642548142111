import * as React from 'react';
import { confirmOrder, getAllOrders } from '../../redux/orders'
import {
    View,
    FlatList,
    Text,
    Image,
    ImageBackground,
    Share
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Divider,
    Button
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import { useSelector, useDispatch } from 'react-redux';
import ProductListItem from '../../components/ProductListItem';
import { approvePayment, downloadReceipt, getPointPayments, setPointStatePayment } from '../../redux/pointManagement';

import { displayPaymentStatusName, displayPaymentTypeName } from '../../utils/helpers';

import Toast from 'react-native-toast-message';

import {useState} from 'react';

import dayjs from 'dayjs';

export default function PoinPaymentScreen({ navigation, route }) {
    const paymentId = route.params && route.params.payment ? route.params.payment : (payment ? payment : {});
    const pointPayments = useSelector(state => state.pointManagement.payments);
    const pointPaymentsLoaded = useSelector(state => state.pointManagement.loadedPayments);

    const payment = pointPayments.filter(item => item.id == paymentId)[0];
    const dispatch = useDispatch();

    const statePayment = useSelector(state => state.pointManagement.statePayment);
    const pointPaymentsLoading = useSelector(state => state.pointManagement.isLoadingPayments);

    React.useEffect(() => {
        console.log(statePayment,pointPaymentsLoading);
        if (statePayment && !pointPaymentsLoading) {
            Toast.show({
                type: 'info',
                text1: 'CompaApp - Pago ',
                text2: statePayment
            });
            setTimeout(() => {
                // limpiamos el state para futuros usos
                dispatch(setPointStatePayment(null));
                navigation.navigate('PointManagement');
            }, 500);
        }
    }, [statePayment, pointPaymentsLoading]);

    return (
        <View style={{ padding: themeVariables.gutter, backgroundColor: 'color' }}>
            <Text style={theme.header4}>Nombre usuario: {payment.pedidos.users.name}</Text>
            <Text style={theme.header4}>Monto: $ {payment.monto}</Text>
            <Text style={theme.header4}>Fecha: { dayjs(payment.fecha).format('DD/MM/YYYY HH:mm') }</Text>
            <Text style={theme.header4}>Metodo: {displayPaymentTypeName(payment.metodo)}</Text>
            <Text style={theme.header4}>Estado: {displayPaymentStatusName(payment.estado)}</Text>
            <Divider />
            {   displayPaymentStatusName(payment.estado) == 'Pendiente' &&
                    <View style={{flexDirection: 'row', marginVertical: 5, justifyContent: 'space-between' }}>
                       <Button
                            title='Aprobar'
                            containerStyle={[theme.buttonSuccessContainerStyle, { width: '45%', paddingLeft: 10 }]}
                            buttonStyle={[theme.buttonSuccessButtonStyle, { backgroundColor: themeVariables.mainColor, height: 44 }]}
                            titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                            onPress={() => {
                                dispatch(approvePayment(payment.id, "aprobado"));
                            }}
                            />
                        <Button
                            title='Rechazar'
                            containerStyle={[theme.buttonSuccessContainerStyle, { width: '45%', paddingLeft: 10 }]}
                            buttonStyle={[theme.buttonSuccessButtonStyle, { backgroundColor: themeVariables.mainColor, height: 44 }]}
                            titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                            onPress={() => {
                                dispatch(approvePayment(payment.id, "rechazado"));
                            }}
                        />
                    </View>
            }
            <Divider />
            <Button
                title='Descargar comprobante'
                containerStyle={[theme.buttonSuccessContainerStyle, { width: '100%', paddingLeft: 10 }]}
                buttonStyle={[theme.buttonSuccessButtonStyle, { backgroundColor: themeVariables.mainColor, height: 44 }]}
                titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                onPress={() => {
                    dispatch(downloadReceipt(payment.id));
                }}
            />
        </View>
    )
}