
export function displayPaymentStatusName (state) {
    switch (state) {
        case 'pendiente':
            return 'Pendiente';
        case 'aprobado':
            return 'Aprobado';
        case 'rechazado':
            return 'Rechazado';
    }
    return '';
}
export function displayPaymentTypeName (state) {
    switch (state) {
        case 'bank':
            return 'Banco';
        case 'mercadoPago':
            return 'Mercado Pago';
        case 'cash':
            return 'Efectivo';
    }
    return '';
}
export function displayOrderStatusName (state) {
    switch (state) {
        case 0:
            return 'Pendiente';
        case 1:
            return 'Confirmado';
        case 2:
            return 'Cancelado';
        case 3:
            return 'Entregado';
        case 4:
            return 'Confirmado';
    }
    return '';
}

export function orderHasPendingPayments (order) {
    if (order && order.pagos && order.pagos.length) {
        return !!order.pagos.find(p => p.estado === 'pendiente');
    }
    return false;
}

export function totalizadorPeso (accumulator, currentValue) {
    let weight = currentValue.producto_campaña.peso_bulto * currentValue.cantidad_pedido;
    if (currentValue.producto_campaña.divisible > 1) {
        weight = currentValue.producto_campaña.peso_bulto * currentValue.cantidad_pedido / currentValue.producto_campaña.divisible;
    }
    return accumulator + weight;
};


export function totalizadorPrecio (accumulator, currentValue) {
    let price = currentValue.precio;
    return accumulator + price;
};



export function totalizadorPagos (accumulator, currentValue) {
    let price = 0;
    if (currentValue.estado === 'aprobado') {
        price += currentValue.monto;
    }
    return accumulator + price;
};




export function  totalizadorBultos (accumulator, currentValue) {
    let q = currentValue.cantidad_pedido;
    if (currentValue.producto_campaña.divisible > 1) {
        q = Math.ceil(currentValue.cantidad_pedido / currentValue.producto_campaña.divisible);
    }
    return accumulator + q;
}