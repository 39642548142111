import * as React from 'react';
import {
    View,
    FlatList, 
    Text,
    Image,
    ImageBackground,
    Share
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Icon,
    Button
} from 'react-native-elements';
import theme, { themeVariables } from '../theme/styles';
import { useSelector, useDispatch } from 'react-redux';
import ProductListItem from '../components/ProductListItem';
import { getPastOrders } from '../redux/orders';

import { totalizadorPeso, totalizadorPrecio } from '../utils/helpers';


//screen showing a user order to a point manager
// TODO: fix reactivity?
export default function PastOrderDetailScreen({ navigation, route}) {
    const orderId = route.params && route.params.order ? route.params.order : (order ? order : {});
    const orders = useSelector(state => state.orders.pastOrders);

    const order = Object.values(orders).filter(item => item.id == orderId)[0];
    const dispatch = useDispatch();


    React.useEffect(() =>{
        dispatch(getPastOrders());
    },[]);
    
    return (
          <View style={[theme.screenContainer, { paddingHorizontal: themeVariables.gutter }]}>
            <FlatList
                  data={order ? order.linea_pedidos : []}
                  renderItem={({ item, index, separators }) => {
                    return (
                      <ProductListItem
                        navigation={navigation}
                        item={item}
                        order={order}
                      />
                    )
                  }}
                  extraData={order}
                  keyExtractor={item => item.id.toString()}
              />

            <View style={{ paddingVertical: 10, borderTopWidth: 1, borderTopColor: '#DDD' }}>
                <Text style={{ fontWeight: 'bold' }}>Cantidad de productos: {order ? order.linea_pedidos.length : 0}</Text>
                <Text style={{ fontWeight: 'bold' }}>Peso total: {order ? order.linea_pedidos.reduce(totalizadorPeso, 0) : 0} kg.</Text>
                <Text style={theme.leadingHighlightText}>Total pedido: $ {order ? order.linea_pedidos.reduce(totalizadorPrecio, 0) : 0}</Text>
                <View style={{ flexDirection: 'row'}}>
                  {/* <Button 
                      title='seguir comprando'
                      containerStyle={[theme.buttonSuccessContainerStyle]}
                      buttonStyle={[theme.buttonSuccessButtonStyle]}
                      titleStyle={[theme.buttonSuccessTitleStyle]}
                      onPress={() => {
                        navigation.pop();
                      }}
                  />
                  <Button 
                      title='pagar/confirmar'
                      containerStyle={[theme.buttonSuccessContainerStyle]}
                      buttonStyle={[theme.buttonSuccessButtonStyle]}
                      titleStyle={[theme.buttonSuccessTitleStyle]}
                      onPress={() => {
                        dispatch(confirmOrder(order ? order.id : null))
                        //TODO: confirmar pedido
                      }}
                  /> */}
                </View>
            </View>

        </View>
    )
}
