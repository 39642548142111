
import compaApi from '../apis/compa';
import { onAction } from './middleware/event-sync';
import { storageGet, storageSet } from './middleware/async-storage';

export const PRODUCER = 'PRODUCER';

export const PRODUCER_LOADING = `[${PRODUCER}] loading`;

export const PRODUCER_LOADED = `[${PRODUCER}] loaded`;

export const PRODUCER_SET_RESULT_PRODUCTS = `[${PRODUCER}] set result PRODUCER`;


export const setResultProducts = (result) => ({
    type: PRODUCER_SET_RESULT_PRODUCTS,
    payload: result
});

export const setProducerLoading = (isLoading) => ({
    type: PRODUCER_LOADING,
    payload: isLoading
});

export const setProducerLoaded = (loaded) => ({
    type: PRODUCER_LOADED,
    payload: loaded
});

export const getProducts = () => {
    return (dispatch) => {
        dispatch(setProducerLoading(true));
        compaApi.producer
            .getProducts()
            .then((response) => {
                dispatch(
                    setResultProducts(response)
                );
                dispatch(setProducerLoading(false));
                dispatch(setProducerLoaded(true));
            })
            .catch(() => {
                dispatch(setProducerLoading(false));
            });
    };
};
export const pauseProduct = (id) => {
    return (dispatch) => {
        dispatch(setProducerLoading(true));
        let data = {
            producto: id
        }
        compaApi.producer
            .pauseProduct(data)
            .then((response) => {
                dispatch(getProducts());
                dispatch(setProducerLoading(false));
                dispatch(setProducerLoaded(true));
            })
            .catch(() => {
                dispatch(setProducerLoading(false));
            });
    };
};

const initialState = {
    products: [],
    loaded: true,
    isLoading: false
};

export function ProducerReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PRODUCER_SET_RESULT_PRODUCTS:
            return { ...state, products: payload };
        case PRODUCER_LOADED:
            return { ...state, loaded: payload };
        case PRODUCER_LOADING:
            return { ...state, isLoading: payload };
        default:
            return state;
    }
}


