import * as React from 'react';
import { FlatList, Text, View, StyleSheet, ActivityIndicator, ScrollView } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { getAllGroups, searchGroups } from '../redux/groups';
import theme, { themeVariables } from '../theme/styles';

import GrupoListItem from '../components/GrupoListItem';
import FavouritesListItem from '../components/FavouritesListItem';
import SearchGroup from '../components/SearchGroup';
import MapView from 'react-native-maps';

import { calcCenter } from '../utils/geolocation';
import * as Location from 'expo-location';

export default function HomeScreen ({ navigation }) {
    const dispatch = useDispatch();
    const groups = useSelector(state => state.groups.groups);
    const favorites = useSelector(state => state.favorites.favorites);
    const favoritesLoaded = useSelector(state => state.favorites.loaded);
    const loading = useSelector(state => state.groups.loading);
    const loaded = useSelector(state => state.groups.loaded);
    const isMapView = useSelector(state => state.groups.mapView);
    const user = useSelector(state => state.auth.user);

    const [location, setLocation] = React.useState(null);

    let map = null;
    const [groupSelected, setSelectedGroup] = React.useState(null);
    const [orderedGroups, setOrderedGroups] = React.useState(groups);

    React.useEffect(() => {
      dispatch(getAllGroups());
    }, []);

    React.useEffect(() => {
      (async () => {
        let { status } = await Location.requestPermissionsAsync();
        if (status !== 'granted') {
          return;
        }
  
        let location = await Location.getCurrentPositionAsync({});
        setLocation(location);
      })();
    }, []);

    React.useEffect(() => {
      console.log('LOCATION', location);
      if (location && location.coords && Array.isArray(groups)) {
        // latitud, longitud // en point igual
        let _groups = groups.slice(0);
        _groups.forEach(g => {
          g.distance = Math.sqrt(Math.pow(location.coords.latitude - g.latitud, 2) + Math.pow(location.coords.longitude - g.longitud, 2));
        });
        _groups.sort((a, b) => {
          return a.distance - b.distance;
        });
        setOrderedGroups(_groups);
      } else {
        console.log(groups);
        setOrderedGroups(groups);
      }
    }, [location, groups]);
   
    return (
        <View style={theme.screenContainer}>
            <View style={{ paddingHorizontal: 0,justifyContent: 'flex-start', flex: 1 }}>
                <SearchGroup />
                {
                  loading &&
                  <View style={theme.activityIndicator}>
                    <ActivityIndicator size='large' color={themeVariables.mainColor} />
                  </View>
                }
                {
                  loaded && isMapView && 
                    <View style={{ flex: 1 }}>
                      <MapView 
                        defaultZoom={10} 
                        region={calcCenter(groups.map(g => ({ latitude: g.latitud, longitude: g.longitud })), { latitude: -32, longitude: -65 })}
                        
                        options={{
                          mapTypeControl: false,
                          streetViewControl: false,
                          fullscreenControl: false,
                        }} 
                        ref={m => (map = m)} 
                      >
                        {
                          groups.map((g, index) => (
                            <MapView.Marker
                              key={index}
                              coordinate={{ latitude: g.latitud, longitude: g.longitud }}
                              title={g.nombre}
                              description={g.descripcion}
                              onPress={() => {
                                setSelectedGroup(g);
                              }}
                            />
                          ))
                        }
                      </MapView>
                      {
                        groupSelected &&
                          <View
                            style={{ 
                              width: '95%',
                              marginHorizontal: '2.5%', 
                              backgroundColor: 'white',
                              borderRadius: 15, 
                              position: 'absolute', 
                              bottom: 15,
                              elevation:10,
                              shadowColor: '#999',
                              shadowOffset: { width: 0, height: 2 },
                              shadowOpacity: 0.5,
                              shadowRadius: 5, 
                            }}
                          >
                            <GrupoListItem
                              navigation={navigation}
                              item={groupSelected}
                              topLeftRoundBorder={15}
                            />
                          </View>
                      }
                    </View>
                }
                {
                  !isMapView && 
                    <ScrollView style={{flex: 1}}>
                      {
                        user && 
                          <Text style={[theme.header5, { paddingHorizontal: themeVariables.gutter}]}>Hola, { user.name }. Bienvenido a Compa!</Text>
                      }
                      {
                        !user && 
                          <Text style={[theme.header5, { paddingHorizontal: themeVariables.gutter}]}>Hola, visitante. Bienvenido a Compa!</Text>
                      }
                      {
                        favoritesLoaded && favorites && favorites.length > 0 && 
                          <>
                            <Text style={[theme.header4, {paddingHorizontal: themeVariables.gutter}]}>Favoritos</Text>
                            {
                              favoritesLoaded &&
                                  <FlatList
                                      data={favorites}
                                      style= {{paddingLeft: themeVariables.gutter}}
                                      renderItem={({ item, index, separators }) => {
                                        let group = orderedGroups.filter((g) => (g.id === item.grupo_id));
                                        return (
                                          <FavouritesListItem
                                            navigation={navigation}
                                            item={item}
                                            group={group}
                                          />)
                                      }}
                                      keyExtractor={item => item.id.toString()}
                                      horizontal={true}
                                  />
                              }
                          </>
                      }
                      <Text style={[theme.header4, {paddingBottom: 16, paddingHorizontal: themeVariables.gutter}]}>
                        { (location && location.coords) ? 'Grupos de compras cercanos a ti' : 'Grupos de compra' }
                      </Text>
                      {
                        loaded && orderedGroups && orderedGroups.length > 0 &&
                            <FlatList
                              style={{paddingHorizontal: themeVariables.gutter, paddingBottom: themeVariables.endMargin }}
                              data={orderedGroups}
                              renderItem={({ item, index, separators }) => (
                                <GrupoListItem
                                  navigation={navigation}
                                  item={item}
                                />
                              )}
                              keyExtractor={item => item.id.toString()}
                          />
                      }
                    </ScrollView>
                }
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      marginTop: 60,
    },
    item: {
      backgroundColor: '#f9c2ff',
      padding: 20,
      marginVertical: 8,
      marginHorizontal: 16,
    },
    title: {
      fontSize: 32,
    },
  });
  