import { onAction } from './middleware/event-sync';
import { storageGet, storageSet } from './middleware/async-storage';

import compaApi from '../apis/compa';

export const TOKEN = 'TOKEN';
export const TOKEN_LOADING = `[${TOKEN}] loading`;
export const TOKEN_SET = `[${TOKEN}] set`;
export const TOKEN_LOADED = `[${TOKEN}] loaded`;

const initialState = {
    token: null,
    loaded: false
};

export const tokenSet = (token) => ({ type: TOKEN_SET, payload: token });
export const tokenLoaded = (token) => ({ type: TOKEN_LOADED, payload: null });

export const TokenReducer = (state = initialState, action) => {
    console.log('action', action);
    switch (action.type) {
        case TOKEN_SET:
            return {
                ...state,
                token: action.payload
            };
        case TOKEN_LOADED:
            return { ...state, loaded: true };
        default:
            return state;
    }
};

const tokenLoadingMD = onAction(TOKEN_LOADING, (store, action) => {
    store.dispatch(storageGet(TOKEN, TOKEN_SET));
});

const tokenStorageMD = onAction(TOKEN_SET, (store, action) => {
    const { payload: token } = action;
    if (token) {
        store.dispatch(storageSet(TOKEN, token));
        compaApi.setApiToken(token);
    }
    console.log('TOKEN_SET .....');
    if (!store.getState().token.loaded) {
        console.log('TOKEN_LOADED');
        store.dispatch(tokenLoaded());
    }
});

export const tokenMiddleware = [tokenLoadingMD, tokenStorageMD];
