import * as React from 'react';

import {
    View,
    Text,
    FlatList,
    ScrollView
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    ListItem,
    Button
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import { useDispatch, useSelector } from 'react-redux';
import CampaignProductListItem from '../../components/GroupManagement/CampaignProductListItem'
import { getGroup, getGroupCampaigns, getGroupProducts, setSelectedCampaign } from '../../redux/groupManagement';

import dayjs from 'dayjs';

export default function GroupManagementScreen({ navigation, route, changeTab }) {


    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);

    const group = useSelector(state => state.groupManagement.group);

    const productsLoaded = useSelector(state => state.groupManagement.loadedCampaigns)
    const campaigns = useSelector(state => state.groupManagement.campaigns);

    const selectedCampaign = useSelector(state => state.groupManagement.selectedCampaign);
    const products = selectedCampaign ? selectedCampaign.productos_campaña : null

    React.useEffect(() => {
        dispatch(getGroup());
        dispatch(getGroupCampaigns());
    }, []);

    return (
        <View style={{ padding: themeVariables.gutter, flex: 1, backgroundColor: 'white' }}>
            {
                group && 
                    <Text style={theme.header4}>Campañas del grupo: { group.nombre }</Text>
            }
            <ScrollView>
                {campaigns && <FlatList
                    data={campaigns}
                    horizontal={true}
                    renderItem={({ item, index, separators }) => {
                        return (
                            <View key={index} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <View>
                                    <Text>
                                        <Text style={{ fontWeight: 'bold' }}>{ item.nombre }</Text>
                                        <Text> - Cierra: { dayjs(item.fin).format('DD/MM/YY HH:mm') }</Text>
                                    </Text>
                                </View>
                                <Button
                                    title='Ver productos'
                                    containerStyle={[theme.buttonSuccessContainerStyle, { marginLeft: 5 }]}
                                    buttonStyle={[theme.buttonSuccessButtonStyle]}
                                    titleStyle={[theme.buttonSuccessTitleStyle]}
                                    onPress={() => {
                                        dispatch(setSelectedCampaign(item))
                                    }}
                                />
                            </View>

                        )
                    }}
                    keyExtractor={item => item.id.toString()}
                />}
                { productsLoaded && products && 
                    <Text style={theme.header5}>Productos de la campaña: { selectedCampaign.nombre }</Text>
                }
                { productsLoaded && products && <FlatList
                    data={products}
                    renderItem={({ item, index, separators }) => {
                        return (
                            <CampaignProductListItem
                                navigation={navigation}
                                item={item}
                                index={index}
                            />
                        )
                    }}
                    keyExtractor={item => item.id.toString()}
                />}
            </ScrollView>
        </View>
    );
}

