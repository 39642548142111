import compaApi from '../apis/compa';

export const CLAIM = 'CLAIM';

export const CLAIM_LOADING = `[${CLAIM}] claims`;

export const CLAIM_LOADED = `[${CLAIM}] loaded`;

export const CLAIM_SET_RESULT_CLAIM = `[${CLAIM}] set result claims`;

export const setResultClaims = (result) => ({
    type: CLAIM_SET_RESULT_CLAIM,
    payload: result
});

export const setClaimsLoading = (isLoading) => ({
    type: CLAIM_LOADING,
    payload: isLoading
});

export const setClaimsLoaded = (loaded) => ({
    type: CLAIM_LOADED,
    payload: loaded
});



export const getAllClaims = () => {
    return (dispatch) => {
        dispatch(setClaimsLoading(true));
        compaApi.claims
            .getAllClaims()
            .then((result) => {
                dispatch(
                    setResultClaims(result)
                );
                dispatch(setClaimsLoading(false));
                dispatch(setClaimsLoaded(true));
            })
            .catch(() => {
                dispatch(setClaimsLoading(false));
            });
    };
};

export const createClaim = (orderId) => {
    return (dispatch) => {
        dispatch(setClaimsLoading(true));
        let data = {
            pedido: orderId ? orderId : null
        }
        compaApi.claims
            .createClaim(data)
            .then((result) => {
                dispatch(getAllClaims());
            })
            .catch(() => {
                dispatch(setClaimsLoading(false));
            });
    }
}
export const addToClaim = (lineId, description) => {
    return (dispatch) => {
        dispatch(setClaimsLoading(true));
        let data = {
            linea: lineId ? lineId : null,
            descripcion: description
        }
        compaApi.claims
            .addToClaim(data)
            .then((result) => {
                dispatch(getAllClaims());
            })
            .catch(() => {
                dispatch(setClaimsLoading(false));
            });
    };
};
export const addImagesToClaim = (claimId, images) => {
    return (dispatch) => {
        dispatch(setClaimsLoading(true));
        let data = {
            reclamo: claimId ? claimId : null,
            imagenes: images
        };
        compaApi.claims
            .addImagesToClaim(data)
            .then((result) => {
                dispatch(getAllClaims());
            })
            .catch(() => {
                dispatch(setClaimsLoading(false));
            });
    };
};


const initialState = {
    claims: [],
    loaded: false,
    isLoading: false
};

export function ClaimsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CLAIM_SET_RESULT_CLAIM:
            return { ...state, claims: payload};
        case CLAIM_LOADED:
            return { ...state, loaded: payload };
        case CLAIM_LOADING:
            return { ...state, isLoading: payload };
        default:
            return state;
    }
}
