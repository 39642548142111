// Create an instance.
export default class AuthApi {
    constructor(baseUrl, network) {
      this.baseUrl = baseUrl;
      this.token = null;
      this.network = network;
    }
    login(data) {
        return new Promise((resolve, reject) => {
          this.network.post(this.baseUrl + '/auth/login', data).then((json) => {
            resolve(json);
          })
          .catch((error) => {
            reject(error);
          });
        });
    }
    retoken() {
        return new Promise((resolve, reject) => {
          console.log('this.token', this.token);
          this.network.post(this.baseUrl + '/auth/refresh', null, this.token).then((json) => {
            resolve(json);
          })
          .catch((error) => {
            console.log('Error in retoken authApi', error);
            resolve(null);
          });
        });
    }
    register(data) {
      return new Promise((resolve, reject) => {
        const postData = {
          email: data.email,
          password: data.password,
          name: data.name,
          phone: data.phone
        };
        console.log('data', postData);
        this.network.post(this.baseUrl + '/users/register', postData).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          console.log('Error in register authApi', error);
          reject(error);
        });
      });
    }
    recoverPassword(data) {
      return new Promise((resolve, reject) => {
        const postData = {
          email: data.email
        };
        console.log('data', postData);
        this.network.post(this.baseUrl + '/auth/recoverPassword', postData).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          console.log('Error in recover password authApi', error);
          reject(error);
        });
      });
    }
    // changePasswordWithCode
    changePasswordWithCode(data) {
      return new Promise((resolve, reject) => {
        console.log('data', data);
        this.network.post(this.baseUrl + '/auth/changePasswordWithCode', data).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          console.log('Error in recover password authApi', error);
          reject(error);
        });
      });
    }
    logout() {
      return new Promise((resolve, reject) => {
        this.network.post(this.baseUrl + '/auth/logout').then((json) => {
          resolve(json);
        })
        .catch((error) => {
          console.log('Error in logout authApi', error);
          reject(error);
        });
      });
  }
}