import * as React from 'react';

import {
    Text,
    View,
    ActivityIndicator,
    FlatList
} from 'react-native';

import {
    Divider
} from 'react-native-elements';

import { useSelector, useDispatch } from 'react-redux';
import { getNotifications } from '../redux/notifications';
import { getAllOrders } from '../redux/orders';
import theme, { themeVariables } from '../theme/styles';
import SelectPointListItem from '../components/SelectPointListItem';

let count = 0;

export default function OrderSelectionScreen({ navigation, route }) {
    const dispatch = useDispatch();

    const orders = useSelector(state => state.orders.orders);
    const points = orders ? Object.values(orders).map((order) => order.points) : [];
    React.useEffect(() => {
        dispatch(getAllOrders());
    }, []);

    React.useEffect(() => {
        if(Object.values(orders).length == 1) {
            count++;
            console.log('aaaaa', count);
            if (count < 2) {
                navigation.push('Cart', { point: Object.values(orders)[0].point_id});
            }
        }
    }, [orders]);
    return (
        <View style={theme.screenContainer}>
            { orders && points.length > 1 && 
                <>
                    <Text style={[theme.header4, { paddingHorizontal: themeVariables.gutter }]}>Pedidos abiertos</Text>
                    <Divider style={{ marginVertical: 10 }} />
                    <FlatList
                        data={points}
                        renderItem={({ item, index, separators }) => {
                            return (
                                <SelectPointListItem
                                    item={item}
                                    navigation={navigation}
                                />
                            )
                        }}
                        keyExtractor={(item, index) => index.toString()}
                    />
                </>
            }
            { !(Object.keys(orders).length > 0) &&
                <>
                    <View style={theme.activityIndicator}>
                        <Text style={{ textAlign: 'center' }}>Tu pedido se encuentra vacío.</Text>
                    </View>
                </>
            }
        </View>
    );
}