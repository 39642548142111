import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import LoginScreen from '../screens/auth/LoginScreen';
import RememberPasswordScreen from '../screens/auth/RememberPasswordScreen';
import RegisterScreen from '../screens/auth/RegisterScreen';

import { themeVariables } from '../theme/styles';

const Stack = createStackNavigator();

export default function LoginNavigator({ navigation, route }) {
    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false
            }}
        >
            <Stack.Screen
                name="Login"
                component={LoginScreen}
            />
            <Stack.Screen
                name="RememberPassword"
                component={RememberPasswordScreen}
            />
            <Stack.Screen
                name="Register"
                component={RegisterScreen}
            />
        </Stack.Navigator>
    );
}
