
import compaApi from '../apis/compa';

export const USER = 'USER';

export const USER_LOADING = `[${USER}] USERs`;

export const USER_LOADED = `[${USER}] loaded`;

export const USER_SET_RESULT_SUMMARY = `[${USER}] set result summary`;



export const setResultBalance = (result) => ({
    type: USER_SET_RESULT_SUMMARY,
    payload: result
});

export const getBalance = () => {
    return (dispatch) => {
        compaApi.user
            .getBalance()
            .then((result) => {
                dispatch(setResultBalance(result));
            })
            .catch(() => {
            });
    };
};

export const changeProfile = (profileData) => {
    return (dispatch) => {
        compaApi.user
            .changeProfile(profileData)
            .then((response) => {
                console.log('response changeProfileData', response);
                dispatch(authSetUser(response.user));
            })
            .catch(() => {
            });
    };
};


const initialState = {
    summary: null,
    summaryTotal: 0,
    loaded: false,
    isLoading: false
};

export function UserReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_SET_RESULT_SUMMARY:
            const summary = payload;
            const pagos = summary && summary.pagos ? summary.pagos : [];
            const reclamos = summary && summary.reclamos ? summary.reclamos : [];
            const pedidos = summary && summary.pedidos ? summary.pedidos : [];
        
            const totalPagos = pagos.reduce((accumualtor, current) => accumualtor + current.monto, 0);
            const totalReclamos = reclamos.reduce((accumualtor, current) => accumualtor + current.precio_total, 0);
            const totalPedidos = pedidos.reduce((accumualtor, current) => accumualtor + current.precio_total, 0);
        
            const summaryTotal = totalReclamos + totalPagos - totalPedidos;

            return { 
                ...state, 
                summary: summary,
                summaryTotal: summaryTotal
            };
        case USER_LOADED:
            return { ...state, loaded: payload };
        case USER_LOADING:
            return { ...state, isLoading: payload };
        default:
            return state;
    }
}
