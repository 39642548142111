import * as React from 'react';

import {
    View,
    Text
} from 'react-native';
import {
    Button
} from 'react-native-elements';
import theme, { themeVariables } from '../theme/styles';

export default function PointListItem ({ item, navigation, distanceInKm }) {
    return (
        <View>
            <Text style={theme.header5}>{ item.nombre } { distanceInKm ? ' a ' + distanceInKm.toFixed(2) + ' km.' : ''}</Text>
            <Text style={theme.normalText}>{item.ubicacion}</Text>
            <Button 
              title='Comprar en este lugar'
              containerStyle={[theme.buttonSuccessContainerStyle]}
              buttonStyle={[theme.buttonSuccessButtonStyle]}
              titleStyle={[theme.buttonSuccessTitleStyle]}
              onPress={() => {
                navigation.push('PointProducts', { point: item });
              }}
            />
            {
              false && /* administrador de point */
                <Button 
                    title='administrar'
                    containerStyle={[theme.buttonSuccessContainerStyle]}
                    buttonStyle={[theme.buttonSuccessButtonStyle]}
                    titleStyle={[theme.buttonSuccessTitleStyle]}
                    onPress={() => {
                      navigation.push('PointManagement',{point: item})
                    }}
                />
            }
        </View>
    );
}
  