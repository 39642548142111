import { onEveryAction, onAction } from './middleware/event-sync';
import { CURRENT_DEVICE_SET, CURRENT_DEVICE_LOADING } from './device';
import { RESOURCES_LOADED, RESOURCES_LOADING } from './resources';
import { appCompleted, APP_INIT } from './app';
import { TOKEN_LOADING, TOKEN_SET } from './token';
import { AUTH_LOADING, AUTH_LOADED } from './auth';
import compaApi from '../apis/compa';
import { FAVORITES_LOADING } from './favorites';

const appLoading = onAction(APP_INIT, [
    CURRENT_DEVICE_LOADING,
    RESOURCES_LOADING,
    TOKEN_LOADING,
    FAVORITES_LOADING
]);

const authLoading = onAction(TOKEN_SET, AUTH_LOADING);

// Espera que el sistema se cargue para continuar
const appLoaded = onEveryAction(
    [CURRENT_DEVICE_SET, RESOURCES_LOADED], // , AUTH_LOADED
    ({ dispatch }, action) => {
        dispatch(appCompleted());
    }
);

const setTokenApi = onAction(TOKEN_SET, (store, action) => {
    const { payload: token } = action;
    compaApi.setApiToken(token);
    console.log('TOKEN_SET', token);

    if (!store.getState().token.loaded) {
        console.log('TOKEN_LOADED');
        store.dispatch(tokenLoaded());
    }
});
export const workflowMiddleware = [
    appLoading,
    appLoaded,
    authLoading,
    setTokenApi
];
