import * as React from 'react';
import { useSelector } from 'react-redux';
import { Text, View, Image, TouchableWithoutFeedback } from 'react-native';
import compaApi from '../apis/compa';
import Badged from './ProductFeatures/Badged';
import ItemQuantityInput from './ProductFeatures/ItemQuantityInput';
import ReplaceItemCheckbox from './ProductFeatures/ReplaceItemCheckbox';
import theme, { themeVariables } from '../theme/styles';
import dayjs from 'dayjs';

export default function NotificationListItem ({ item, navigation }) {
    return (
        <TouchableWithoutFeedback
            onPress={() => {
                
            }}
        >
            <View style={{ flexDirection: 'row', marginBottom: 15, paddingBottom: 15, borderBottomWidth: 1, borderBottomColor: '#CCC', paddingTop: 4 }}>
                <Image
                    source={{
                        uri: compaApi.generateImageUri(item.logo) ? compaApi.generateImageUri(item.logo) : require('../assets/logo.png')
                    }}
                    style={{
                        width: 75,
                        height: 75,
                        borderRadius: themeVariables.lowBorderRadius,
                        marginRight: 12
                    }}
                />
                <View style={{ flexDirection: 'row', justifyContent: 'flex-start', flex: 1  }}>
                    <View style={{ flex: 1, flexWrap: 'wrap' }}>
                        <Text style={ [theme.header5, {marginTop: 0}] }>{ item.titulo }</Text>
                        <Text style={ [theme.normalText, {marginTop: 0, flexWrap: 'wrap' }] }>{ item.mensaje }</Text>
                        <Text style={ [theme.normalText, {marginTop: 0, fontSize: 12, fontStyle: 'italic'}] }>Recibida: { dayjs(item.fecha).format('DD/MM/YYYY HH:mm') }</Text>
                   </View>
               </View>
            </View>
        </TouchableWithoutFeedback>
    );
}
