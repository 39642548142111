
import compaApi from '../apis/compa';
import { onAction } from './middleware/event-sync';
import { storageGet, storageSet } from './middleware/async-storage';
import { clone } from 'lodash';
import { TOKEN, TOKEN_LOADING,TOKEN_SET } from './token.js';
import { AUTH_SET_REGISTER_RESULT, AUTH_LOADED } from './auth.js';

import { getAllOrders } from './orders';

export const LOCAL_ORDER = 'LOCAL_ORDER';


export const LOCAL_ORDER_SET_RESULT_ORDERS = `[${LOCAL_ORDER}] set orders`;

export const LOCAL_ORDER_LOADING = `[${LOCAL_ORDER}] orders`;

export const LOCAL_ORDER_LOADED = `[${LOCAL_ORDER}] loaded`;

export const LOCAL_ORDER_MODIFY_PRODUCT = `[${LOCAL_ORDER}] modify product`;



const saveLocalOrders = onAction(LOCAL_ORDER_MODIFY_PRODUCT, (store, action) => {
    let state = store.getState();
    store.dispatch(storageSet(LOCAL_ORDER, state.localOrders.orders));
});

const loadLocalOrders = onAction(TOKEN_LOADING, (store, action) => {
    console.log("local orders loaded")
    store.dispatch(storageGet(LOCAL_ORDER, LOCAL_ORDER_SET_RESULT_ORDERS));
});

export const localModifyProduct = (result) => ({
    type: LOCAL_ORDER_MODIFY_PRODUCT,
    payload: result
});

export const setResultLocalOrders = (result) => ({
    type: LOCAL_ORDER_SET_RESULT_ORDER,
    payload: result
});

export const setLocalOrdersLoading = (result) => ({
    type: LOCAL_ORDER_LOADING,
    payload: result
});

export const loadOrdersFromDevice = () => {
    return (dispatch) => {
        dispatch(setLocalOrdersLoading(true))
        dispatch(setLocalOrdersLoading(false))
    }
}

export const modifyLocalProduct = (product, pointId, productId, amount, replace) => {
    return (dispatch) => {
        let data = { product, pointId: pointId, productId: productId, amount: amount, replace: replace }
        dispatch(localModifyProduct(data))
    };
};

// TODO: se puede reemplazar por AUTH_LOADED
const addLocalOrdersToUser = onAction(AUTH_SET_REGISTER_RESULT, (store, action) => {
    setTimeout(() => {
        const  localOrders  = store.getState().localOrders.orders;
    
        console.log("ADDDD", localOrders);
        for (const pointId in localOrders) {
            for (const productId in localOrders[pointId]) {
                console.log("add local product", productId);
                let data = {
                    producto: productId,
                    unidades: localOrders[pointId][productId].amount,
                    sustitucion: localOrders[pointId][productId].replace
                }
                compaApi.orders
                    .addProduct(data)
                    .then((result) => {
                        console.log('result', result);
                        setTimeout(() => {

                            store.dispatch(getAllOrders());
                        }, 500);
                    })
                    .catch((err) => {
                        console.log('catch', err);
                    });
            }
        }
    }, 1000);
});



const initialState = {
    orders: {},
    loaded: false,
    isLoading: false
};

export function LocalOrdersReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOCAL_ORDER_MODIFY_PRODUCT:
            let local = clone(state.orders);

            if (!(payload.pointId in local)) {
                local[payload.pointId] = {}
            }

            if (!(payload.productId in local[payload.pointId])) {
                local[payload.pointId][payload.productId] = {
                    amount: payload.amount,
                    replace: payload.replace ? true : false,
                    product: payload.product
                }
            } else {
                local[payload.pointId][payload.productId].amount = local[payload.pointId][payload.productId].amount + payload.amount;
                if (payload.replace != null) {
                    local[payload.pointId][payload.productId].replace = payload.replace;
                }
            }

            return { ...state, orders: local };
        case LOCAL_ORDER_SET_RESULT_ORDERS:
            if (payload) {
                return { ...state, orders: payload }
            }
            return { ...state, orders: {} }
        default:
            return state;
    }
}

export const localOrdersMiddleware = [saveLocalOrders, loadLocalOrders, addLocalOrdersToUser];
