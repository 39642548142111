import compaApi from '../apis/compa';

export const POINT_MANAGEMENT = 'POINT ORDERS';
export const POINT_MANAGEMENT_ORDERS_LOADING = `[${POINT_MANAGEMENT}] point orders loading`;
export const POINT_MANAGEMENT_ORDERS_LOADED = `[${POINT_MANAGEMENT}] point ordersloaded`;
export const POINT_MANAGEMENT_SET_RESULT_ORDERS = `[${POINT_MANAGEMENT}] set result point orders`;

export const POINT_MANAGEMENT_PRODUCTS_LOADING = `[${POINT_MANAGEMENT}] point products orders`;
export const POINT_MANAGEMENT_PRODUCTS_LOADED = `[${POINT_MANAGEMENT}] point products loaded`;
export const POINT_MANAGEMENT_SET_POINT_PRODUCTS = 'POINT ORDERS set point products';

export const POINT_MANAGEMENT_CLAIMS_LOADING = `[${POINT_MANAGEMENT}] point claims orders`;
export const POINT_MANAGEMENT_CLAIMS_LOADED = `[${POINT_MANAGEMENT}] point claims loaded`;
export const POINT_MANAGEMENT_SET_POINT_CLAIMS = 'POINT ORDERS set point claims';

export const POINT_MANAGEMENT_PAYMENTS_LOADING = `[${POINT_MANAGEMENT}] point payments orders`;
export const POINT_MANAGEMENT_PAYMENTS_LOADED = `[${POINT_MANAGEMENT}] point payments loaded`;
export const POINT_MANAGEMENT_SET_POINT_PAYMENTS = 'POINT ORDERS set point payments';


export const POINT_MANAGEMENT_CAMPAINGS_LOADING = `[${POINT_MANAGEMENT}] point campaings`;
export const POINT_MANAGEMENT_SET_POINT_CAMPAINGS = 'POINT campaings set point campaings';

export const POINT_MANAGEMENT_CLAIMS_PROCESED = `[${POINT_MANAGEMENT}] point claims procesed`;

export const POINT_MANAGEMENT_SET_STATE_PAYMENT = `[${POINT_MANAGEMENT}] point set state payment`;

export const POINT_MANAGEMENT_SET_STATE_CLAIM = `[${POINT_MANAGEMENT}] point set state claim`;

export const setClaimProcesed = (result) => ({
    type: POINT_MANAGEMENT_CLAIMS_PROCESED,
    payload: result
});

export const setResultPointOrders = (result) => ({
    type: POINT_MANAGEMENT_SET_RESULT_ORDERS,
    payload: result
});

export const setPointOrdersLoading = (isLoading) => ({
    type: POINT_MANAGEMENT_ORDERS_LOADING,
    payload: isLoading
});

export const setPointOrdersLoaded = (loaded) => ({
    type: POINT_MANAGEMENT_ORDERS_LOADED,
    payload: loaded
});
export const setPointProducts = (products) => ({
    type: POINT_MANAGEMENT_SET_POINT_PRODUCTS,
    payload: products
});
export const setPointProductsLoading = (isLoading) => ({
    type: POINT_MANAGEMENT_PRODUCTS_LOADING,
    payload: isLoading
});

export const setPointProductsLoaded = (loaded) => ({
    type: POINT_MANAGEMENT_PRODUCTS_LOADED,
    payload: loaded
});
export const setPointClaims = (products) => ({
    type: POINT_MANAGEMENT_SET_POINT_CLAIMS,
    payload: products
});
export const setPointClaimsLoading = (isLoading) => ({
    type: POINT_MANAGEMENT_CLAIMS_LOADING,
    payload: isLoading
});

export const setPointClaimsLoaded = (loaded) => ({
    type: POINT_MANAGEMENT_CLAIMS_LOADED,
    payload: loaded
});
export const setPointPayments = (products) => ({
    type: POINT_MANAGEMENT_SET_POINT_PAYMENTS,
    payload: products
});
export const setPointPaymentsLoading = (isLoading) => ({
    type: POINT_MANAGEMENT_PAYMENTS_LOADING,
    payload: isLoading
});
export const setPointCampaings = (campaings) => ({
    type: POINT_MANAGEMENT_SET_POINT_CAMPAINGS,
    payload: campaings
});
export const setPointCampaingsLoading = (isLoading) => ({
    type: POINT_MANAGEMENT_CAMPAINGS_LOADING,
    payload: isLoading
});

export const setPointPaymentsLoaded = (loaded) => ({
    type: POINT_MANAGEMENT_PAYMENTS_LOADED,
    payload: loaded
});

export const setPointStatePayment = (result) => ({
    type: POINT_MANAGEMENT_SET_STATE_PAYMENT,
    payload: result
});

export const setPointStateClaim = (result) => ({
    type: POINT_MANAGEMENT_SET_STATE_CLAIM,
    payload: result
});

export const getAllPointOrders = (campaing_id) => {
    return (dispatch) => {
        dispatch(setPointOrdersLoading(true));
        compaApi.pointManagement
            .getAllPointOrders(campaing_id)
            .then((result) => {
                dispatch(setResultPointOrders(result));
                dispatch(setPointOrdersLoading(false));
                dispatch(setPointOrdersLoaded(true));
            })
            .catch((err) => {
                dispatch(setPointOrdersLoading(false));
            });
    };
};
export const getPointProducts = (campaing_id) => {
    return (dispatch) => {
        dispatch(setPointProductsLoading(true));
        compaApi.pointManagement
            .getPointProducts(campaing_id)
            .then((result) => {
                dispatch(setPointProducts(result));
                dispatch(setPointProductsLoading(false));
                dispatch(setPointProductsLoaded(true));
            })
            .catch((err) => {
                dispatch(setPointProductsLoading(false));
            });
    };
};
export const getPointClaims = (campaing_id) => {
    return (dispatch) => {
        dispatch(setPointClaimsLoading(true));
        compaApi.pointManagement
            .getPointClaims(campaing_id)
            .then((result) => {
                dispatch(setPointClaims(result));
                dispatch(setPointClaimsLoading(false));
                dispatch(setPointClaimsLoaded(true));
            })
            .catch((err) => {
                console.log('getPointClaims catch err', err);
                dispatch(setPointClaimsLoaded(true));
            });
    };
};
export const getPointPayments = (campaing_id) => {
    return (dispatch) => {
        dispatch(setPointPaymentsLoading(true));
        compaApi.pointManagement
            .getPointPayments(campaing_id)
            .then((result) => {
                dispatch(setPointPayments(result));
                dispatch(setPointPaymentsLoading(false));
                dispatch(setPointPaymentsLoaded(true));
            })
            .catch((err) => {
            });
    };
};


export const getPointCampaings = () => {
    return (dispatch) => {
        dispatch(setPointCampaingsLoading(true));
        compaApi.pointManagement
            .getPointCampaings()
            .then((result) => {
                dispatch(setPointCampaings(result));
                dispatch(setPointCampaingsLoading(false));
            })
            .catch((err) => {
            });
    };
};

export const approveClaimLine = (lineId, state) => {
    return (dispatch) => {
        dispatch(setPointClaimsLoading(true));
        //TODO: traer el pointId del user, no pedirlo como parametro, ya q un user solo es admin un point
        let data = {
            lineaId: lineId,
            estado: state
        }
        compaApi.pointManagement
            .approveClaimLine(data)
            .then((result) => {
                dispatch(setPointStateClaim("El reclamo se aprobó con éxito."));
                dispatch(setPointClaimsLoading(false));
                dispatch(setPointClaimsLoaded(true));
            })
            .catch((err) => {
                dispatch(setPointStateClaim("Ocurrió un error al aprobar el reclamo."));
                dispatch(setPointClaimsLoading(false));
            });
    }
}
export const approvePayment = (paymentId, statePayment) => {
    return (dispatch) => {
        dispatch(setPointPaymentsLoading(true));
        let data = {
            pago: paymentId,
            estado: statePayment
        }
        compaApi.pointManagement
            .approvePayment(data)
            .then((result) => {
                if (statePayment == "aprobado") {
                    dispatch(setPointStatePayment("El pago se aprobó con éxito."));
                }
                if (statePayment == "rechazado") {
                    dispatch(setPointStatePayment("El pago se fue rechazado."));
                }
                dispatch(getPointPayments());
                dispatch(setPointPaymentsLoading(false));
            })
            .catch((err) => {
                if (statePayment == "aprobado") {
                    dispatch(setPointStatePayment("Ocurrió un error al aprobar el pago"));
                }
                if (statePayment == "rechazado") {
                    dispatch(setPointStatePayment("Ocurrió un error al rechazar el pago"));
                }
                dispatch(setPointPaymentsLoading(false));
            });
    }
}
export const downloadReceipt = (paymentId) => {
    return (dispatch) => {
        compaApi.pointManagement
            .downloadReceipt(paymentId)
            .then((result) => {
                console.log("descarga", result);
            })
            .catch((err) => {
            });
    }
}


/*
    pedido_id,
    monto,
    point_id,
    estado,
    metodo
*/
export const createPayment = (data) => {
    return (dispatch) => {
        compaApi.pointManagement
            .createPayment(data)
            .then((result) => {
                // dispatch(getPointPayments());
            })
            .catch((err) => {
            });
    }
}


const initialState = {
    orders: [],
    products: [],
    claims: [],
    payments: [],
    loadedOrders: false,
    isLoadingOrders: false,
    loadedProducts: false,
    isLoadingProducts: false,
    loadedClaims: false,
    isLoadingClaims: false,
    loadedPayments: false,
    isLoadingPayments: false,
    statePayment: null,
    stateClaim: null,
    campaings: null,
    isLoadingCampaings: false
};

export function PointManagementReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case POINT_MANAGEMENT_SET_RESULT_ORDERS:
            return { ...state, orders: payload };
        case POINT_MANAGEMENT_ORDERS_LOADED:
            return { ...state, loadedOrders: payload };
        case POINT_MANAGEMENT_ORDERS_LOADING:
            return { ...state, isLoadingOrders: payload };
        case POINT_MANAGEMENT_PRODUCTS_LOADED:
            return { ...state, loadedProducts: payload };
        case POINT_MANAGEMENT_PRODUCTS_LOADING:
            return { ...state, isLoadingProducts: payload };
        case POINT_MANAGEMENT_SET_POINT_PRODUCTS:
            return { ...state, products: payload.data };
        case POINT_MANAGEMENT_SET_POINT_CLAIMS:
            return { ...state, claims: payload }
        case POINT_MANAGEMENT_CLAIMS_LOADED:
            return { ...state, loadedClaims: payload }
        case POINT_MANAGEMENT_CLAIMS_LOADING:
            return { ...state, isLoadingClaims: payload }
        case POINT_MANAGEMENT_SET_POINT_PAYMENTS:
            return { ...state, payments: payload }
        case POINT_MANAGEMENT_PAYMENTS_LOADED:
            return { ...state, loadedPayments: payload }
        case POINT_MANAGEMENT_PAYMENTS_LOADING:
            return { ...state, isLoadingPayments: payload }
        case POINT_MANAGEMENT_SET_POINT_CAMPAINGS:
            return { ...state, campaings: payload }
        case POINT_MANAGEMENT_CAMPAINGS_LOADING:
            return { ...state, isLoadingCampaings: payload }
        case POINT_MANAGEMENT_SET_STATE_PAYMENT:
            return { ...state, statePayment: payload}
        case POINT_MANAGEMENT_SET_STATE_CLAIM:
            return { ...state, stateClaim: payload}
        default:
            return state;
    }
}
