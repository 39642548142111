// Create an instance.
export default class NotifcationsApi {
    constructor(baseUrl, network) {
      this.baseUrl = baseUrl;
      this.token = null;
      this.network = network;
    }



    get(data) {
      return new Promise((resolve, reject) => {
        this.network.get(this.baseUrl + '/notificaciones/notificaciones', data, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      });
    }
    markRead(data) {
      return new Promise((resolve, reject) => {
        this.network.get(this.baseUrl + '/notificaciones/marcarLeidas', data, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      });
    }
}