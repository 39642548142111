import * as React from 'react';
import { deliverOrder, setDeliverResult } from '../../redux/orders';
import {
  View,
  FlatList,
  Text,
  Image,
  ImageBackground,
  Share
} from 'react-native';
import * as Linking from 'expo-linking';
import {
  Input,
  Button
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import { useSelector, useDispatch } from 'react-redux';
import DeliveryProductListItem from '../../components/PointManagement/DeliveryProductListItem';
import UserOrderListItem from '../../components/PointManagement/UserOrderListItem';
import { ScrollView } from 'react-native';

import { displayOrderStatusName, totalizadorPeso, totalizadorPrecio } from '../../utils/helpers';

import { createPayment } from '../../redux/pointManagement';
import RNPickerSelect from 'react-native-picker-select';
import dayjs from 'dayjs';
import Toast from 'react-native-toast-message';

//screen showing a user order to a point manager
export default function UserOrderDetailScreen({ navigation, route }) {
  const orderId = route.params && route.params.order ? route.params.order : (order ? order : {});
  const pointOrders = useSelector(state => state.pointManagement.orders);

  const order = pointOrders.filter(item => item.id == orderId)[0];
  const dispatch = useDispatch();

  const paymentType = order && order.pagos && order.pagos.length > 0 ? order.pagos[0].metodo : "incompleto";
  console.log("order", order);
  const user = useSelector(state => state.auth.user);
  const [ monto, setMonto ] = React.useState('');
  const [ metodoDePago, setMetodoDePago ] = React.useState(null);
  // FIXME
  const [ lineas, setLineas ] = React.useState({});
  const deliveredResult = useSelector(state => state.orders.deliveredResult);
  const ordersLoading = useSelector(state => state.orders.isLoading);


  React.useEffect(() => {
    console.log(deliveredResult, ordersLoading);
    if (deliveredResult && !ordersLoading) {
        Toast.show({
            type: 'info',
            text1: 'CompaApp - Pedidos',
            text2: deliveredResult
        });
        setTimeout(() => {
            // limpiamos el result para futuros usos
            dispatch(setDeliverResult(null));
            // navigation.navigate('Cart');
        }, 500);
    }
  }, [deliveredResult, ordersLoading]);
  
  return (
      <ScrollView>
        <View style={{ marginBottom: themeVariables.smallMargin, alignItems: "center", padding: 5 }}>

          <Text style={[theme.header5, { fontSize: 22 }]}>Detalle del pedido</Text>
          <Text style={theme.header6}>Total $ {order ? order.linea_pedidos.reduce(totalizadorPrecio, 0) : 0}</Text>

          <Text>Fecha: <Text style={{ fontWeight: 'bold' }}>{ order ? dayjs(order.fecha).format('DD/MM/YYYY HH:mm') : '' }</Text></Text>v
          <Text>Metodo de pago: {paymentType}</Text>
          <Text>Cantidad de productos {order ? order.linea_pedidos.length : 0}</Text>
          <Text>Peso total {order ? order.linea_pedidos.reduce(totalizadorPeso, 0) : 0} kg.</Text>
          <Text>Estado: <Text style={{ fontWeight: 'bold' }}>{ order ? displayOrderStatusName(order.estado) : '' }</Text></Text>
            {
              user.point_id > 0 && order && order.estado != 3 &&
                <Button
                    title='Marcar como entregado'
                    containerStyle={{ marginBottom: 10 }}
                    onPress={() => {
                      dispatch(deliverOrder(order.id, lineas));
                    }}
                    loading={ordersLoading}
                />
            }
        </View>
        <UserOrderListItem
          navigation={navigation}
          item={order}
          touchable={false}
        ></UserOrderListItem>
        {
          user.point_id > 0 &&
            <View style={{ padding: themeVariables.gutter }}>
              <Text style={[theme.header5, { fontSize: 22 }]}>Agregar un pago</Text>
              <Input 
                label='Monto a pagar'
                defaultValue={monto.toString()}
                onChangeText={(text) => {
                  setMonto(parseInt(text, 10));
                }}
                containerStyle={{ marginVertical: 10, padding: 0, paddingHorizontal: 0 }}
                inputContainerStyle={{
                  borderWidth: 0,
                  padding: 0
                }}
                inputStyle={{
                  fontSize: 16,
                  color: '#333',
                  paddingHorizontal: 5,
                  borderWidth: 1,
                  borderColor: '#999',
                  borderRadius: 8,
                  backgroundColor: 'white'
                }}
              />
              <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#86939e'}}>Método de pago</Text>
              <RNPickerSelect
                  onValueChange={(value) => {
                    setMetodoDePago(value)
                  }}
                  placeholder={{
                    label: 'Seleccione un método de pago',
                    value: null,
                    color: 'red',
                  }}
                  style={{
                    inputWeb: {
                      fontSize: 16,
                      paddingHorizontal: 10,
                      paddingVertical: 8,
                      borderWidth: 1,
                      borderColor: '#999',
                      borderRadius: 8,
                      color: '#333',
                      paddingRight: 30, // to ensure the text is never behind the icon
                    }
                  }}
                  items={[
                      { label: 'Efectivo', value: 'cash' },
                      { label: 'Transferencia Bancaria', value: 'bank' },
                      { label: 'Mercado Pago', value: 'mercadoPago' },
                      // { label: 'Hockey', value: 'hockey' },
                  ]}
              />
              <Button 
                title='Registrar pago'
                onPress={() => {
                  if (metodoDePago && parseInt(monto, 10) > 0) {
                    let data = {
                      pedido_id: order.id,
                      monto: monto,
                      point_id: order.point_id,
                      estado: 'aprobado',
                      metodo: metodoDePago,
                      fecha: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                      users_id: order.users_id,
                      comprobante: ''
                    }
                    dispatch(createPayment(data));
                  } else {
                    if (!parseInt(monto, 10) > 0) {
                      alert('Monto inválido o vacío');
                    } else {
                      if (!metodoDePago) {
                        alert('Debe seleccionar un método de pago');
                      }
                    }
                  }
                }}
                containerStyle={{ marginTop: 15 }}
              />
            </View>
        }
        <View style={{ backgroundColor: 'white', paddingVertical: 10, paddingHorizontal: 5 }}>
          <Text style={theme.header4}>Productos del pedido</Text>
          <FlatList
            data={order ? order.linea_pedidos : []}
            renderItem={({ item, index, separators }) => {
              return (
                <DeliveryProductListItem
                  navigation={navigation}
                  item={item}
                  order={order}
                  onInputChange={(pid, q) => {
                    let _lineas = JSON.parse(JSON.stringify(lineas));
                    _lineas[pid] = q;
                    setLineas(_lineas);
                  }}
                />
              )
            }}
            extraData={order, lineas}
            keyExtractor={item => item.id.toString()}
          />

        </View>

      </ScrollView>
  )
}
