import * as React from 'react';
import { View, Text, ImageBackground, ScrollView } from 'react-native';
import compaApi from '../apis/compa';
import theme, { themeVariables } from '../theme/styles';
import { useSelector } from 'react-redux';
import Badged from '../components/ProductFeatures/Badged';
import ItemQuantityInput from '../components/ProductFeatures/ItemQuantityInput';

export default function ProductScreen({ navigation, route }) {
    const product_id = route.params && route.params.productId ? route.params.productId : {};
    const point_id = route.params && route.params.pointId ? route.params.pointId : {};
    const products = useSelector(state => state.products.products);
    let product = products[point_id] ? products[point_id].filter(p => p.id == product_id)[0] : null;


    const orders = useSelector(state => state.orders.orders);
    const order = orders[point_id];
    
    if (!product && order && order.linea_pedidos) {
        product = order.linea_pedidos.find(p => p.producto_point_id == product_id);
    }
    console.log("ord,pr", product_id, order, product);
    const orderItem = order && order.linea_pedidos ? order.linea_pedidos.filter(l => l.producto_point.id == product.id)[0] : {};

    
    return (
        <View style={theme.screenContainer}>
            <ScrollView>
                <View style={{ marginBottom: themeVariables.gutter }}>
                    <ImageBackground
                        source={{
                            uri: compaApi.generateImageUri(product.producto_campaña.imagen, 'images/') ? compaApi.generateImageUri(product.producto_campaña.imagen, 'images/') : require('../assets/logo.png')
                            // uri: compaApi.generateImageUri(product.imagen) ? compaApi.generateImageUri(product.imagen) : require('../assets/logo.png')
                        }}
                        style={{
                            width: '100%',
                            height: 180
                        }}
                    >
                    </ImageBackground>
                </View>
                <View style={[theme.screenContainer, { paddingHorizontal: themeVariables.gutter }]}>
                    <View style={{ flexDirection: 'row', marginBottom: 15, paddingBottom: 15, paddingTop: 4, justifyContent: 'space-between' }}>
                        <View style={{ flex: 1 }}>
                            <View style={{ paddingTop: 2 }}>
                                <Badged item={product} />
                            </View>
                            <Text style={[theme.header7, { marginBottom: themeVariables.smallMargin, flex: 1, flexWrap: 'wrap' }]}>{product.producto_campaña.nombre}</Text>
                            <Text style={theme.smallText}>
                                Cajón: {product.producto_campaña.peso_bulto} kg aprox.
                                Kilo: $ {product.producto_campaña.precio_referencia_kilo} aprox.
                            </Text>
                            {
                                product.producto_campaña.divisible > 1 &&
                                <Text style={theme.highlightText}>Fraccionado: $ {product.precio_fraccion}</Text>
                            }
                            {
                                product.bultos_restantes == 0 &&
                                <Text style={theme.leadingHighlightText}>Sin stock</Text>
                            }
                            <Text style={theme.highlightText}>$ {orderItem ? orderItem.precio : 0}</Text>
                        </View>
                        <ItemQuantityInput item={product} order={order} />
                    </View>

                    <Text style={[theme.normalText, { fontWeight: 'bold' }]}> Origen:  {product.producto_campaña.origen}</Text>
                    {/* TODO: este calculo del cajon esta mal */}
                    <Text style={[theme.normalText, { fontWeight: 'bold' }]}> Peso de cajon completo:  {product.producto_campaña.peso_bulto } kg.</Text>

                    <Text style={[theme.normalText, { marginTop: 15 }]}> {product.producto_campaña.descripcion}</Text>
                </View>
            </ScrollView>
        </View>
    );
}
