import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';

import { CurrentDeviceReducer, deviceMiddleware } from './device';
import { AppReducer } from './app';
import { logger } from './middleware/log';
import { resourceMiddleware } from './resources';
import { workflowMiddleware } from './workflow';
import { asyncStorageMiddleware } from './middleware/async-storage';
import { tokenMiddleware, TokenReducer } from './token';
import { AuthReducer, authMiddleware } from './auth';
import { GroupsReducer } from './groups';
import { ProductsReducer } from './products';
import { OrdersReducer } from './orders';
import { PointManagementReducer } from './pointManagement';
import { favoritesMiddleware, FavoritesReducer } from './favorites';
import { localOrdersMiddleware, LocalOrdersReducer } from './localOrders';
import { NotificationsReducer } from './notifications';
import { miniGroupInviteReducer } from './miniGroupInvite';
import { MiniGroupsReducer } from './miniGroups';
import { CartReducer } from './cart';
import { ClaimsReducer } from './claims';
import { UserReducer } from './user';
import { GroupManagementReducer } from './groupManagement'
import { ProducerReducer } from './producer';

const middleware = [
    thunk,
    logger,
    ...deviceMiddleware,
    ...resourceMiddleware,
    ...workflowMiddleware,
    ...asyncStorageMiddleware,
    ...tokenMiddleware,
    ...authMiddleware,
    ...favoritesMiddleware,
    ...localOrdersMiddleware
];

const reducer = combineReducers({
    device: CurrentDeviceReducer,
    app: AppReducer,
    token: TokenReducer,
    auth: AuthReducer,
    groups: GroupsReducer,
    products: ProductsReducer,
    orders: OrdersReducer,
    favorites: FavoritesReducer,
    pointManagement: PointManagementReducer,
    notifications: NotificationsReducer,
    miniGroupInvite: miniGroupInviteReducer,
    miniGroups: MiniGroupsReducer,
    cart: CartReducer,
    claims: ClaimsReducer,
    user: UserReducer,
    groupManagement: GroupManagementReducer,
    localOrders: LocalOrdersReducer,
    producer: ProducerReducer
});

const store = createStore(reducer, applyMiddleware(...middleware));

export default store;
