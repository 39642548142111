
import compaApi from '../apis/compa';
import { onAction } from './middleware/event-sync';
import { storageGet, storageSet } from './middleware/async-storage';

export const FAVORITES = 'FAVORITES';

export const FAVORITES_LOADING = `[${FAVORITES}] loading`;

export const FAVORITES_LOADED = `[${FAVORITES}] loaded`;

export const FAVORITES_SET_RESULT_FAVORITES = `[${FAVORITES}] set result FAVORITES`;

export const FAVORITES_TOGGLE_FROM_FAVORITES = `[${FAVORITES}] toggle from FAVORITES`;


const saveFavorites = onAction(FAVORITES_TOGGLE_FROM_FAVORITES, (store, action) => {
    let state = store.getState();
    store.dispatch(storageSet(FAVORITES, state.favorites.favorites));
});

const loadFavorites = onAction(FAVORITES_LOADING, (store, action) => {
    store.dispatch(storageGet(FAVORITES, FAVORITES_SET_RESULT_FAVORITES));
});

export const toggleFavorite = (point) => {
    return (dispatch) => {
        dispatch(toggleFromFavorite(point))
    };
}

export const toggleFromFavorite = (result) => ({
    type: FAVORITES_TOGGLE_FROM_FAVORITES,
    payload: result
});

export const setResultFavorites = (result) => ({
    type: FAVORITES_SET_RESULT_FAVORITES,
    payload: result
});

export const setFavoritesLoading = (isLoading) => ({
    type: FAVORITES_LOADING,
    payload: isLoading
});

export const setFavoritesLoaded = (loaded) => ({
    type: FAVORITES_LOADED,
    payload: loaded
});


const initialState = {
    favorites: [],
    loaded: true,
    isLoading: false
};

export function FavoritesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FAVORITES_SET_RESULT_FAVORITES:
            return { ...state, favorites: payload };
        case FAVORITES_TOGGLE_FROM_FAVORITES:
            let favoritesList = [...state.favorites]
            if (favoritesList.filter(group => group.id == payload.id).length > 0) {
                favoritesList = favoritesList.filter(group => group.id != payload.id)
            } else {
                favoritesList.push(payload);
            }
            return { ...state, favorites: favoritesList };
        case FAVORITES_LOADED:
            return { ...state, loaded: payload };
        case FAVORITES_LOADING:
            return { ...state, isLoading: payload };
        default:
            return state;
    }
}


export const favoritesMiddleware = [saveFavorites, loadFavorites];
