import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, View, Image, TouchableWithoutFeedback,Button } from 'react-native';
import compaApi from '../apis/compa';
import Badged from './ProductFeatures/Badged';
import ItemQuantityInput from './ProductFeatures/ItemQuantityInput';
import ReplaceItemCheckbox from './ProductFeatures/ReplaceItemCheckbox';
import theme, { themeVariables } from '../theme/styles';
import { inviteUser } from '../redux/miniGroupInvite';

export default function ProductListItem ({ item, navigation, user}) {
    const dispatch = useDispatch();
    const isInvited = user.usuarios_usuarios ? user.usuarios_usuarios.length > 0 : false;
    const [invited, toggleInvited] = React.useState(isInvited);

    return (
        <TouchableWithoutFeedback
            onPress={() => {
                
            }}
        >
            <View style={{ flexDirection: 'row', marginBottom: 15, paddingBottom: 15, borderBottomWidth: 1, borderBottomColor: '#CCC', paddingTop: 4 }}>
                <Text>{user.name}</Text>
                <Button 
                    title={ invited ? "invitado" : "invitar"}
                    containerStyle={[theme.buttonSuccessContainerStyle]}
                    buttonStyle={[theme.buttonSuccessButtonStyle]}
                    titleStyle={[theme.buttonSuccessTitleStyle]}
                    disabled={invited}
                    onPress={() => {
                      //invite user
                      toggleInvited(true);
                      dispatch(inviteUser(user.id));
                    }}
                />
            </View>
        </TouchableWithoutFeedback>
    );
}
