import React, { useEffect } from 'react';
import { doRegister } from '../../redux/auth';
import { Text, View, Image, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import theme, { themeVariables } from '../../theme/styles';
import { Button, Icon } from 'react-native-elements';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function RegisterScreen ({ navigation }) {
    const dispatch = useDispatch();
  
    const { register, handleSubmit, setValue, errors, getValues } = useForm();
    useEffect(() => {
        register('name', { required: true });
        register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i });
        register('password', { required: true });
        register('phone', { required: true, pattern: /\+?(549)?[0-9]{10}/i});
        // register('city', { required: true });
        AsyncStorage.clear();
    }, [register]);

    const onSubmit = (data) => {
      console.log("llego");
      dispatch(doRegister(data));
    };

    return (
    <ScrollView style={theme.verticalContainer, theme.fullWidth}>
      <View style={[theme.verticalContainer, theme.topCard, theme.bigShadow, theme.fullWidth]}>
        <Image 
          source={{ uri: require('../../assets/logo-square.png') }}
          style={[theme.logo, {marginBottom: 8}]}
        />
        <Text style={[theme.header2]}>
          Crear cuenta
        </Text>
        <View style={theme.loginInputContainerStyle}>
          <Icon
            name='person'
            size={24} 
            color={themeVariables.mainColor}
            style={theme.loginIconInputStyle}
          ></Icon>
          <TextInput
            style={theme.loginInputStyle}
            placeholderTextColor={themeVariables.mainColor}
            onChangeText={text => setValue('name', text)}
            placeholder="Nombre completo"
            textContentType="name"
            autoCompleteType="name"
            ></TextInput>
        </View>
        {
          errors.name && 
            <Text style={{ color: 'red' }}>El nombre de estar completo.</Text>
        }
        <View style={theme.loginInputContainerStyle}>
          <Icon
            name='email'
            size={24} 
            color={themeVariables.mainColor}
            style={theme.loginIconInputStyle}
          ></Icon>
          <TextInput
            style={theme.loginInputStyle}
            placeholderTextColor={themeVariables.mainColor}
            onChangeText={text => setValue('email', text)}
            placeholder="Email"
            textContentType="emailAddress"
            autoCompleteType="email"
            ></TextInput>
        </View>
        {
          errors.email && !getValues('email') && 
            <Text style={{ color: 'red' }}>El email de estar completo.</Text>
        }
        {
          errors.email && getValues('email') && 
            <Text style={{ color: 'red' }}>El email ingresado es inválido.</Text>
        }
        <View style={theme.loginInputContainerStyle}>
          <Icon
            name='phone'
            size={24} 
            color={themeVariables.mainColor}
            style={theme.loginIconInputStyle}
          ></Icon>
          <TextInput
            style={theme.loginInputStyle}
            placeholderTextColor={themeVariables.mainColor}
            onChangeText={text => setValue('phone', text)}
            placeholder="Telefono"
            textContentType="telephoneNumber"
            autoCompleteType="tel"
            ></TextInput>
        </View>
        {
          errors.phone && !getValues('phone') && 
            <Text style={{ color: 'red' }}>El teléfono de estar completo.</Text>
        }
        {
          errors.phone && getValues('phone') && 
            <Text style={{ color: 'red' }}>El teléfono ingresado es inválido.</Text>
        }
        <View style={theme.loginInputContainerStyle}>
          <Icon
            name='lock'
            size={24} 
            color={themeVariables.mainColor}
            style={theme.loginIconInputStyle}
          ></Icon>
          <TextInput
            style={theme.loginInputStyle}
            placeholderTextColor={themeVariables.mainColor}
            onChangeText={text => setValue('password', text)}
            secureTextEntry={true}
            placeholder="Password"
            textContentType="password"
            autoCompleteType="password"
            ></TextInput>
        </View>
        {/* TODO: no checkea que ambas passwords coincidan */}

        {
          errors.password && 
            <Text style={{ color: 'red' }}>La contraseña de estar completa.</Text>
        }
        <View style={theme.loginInputContainerStyle}>
          <Icon
            name='lock'
            size={24} 
            color={themeVariables.mainColor}
            style={theme.loginIconInputStyle}
          ></Icon>
          <TextInput
            style={theme.loginInputStyle}
            placeholderTextColor={themeVariables.mainColor}
            onChangeText={text => setValue('password', text)}
            secureTextEntry={true}
            placeholder="Repetir password"
            textContentType="password"
            autoCompleteType="password"
            ></TextInput>
        </View>
          <View style={{flex: 1}}>
          </View>
          <Button
            containerStyle={[theme.buttonSuccessContainerStyle, {width: "90%", marginBottom: 30, borderRadius: 30}]}
            buttonStyle={[theme.buttonSuccessButtonStyle, {paddingVertical: 16, paddingRight: 26, fontWeight: 'bold'}]}
            titleStyle={[theme.buttonSuccessTitleStyle]}
            icon={
              <Icon 
                name='arrow-forward'
                size={14} 
                color='white'
                style={{
                  paddingTop: 2,
                  paddingRight: 12
                }}  
              />
            }
            onPress={() => {
              handleSubmit(onSubmit)()
            }}
            title="Crear cuenta"
          />
        </View>
        <View style={{marginVertical: 28}}>
          <TouchableOpacity
          onPress={() =>
            navigation.goBack(null)
          }
          >
            <Text
              style={[theme.normalText, theme.registerButton]}
            >
              Login
            </Text>
        </TouchableOpacity>
      </View>
      </ScrollView>);
}
