import * as React from 'react';

import {
    View,
    Text,
    Platform,
    Image
} from 'react-native';
import {
    Button
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as ImagePicker from 'expo-image-picker';
import { FlatList } from 'react-native';
import { addImagesToClaim } from '../../redux/claims';

export default function ClaimImagePicker({ claimId, navigation }) {

    const dispatch = useDispatch();
    const [images, setImage] = React.useState([]);

    const addImage = (image) => { setImage([...images,image]) }

    React.useEffect(() => {
        (async () => {
            if (Platform.OS !== 'web') {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (status !== 'granted') {
                    alert('Sorry, we need camera roll permissions to make this work!');
                }
            }
        })();
    }, []);


    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.cancelled) {
            addImage(result.uri);
        }
    };


    return (
        <View>
            <Text style={{ marginVertical: 10 }}>Para realizar un reclamo envíe imágenes de los productos a reclamar.</Text>
            <Button title="Seleccionar imágenes" onPress={pickImage} />
            <FlatList
                data={images}
                horizontal={true}
                renderItem={({ item, index, separators }) => (
                    <Image source={{ uri: item }} style={{ width: 200, height: 200 }} />
                    )}
            ></FlatList>
            <Button containerStyle={{ marginTop: 15 }} title="Grabar imagenes" onPress={ () => {dispatch(addImagesToClaim(claimId,images))}} />
        </View>
    );
}

