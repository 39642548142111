import * as React from 'react';
import { View, Text, ImageBackground, ScrollView } from 'react-native';
import {
    Button,
    Divider,
    Input
} from 'react-native-elements';
import compaApi from '../../apis/compa';
import theme, { themeVariables } from '../../theme/styles';
import { changeStock, setStateStock} from '../../redux/groupManagement';
import { useDispatch, useSelector } from 'react-redux';
import Toast from 'react-native-toast-message';

export default function ProductManagementScreen({ navigation, route }) {
    const dispatch = useDispatch();

    const product_id = route.params && route.params.productId ? route.params.productId : {};

    const group = useSelector(state => state.groupManagement.group);

    const productsLoaded = useSelector(state => state.groupManagement.loadedCampaigns);
    const campaigns = useSelector(state => state.groupManagement.campaigns);

    const products = campaigns[0] ? campaigns[0].productos_campaña : null
    const product = products.filter(p => p.id == product_id)[0];
    //TODO: en esta screen se deberia poder modificar todos los campos, nombre descripcion etc
    const [ quantity, setQuantity ] = React.useState(0);

    const stateStock = useSelector(state => state.groupManagement.stateStock);

    const [disableStockButton, setDisableStockButton] = React.useState(false);
    const [newStockValue, setNewStockValue] = React.useState('');
    
    React.useEffect(() => {
        if (quantity < 0) {
            setDisableStockButton(true);
            Toast.show({
                type: 'error',
                text1: 'CompaApp - Stock ',
                text2: 'El nuevo stock no puede ser negativo.'
            });
            setTimeout(() => {
            }, 500);
        }
        if (isNaN(quantity) && newStockValue != '') {
            setDisableStockButton(true);
            Toast.show({
                type: 'error',
                text1: 'CompaApp - Stock ',
                text2: 'El nuevo stock debe ser un número.'
            });
            setTimeout(() => {
            }, 500);
        }
        if ((!(quantity < 0) && !(isNaN(quantity))) || newStockValue == '') {
            setDisableStockButton(false);
        }
    }, [quantity, newStockValue]);

    React.useEffect(() => {
        if (stateStock && productsLoaded) {
            Toast.show({
                type: 'info',
                text1: 'CompaApp - Stock ',
                text2: stateStock
            });
            setTimeout(() => {
                // limpiamos el state para futuros usos
                dispatch(setStateStock(null));
            }, 500);
        }
    }, [stateStock, productsLoaded]);

    return (
        <View style={theme.screenContainer}>
            <ScrollView>
                <View style={{ marginBottom: themeVariables.gutter }}>
                    <ImageBackground
                        source={{
                            uri: compaApi.generateImageUri(product.imagen) ? compaApi.generateImageUri(product.imagen) : require('../../assets/logo.png')
                        }}
                        style={{
                            width: '100%',
                            height: 180
                        }}
                    >
                    </ImageBackground>
                </View>
                <View style={[theme.screenContainer, { paddingHorizontal: themeVariables.gutter }]}>
                    <View style={{ flexDirection: 'row', marginBottom: 15, paddingBottom: 15, paddingTop: 4, justifyContent: 'space-between' }}>
                        <View>
                            <Text style={[theme.header7, { marginBottom: themeVariables.smallMargin }]}>{product.nombre}</Text>
                            <Text style={theme.smallText}>
                                Cajón: {product.peso_bulto} kg aprox.
                                Kilo: $ {product.precio_referencia_kilo} aprox.
                            </Text>
                            {
                                product.divisible > 1 &&
                                    <Text style={theme.highlightText}>Fraccionado: $ { product.precio_fraccion } / { product.nombre_fraccion }</Text>
                            }
                            {
                                product.bultos_restantes == 0 &&
                                <Text style={theme.leadingHighlightText}>Sin stock</Text>
                            }
                        </View>
                    </View>

                    <Text style={[theme.normalText, { fontWeight: 'bold' }]}> Origen:  { product.origen }</Text>
                    {/* TODO: este calculo del cajon esta mal */}
                    <Text style={[theme.normalText, { fontWeight: 'bold' }]}> Bultos disponibles:  {product.bultos_disponibles}</Text>
                    {
                        product.descripcion && 
                            <Text style={[theme.normalText, { marginTop: 15 }]}> {product.descripcion}</Text>
                    }
                    {/* Esta UI deberia dejarte elegir la cantidad de elementos para cambiar */}
                    <Divider style={{ marginVertical: 10 }} />
                    <Input 
                        label='Nueva cantidad'
                        containerStyle={{ padding: 0 }}
                        inputContainerStyle={{ borderWidth: 1, borderColor: '#CCC' }}
                        onChangeText={(text) => {
                            setQuantity(parseInt(text, 10));
                            setNewStockValue(text);
                        }}
                        keyboardType='number-pad'
                    />
                    <Button
                        title='Cambiar stock'
                        disabled={disableStockButton}
                        containerStyle={[theme.buttonSuccessContainerStyle]}
                        buttonStyle={[theme.buttonSuccessButtonStyle]}
                        titleStyle={[theme.buttonSuccessTitleStyle]}
                        onPress={() => {
                            dispatch(changeStock(product.id, (quantity - product.bultos_disponibles)))
                        }}
                    />

                    <Divider style={{ marginVertical: 10 }} />
                    <Button
                        title='Reemplazar producto por otro'
                        containerStyle={[theme.buttonSuccessContainerStyle]}
                        buttonStyle={[theme.buttonSuccessButtonStyle]}
                        titleStyle={[theme.buttonSuccessTitleStyle]}
                        onPress={() => {
                            navigation.push('ReplaceProductScreen', { productId: product.id})
                        }}
                    />
                </View>
            </ScrollView>
        </View>
    );
}
