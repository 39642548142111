// Create an instance.
export default class UserApi {
  constructor(baseUrl, network) {
    this.baseUrl = baseUrl;
    this.token = null;
    this.network = network;
  }
  getBalance() {
    return new Promise((resolve, reject) => {
      this.network.get(this.baseUrl + '/users/cuentacorriente', null, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
  changeProfile(data) {
    return new Promise((resolve, reject) => {
      this.network.post(this.baseUrl + '/users/cambiarPerfil', data, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
}