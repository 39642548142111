import compaApi from '../apis/compa';

export const GROUP_MANAGEMENT = 'GROUP MANAGEMENT';

export const GROUP_MANAGEMENT_SET_GROUP = `[${GROUP_MANAGEMENT}] group`;

export const GROUP_MANAGEMENT_ORDERS_LOADING = `[${GROUP_MANAGEMENT}] group orders loading`;
export const GROUP_MANAGEMENT_ORDERS_LOADED = `[${GROUP_MANAGEMENT}] group ordersloaded`;
export const GROUP_MANAGEMENT_SET_RESULT_ORDERS = `[${GROUP_MANAGEMENT}] set result group orders`;

export const GROUP_MANAGEMENT_CAMPAIGNS_LOADING = `[${GROUP_MANAGEMENT}] group products orders`;
export const GROUP_MANAGEMENT_CAMPAIGNS_LOADED = `[${GROUP_MANAGEMENT}] group products loaded`;
export const GROUP_MANAGEMENT_SET_GROUP_CAMPAIGNS = 'GROUP ORDERS set group products';

export const GROUP_MANAGEMENT_CLAIMS_LOADING = `[${GROUP_MANAGEMENT}] group claims orders`;
export const GROUP_MANAGEMENT_CLAIMS_LOADED = `[${GROUP_MANAGEMENT}] group claims loaded`;
export const GROUP_MANAGEMENT_SET_GROUP_CLAIMS = 'GROUP MANAGEMENT set group claims';


export const GROUP_MANAGEMENT_SET_SELECTED_CAMPAIGN = 'GROUP MANAGEMENT set selected campaign';

export const GROUP_MANAGEMENT_SET_STATE_STOCK = `[${GROUP_MANAGEMENT}] set state stock`;

// export const setResultGroupOrders = (result) => ({
//     type: GROUP_MANAGEMENT_SET_RESULT_ORDERS,
//     payload: result
// });

// export const setGroupOrdersLoading = (isLoading) => ({
//     type: GROUP_MANAGEMENT_ORDERS_LOADING,
//     payload: isLoading
// });

// export const setGroupOrdersLoaded = (loaded) => ({
//     type: GROUP_MANAGEMENT_ORDERS_LOADED,
//     payload: loaded
// });
export const setGroup = (group) => ({
    type: GROUP_MANAGEMENT_SET_GROUP,
    payload: group
});
export const setGroupCampaigns = (products) => ({
    type: GROUP_MANAGEMENT_SET_GROUP_CAMPAIGNS,
    payload: products
});
export const setGroupCampaignsLoading = (isLoading) => ({
    type: GROUP_MANAGEMENT_CAMPAIGNS_LOADING,
    payload: isLoading
});

export const setGroupCampaignsLoaded = (loaded) => ({
    type: GROUP_MANAGEMENT_CAMPAIGNS_LOADED,
    payload: loaded
});
export const setSelectedCampaign = (campaign) => ({
    type: GROUP_MANAGEMENT_SET_SELECTED_CAMPAIGN,
    payload: campaign
});
export const setStateStock = (result) => ({
    type: GROUP_MANAGEMENT_SET_STATE_STOCK,
    payload: result
});

// export const setGroupClaims = (products) => ({
//     type: GROUP_MANAGEMENT_SET_GROUP_CLAIMS,
//     payload: products
// });
// export const setGroupClaimsLoading = (isLoading) => ({
//     type: GROUP_MANAGEMENT_CLAIMS_LOADING,
//     payload: isLoading
// });

// export const setGroupClaimsLoaded = (loaded) => ({
//     type: GROUP_MANAGEMENT_CLAIMS_LOADED,
//     payload: loaded
// });




// export const getAllGroupOrders = () => {
//     return (dispatch) => {
//         dispatch(setGroupOrdersLoading(true));
//         compaApi.groupManagement
//             .getAllGroupOrders()
//             .then((result) => {
//                 dispatch(setResultGroupOrders(result));
//                 dispatch(setGroupOrdersLoading(false));
//                 dispatch(setGroupOrdersLoaded(true));
//             })
//             .catch((err) => {
//                 dispatch(setGroupOrdersLoading(false));
//             });
//     };
// };
export const getGroup = () => {
    return (dispatch) => {
        compaApi.groupManagement
            .getGroup()
            .then((result) => {
                dispatch(setGroup(result));
            })
            .catch((err) => {
            });
    };
};

export const getGroupCampaigns = () => {
    return (dispatch) => {
        dispatch(setGroupCampaignsLoading(true));
        compaApi.groupManagement
            .getGroupCampaigns()
            .then((result) => {
                dispatch(setGroupCampaigns(result));
                dispatch(setGroupCampaignsLoading(false));
                dispatch(setGroupCampaignsLoaded(true));
            })
            .catch((err) => {
                dispatch(setGroupCampaignsLoading(false));
            });
    };
};

// TODO: optimizar reactividad, se tienen que descargar todos los productos d todas las campanas cuando se cambia el stock d uno
export const changeStock = (productId,quantity) => {
    return (dispatch) => {
        dispatch(setGroupCampaignsLoading(true));
        let data = {
            cantidad: quantity,
            producto: productId
        }
        compaApi.groupManagement
            .changeStock(data)
            .then((result) => {
                dispatch(getGroupCampaigns())
                dispatch(setStateStock('El stock cambió exitosamente.'));
                dispatch(setGroupCampaignsLoading(false));
                dispatch(setGroupCampaignsLoaded(true));
            })
            .catch((err) => {
                dispatch(setStateStock('Ocurrió un error al cambiar el stock.'));
                dispatch(setGroupCampaignsLoading(false));               
            });
    };
};
export const replaceProduct = (originalId,newId,quantity) => {
    return (dispatch) => {
        dispatch(setGroupCampaignsLoading(true));
        let data = {
            cantidad: quantity,
            original: originalId,
            nuevo: newId
        }
        compaApi.groupManagement
            .replaceProduct(data)
            .then((result) => {
                dispatch(getGroupCampaigns())
                dispatch(setGroupCampaignsLoading(false));
                dispatch(setGroupCampaignsLoaded(true));
            })
            .catch((err) => {
                dispatch(setGroupCampaignsLoading(false));
            });
    };
};
// export const getGroupClaims = () => {
//     return (dispatch) => {
//         dispatch(setGroupClaimsLoading(true));
//         compaApi.groupManagement
//             .getGroupClaims()
//             .then((result) => {
//                 dispatch(setGroupClaims(result));
//                 dispatch(setGroupClaimsLoading(false));
//                 dispatch(setGroupClaimsLoaded(true));
//             })
//             .catch((err) => {
//                 dispatch(setGroupClaimsLoading(false));
//             });
//     };
// };

// export const approveClaimLine = (lineId, state) => {
//     return (dispatch) => {
//         dispatch(setGroupClaimsLoading(true));
//         //TODO: traer el groupId del user, no pedirlo como parametro, ya q un user solo es admin un group
//         let data = {
//             lineaId: lineId,
//             estado: state
//         }
//         compaApi.groupManagement
//             .approveClaimLine(data)
//             .then((result) => {
//             })
//             .catch((err) => {
//             });
//     }
// }




const initialState = {
    group: null,
    // orders: [],
    campaigns: [],
    // claims: [],
    // loadedOrders: false,
    // isLoadingOrders: false,
    loadedCampaigns: false,
    isLoadingCampaigns: false,
    // loadedClaims: false,
    // isLoadingClaims: false,
    selectedCampaign: null,
    stateStock: null
};

export function GroupManagementReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GROUP_MANAGEMENT_SET_GROUP:
            return { ...state, group: payload };
        // case GROUP_MANAGEMENT_SET_RESULT_ORDERS:
        //     return { ...state, orders: payload };
        // case GROUP_MANAGEMENT_ORDERS_LOADED:
        //     return { ...state, loadedOrders: payload };
        // case GROUP_MANAGEMENT_ORDERS_LOADING:
        //     return { ...state, isLoadingOrders: payload };
        case GROUP_MANAGEMENT_CAMPAIGNS_LOADED:
            return { ...state, loadedCampaigns: payload };
        case GROUP_MANAGEMENT_CAMPAIGNS_LOADING:
            return { ...state, isLoadingCampaigns: payload };
        case GROUP_MANAGEMENT_SET_GROUP_CAMPAIGNS:
            return { ...state, campaigns: payload };
        // case GROUP_MANAGEMENT_SET_GROUP_CLAIMS:
        //     return { ...state, claims: payload }
        // case GROUP_MANAGEMENT_CLAIMS_LOADED:
        //     return { ...state, loadedClaims: payload }
        // case GROUP_MANAGEMENT_CLAIMS_LOADING:
        //     return { ...state, isLoadingClaims: payload }
        case GROUP_MANAGEMENT_SET_SELECTED_CAMPAIGN:
            return {...state,selectedCampaign: payload}
        case GROUP_MANAGEMENT_SET_STATE_STOCK:
            return {...state, stateStock: payload}
        default:
            return state;
    }
}
