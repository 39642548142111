
import compaApi from '../apis/compa';

export const GROUPS = 'GROUPS';

export const GROUPS_LOADING = `[${GROUPS}] loading`;

export const GROUPS_LOADED = `[${GROUPS}] loaded`;

export const GROUPS_SET_RESULT_GROUPS = `[${GROUPS}] set result Groups`;

export const GROUPS_SET_MAP_VIEW = `[${GROUPS}] set Groups map view`;

export const GROUPS_SET_LAST_EMPTY_SEARCH = `[${GROUPS}] set last empty search`;


export const setResultGroups = (result) => ({
    type: GROUPS_SET_RESULT_GROUPS,
    payload: result
});

export const setGroupsLoading = (isLoading) => ({
    type: GROUPS_LOADING,
    payload: isLoading
});

export const setGroupsLoaded = (loaded) => ({
    type:  GROUPS_LOADED,
    payload: loaded
});

export const setMapView = (state) => ({
    type: GROUPS_SET_MAP_VIEW,
    payload: state
});

export const setLastEmptySearch = (text) => ({
    type: GROUPS_SET_LAST_EMPTY_SEARCH,
    payload: text
});

export const getAllGroups = () => {
    return (dispatch) => {
        dispatch(setLastEmptySearch(null));
        dispatch(setGroupsLoading(true));
        compaApi.groups
            .close()
            .then((response) => {
                dispatch(
                    setResultGroups(response.data ? response.data : [])
                );
                dispatch(setGroupsLoading(false));
                dispatch(setGroupsLoaded(true));
            })
            .catch(() => {
                dispatch(setGroupsLoading(false));
            });
    };
};
export const searchGroups = (search) => {
    return (dispatch, getState) => {
        dispatch(setGroupsLoading(true));
        if (!search) {
            dispatch(getAllGroups())
        } else {
            if (!search.startsWith(getState().groups.lastEmptySearch)) {
                compaApi.groups
                    .search(search)
                    .then((response) => {
                        let groups = response ? response : [];
                        dispatch(setLastEmptySearch(groups.length === 0 ? search : null));
                        dispatch(
                            setResultGroups(groups)
                        );
                        dispatch(setGroupsLoading(false));
                        dispatch(setGroupsLoaded(true));
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(setGroupsLoading(false));
                    });
            }
        }
    };
};


const initialState = {
    groups: [],
    loaded: false,
    isLoading: false,
    mapView: false,
    lastEmptySearch: null
};

export function GroupsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GROUPS_SET_RESULT_GROUPS:
            return { ...state, groups: payload };
        case GROUPS_LOADED:
            return { ...state, loaded: payload };
        case GROUPS_LOADING:
            return { ...state, isLoading: payload };
        case GROUPS_SET_MAP_VIEW:
            return { ...state, mapView: payload};
        case GROUPS_SET_LAST_EMPTY_SEARCH:
            return { ...state, lastEmptySearch: payload};
        default:
            return state;
    }
}
