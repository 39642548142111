import React, { useEffect } from 'react';
import { doRecoverPassword, authSetRememberResult, changePasswordWithCode, authSetRememberChangeResult } from '../../redux/auth';
import { Text, View, Image, TextInput, TouchableOpacity } from 'react-native';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import theme, { themeVariables } from '../../theme/styles';
import { Button, Icon } from 'react-native-elements';
import Toast from 'react-native-toast-message';

export default function RegisterScreen ({ navigation }) {
    const dispatch = useDispatch();

    const rememberResult = useSelector(state => state.auth.rememberResult);
    const rememberChangeResult = useSelector(state => state.auth.rememberChangeResult);
    const [ allowToResetPassword, setAllowToResetPassword ] = React.useState(false);
    const [ loading, setLoading ] = React.useState(false);
    const [ newPassword, setNewPassword ] = React.useState('');
    const [ recuperationCode, setRecuperationCode ] = React.useState('');
    const [ email, setEmail ] = React.useState('');

    const { register, handleSubmit, setValue, errors } = useForm();
    React.useEffect(() => {
        register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i });
    }, [register]);

    const onSubmit = (data) => {
      dispatch(doRecoverPassword(data));
    };

    React.useEffect(() => {
      if (rememberResult) {
        setLoading(false);
        console.log(rememberResult, rememberResult.message === 'Ok');
        if (rememberResult.message === 'Ok') {
          setAllowToResetPassword(true);
        } else {
          Toast.show({
              type: 'error',
              text1: 'CompaApp - Recuperación de contraseña',
              text2: 'Ocurrió un error al recuperar contraseña'
          });
          setAllowToResetPassword(false);
        }
        setTimeout(() => {
          dispatch(authSetRememberResult(null));
        }, 500);
      }
    }, [rememberResult]);


    React.useEffect(() => {
      if (rememberChangeResult) {
        setLoading(false);
        console.log(rememberChangeResult, rememberChangeResult.message === 'Ok');
        if (rememberChangeResult.message === 'Ok') {
          setAllowToResetPassword(false);
          Toast.show({
              type: 'info',
              text1: 'CompaApp - Recuperación de contraseña',
              text2: 'Contraseña restablecida con éxito.'
          });
        } else {
          Toast.show({
              type: 'error',
              text1: 'CompaApp - Recuperación de contraseña',
              text2: 'Ocurrió un error al resetear la contraseña'
          });
        }
        setTimeout(() => {
          dispatch(authSetRememberChangeResult(null));
        }, 500);
      }
    }, [rememberChangeResult]);


    return (
    <View style={theme.verticalContainer, theme.fullWidth}>
      <View style={[theme.verticalContainer, theme.topCard, theme.bigShadow, theme.fullWidth]}>
        <Image 
          source={{ uri: require('../../assets/logo-square.png') }}
          style={[theme.logo, {marginBottom: 8}]}
        />
        {
          !allowToResetPassword && 
            <>
              <Text style={[theme.header2]}>
                Recuperar su contraseña
              </Text>
              <View style={theme.loginInputContainerStyle}>
                <Icon
                  name='email'
                  size={24} 
                  color={themeVariables.mainColor}
                  style={theme.loginIconInputStyle}
                ></Icon>
                <TextInput
                  style={theme.loginInputStyle}
                  placeholderTextColor={themeVariables.mainColor}
                  onChangeText={text => {
                    setValue('email', text);
                    setEmail(text);
                  }}
                  placeholder="Email"
                  textContentType="emailAddress"
                  autoCompleteType="email"
                  ></TextInput>
              </View>
              <View style={{flex: 1}}>
              </View>
              <Button
                containerStyle={[theme.buttonSuccessContainerStyle, {width: "90%", marginBottom: 30, borderRadius: 30}]}
                buttonStyle={[theme.buttonSuccessButtonStyle, {paddingVertical: 16, paddingRight: 26, fontWeight: 'bold'}]}
                titleStyle={[theme.buttonSuccessTitleStyle]}
                icon={
                  <Icon 
                    name='arrow-forward'
                    size={14} 
                    color='white'
                    style={{
                      paddingTop: 2,
                      paddingRight: 12
                    }}  
                  />
                }
                onPress={() => {
                  handleSubmit(onSubmit)();
                  setLoading(true);
                }}
                title="Recuperar contraseña"
                loading={loading}
              />
            </>
        }
        {
          allowToResetPassword && 
            <>
              <Text style={[theme.header2]}>
                Cambiar su contraseña
              </Text>
              <View style={theme.loginInputContainerStyle}>
                <Icon
                  name='lock'
                  size={24} 
                  color={themeVariables.mainColor}
                  style={theme.loginIconInputStyle}
                ></Icon>
                <TextInput
                  style={theme.loginInputStyle}
                  placeholderTextColor={themeVariables.mainColor}
                  onChangeText={text => setRecuperationCode(text)}
                  secureTextEntry={true}
                  placeholder="Código de recuperación"
                  ></TextInput>
                </View>
              <View style={theme.loginInputContainerStyle}>
                <Icon
                  name='lock'
                  size={24} 
                  color={themeVariables.mainColor}
                  style={theme.loginIconInputStyle}
                ></Icon>
                <TextInput
                  style={theme.loginInputStyle}
                  placeholderTextColor={themeVariables.mainColor}
                  onChangeText={text => setNewPassword(text)}
                  secureTextEntry={true}
                  placeholder="Nuevo password"
                  textContentType="password"
                  autoCompleteType="password"
                  ></TextInput>
                </View>
              <View style={{flex: 1}}>
              </View>
              <Button
                containerStyle={[theme.buttonSuccessContainerStyle, {width: "90%", marginBottom: 30, borderRadius: 30}]}
                buttonStyle={[theme.buttonSuccessButtonStyle, {paddingVertical: 16, paddingRight: 26, fontWeight: 'bold'}]}
                titleStyle={[theme.buttonSuccessTitleStyle]}
                icon={
                  <Icon 
                    name='arrow-forward'
                    size={14} 
                    color='white'
                    style={{
                      paddingTop: 2,
                      paddingRight: 12
                    }}  
                  />
                }
                onPress={() => {
                  if (newPassword && recuperationCode) {
                    setLoading(true);
                    dispatch(changePasswordWithCode({
                      code: recuperationCode,
                      password: newPassword,
                      email: email
                    }));
                  } else {
                    Toast.show({
                        type: 'error',
                        text1: 'CompaApp - Recuperación de contraseña',
                        text2: 'Ingresa todos los valores'
                    });
                  }
                }}
                title="Resetear contraseña"
                loading={loading}
              />
            </>
        }
        {/* Tal vez sea útil para restaurar la contraseña. Sino borrar.
        
        <View style={theme.loginInputContainerStyle}>
          <Icon
            name='lock'
            size={24} 
            color={themeVariables.mainColor}
            style={theme.loginIconInputStyle}
          ></Icon>
          <TextInput
            style={theme.loginInputStyle}
            placeholderTextColor={themeVariables.mainColor}
            onChangeText={text => setValue('email', text)}
            secureTextEntry={true}
            placeholder="Password"
            textContentType="password"
            autoCompleteType="password"
            ></TextInput>
        </View>
        */}
        </View>
        <View style={{marginVertical: 28}}>
          <TouchableOpacity
          onPress={() =>
            navigation.goBack(null)
          }
          >
            <Text
              style={[theme.normalText, theme.registerButton]}
            >
              Login
            </Text>
        </TouchableOpacity>
      </View>
      </View>);
}
