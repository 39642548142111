import * as React from 'react';
import { modifyProduct } from '../../redux/orders'
import { Text, View } from 'react-native';
import { useDispatch, useSelector} from 'react-redux';
import { Icon, Button } from 'react-native-elements'
import theme, { themeVariables } from '../../theme/styles';
import { getPointProducts } from '../../redux/products';
import { localModifyProduct, modifyLocalProduct } from '../../redux/localOrders';

export default function ItemPrice ({ item, order, isCart }) {
    const pointIdField = item.producto_point_id ? 'producto_point_id' : 'point_id';
    const idField = item.producto_campaña_id ? 'producto_campaña_id' : 'id';
    const subscribed = getProductSubscribed(order, item[pointIdField], item[idField]);
    const localOrders = useSelector(state => state.localOrders.orders);
    const user = useSelector(state => state.auth.user);
    const localAmount = localOrders[item.point_id] && localOrders[item.point_id][item.id] ? localOrders[item.point_id][item.id].amount : 0;
    const quantity = user ? getProductTotal(order, item[pointIdField], item[idField]) : localAmount;
    const divisible = item.producto_campaña.divisible;
    let unit = quantity;
    let fraction = 0;
    if (divisible > 1) {
        unit = Math.floor(quantity / divisible);
        fraction = quantity % divisible;
    }
    const productPointId = item.producto_point_id ? item.producto_point_id : item.id; 
    let price = 0;
    const pointPrice = isCart ? item.producto_point.precio : item.precio;
    const pointFractionPrice = item.precio_fraccion ? item.precio_fraccion : (item.producto_point ? item.producto_point.precio_fraccion : 0);
    if (divisible > 1) {
        const isFractionated = quantity % divisible != 0;
        const fractionPrice = pointPrice / divisible;
        price = fractionPrice * quantity + (isFractionated ? pointFractionPrice : 0);
    } else {
        price = quantity * pointPrice;
    }
    /*
        $precio_producto = $this->productoPoint->precio;
        $precio_fraccionionamiento = $this->productoPoint->precio_fraccion;

        if ($divisible > 1) {
            $fraccionado = $this->cantidad_pedido % $divisible != 0;
            $precio_fraccion = $precio_producto / $divisible;
            return $precio_fraccion * $this->cantidad_pedido + ($fraccionado ? $precio_fraccionionamiento : 0);
        } else {
            return $this->cantidad_pedido * $precio_producto;
        }
    */
    return (
       <View>
           {
               price > 0 &&
                    <Text style={[theme.highlightText, { fontSize: 22 }]}>$ {price}</Text>
           }
       </View>
    );
}

function getProductTotal(order, point_id, id) {
    if (order) {
        for (let lineaKey in order.linea_pedidos) {
            if (order.linea_pedidos[lineaKey].producto_campaña_id === id) {
                return order.linea_pedidos[lineaKey].cantidad_pedido;
            }
        }
    }
    return 0;
}
function getProductSubscribed(order, point_id, id) {
    if (order) {
        for (let lineaKey in order.linea_pedidos) {
            if (order.linea_pedidos[lineaKey].producto_campaña_id === id) {
                return order.linea_pedidos[lineaKey].cantidad_suscrito;
            }
        }
    }
    return 0;
}