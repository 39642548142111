import * as React from 'react';
import { Linking } from 'react-native';
import {
    View,
    Text
} from 'react-native';
import {
    Button
} from 'react-native-elements';
import theme, { themeVariables } from '../theme/styles';

export default function MercadoPagoInfo ({ data, navigation }) {
    return (
        <View style={{ flexDirection: 'row' }}>
            <Text selectable>Cuenta: { data ? data.cbu : "no se encontro el cbu" }</Text>
            <Text onPress={() => Linking.openURL(data.link)}> Pagar </Text>
        </View>
    );
}
  