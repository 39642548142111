import { throttle, debounce } from 'lodash';
import { searchUsers } from '../redux/miniGroupInvite';
import { useDispatch } from 'react-redux';
import * as React from 'react';

export default (query) => {
  const dispatch = useDispatch();
  const dispatchSearchUsers = (q) => {
    dispatch(searchUsers(q));
  };
  let searchDebounced = debounce(dispatchSearchUsers, 800);
  let searchThrottled = throttle(dispatchSearchUsers, 800);
  
  const search = React.useCallback((q) => {
    if (q.length < 5) {
      searchDebounced(q);
    } else {
      searchThrottled(q);
    }
  }, []);

  React.useEffect(() => {
    search(query);
  }, [query]);
}