import * as React from 'react';
import { modifyProduct } from '../../redux/orders'
import { Text, View } from 'react-native';
import { useDispatch, useSelector} from 'react-redux';
import { Icon, Button } from 'react-native-elements'
import theme, { themeVariables } from '../../theme/styles';
import { getPointProducts } from '../../redux/products';
import { localModifyProduct, modifyLocalProduct } from '../../redux/localOrders';

function MCD(){
    if (arguments.length<2) return false;
    if (arguments.length==2)return (arguments[1]==0?arguments[0]:MCD(arguments[1],arguments[0]%arguments[1]));
    var arr=[].splice.call(arguments,0);
    arr.splice(0,2,MCD(arr[0],arr[1]));
    return MCD.apply(window,arr);
}

export default function ItemQuantityInput ({ item, order, isCart }) {
    const dispatch = useDispatch();
    const pointIdField = item.producto_point_id ? 'producto_point_id' : 'point_id';
    const idField = item.producto_campaña_id ? 'producto_campaña_id' : 'id';
    const subscribed = getProductSubscribed(order, item[pointIdField], item[idField]);
    const localOrders = useSelector(state => state.localOrders.orders);
    const user = useSelector(state => state.auth.user);
    const localAmount = localOrders[item.point_id] && localOrders[item.point_id][item.id] ? localOrders[item.point_id][item.id].amount : 0;
    const quantity = user ? getProductTotal(order, item[pointIdField], item[idField]) : localAmount;
    let divisible = item.producto_campaña.divisible;
    let unit = quantity;
    let fraction = 0;
    let mcd = 1;
    if (divisible > 1) {
        unit = Math.floor(quantity / divisible);
        fraction = quantity % divisible;
        mcd = MCD(item.producto_campaña.divisible, fraction);
        if (mcd > 1) {
            divisible = divisible / mcd;
            fraction = fraction / mcd;
        }
    }
    const productPointId = item.producto_point_id ? item.producto_point_id : item.id; 
     
    return (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Button
                title=''
                buttonStyle={[theme.buttonAddRemoveButtonStyle]}
                icon={
                    <Icon
                        name='add'
                        size={16}
                        color={themeVariables.textColor}
                    />
                }
                onPress={() => {
                    if(user) {
                        dispatch(modifyProduct(productPointId, 1))
                        /* setTimeout(() => {
                            dispatch(getPointProducts(item.point_id));
                        }, 1000); */
                    } else {
                        dispatch(modifyLocalProduct(item, item.point_id, item.id, 1))
                    };
                }}
            />
            <View style={{ marginBottom: 5 }}>
                <Text style={[theme.normalText, { marginVertical: 5, fontSize: 18, textAlign: 'center' }]}>
                    {
                        item.producto_campaña.divisible > 1 &&
                            <Text>
                                { unit > 0 ? (unit + ' ') : ''} 
                                <Text style={{ fontSize: 12 }}>{ fraction > 0 ? (fraction + '/' + divisible) : '0' }</Text>
                            </Text>
                    }
                    
                    {
                        item.producto_campaña.divisible == 1 &&
                            <Text>{quantity}</Text>
                    }
                </Text>
                {
                    quantity > 0 && 
                        <Text style={[theme.normalText, { fontSize: 11, textAlign: 'center' }]}>{ item.producto_campaña.nombre_fraccion }</Text>
                }
                <Text>
                    {
                        subscribed > 0 && 
                            <Text style={[theme.normalText, { fontSize: 10, textAlign: 'center' }]}>{'(' + subscribed + ' fracciones)'}</Text>
                    } 
                </Text>
            </View>
            <Button
                title=''
                buttonStyle={[theme.buttonAddRemoveButtonStyle]}
                icon={
                    <Icon
                        name='remove'
                        size={16}
                        color={themeVariables.textColor}
                    />
                }
                disabled={quantity == 0}
                onPress={() => {
                    if(user){
                        dispatch(modifyProduct(productPointId, -1))
                        dispatch(getPointProducts(item.point_id));
                    } else {
                        dispatch(modifyLocalProduct(item, item.point_id, item.id, -1))
                    }
                }}
            />
        </View>
    );
}

function getProductTotal(order, point_id, id) {
    if (order) {
        for (let lineaKey in order.linea_pedidos) {
            if (order.linea_pedidos[lineaKey].producto_campaña_id === id) {
                return order.linea_pedidos[lineaKey].cantidad_pedido;
            }
        }
    }
    return 0;
}
function getProductSubscribed(order, point_id, id) {
    if (order) {
        for (let lineaKey in order.linea_pedidos) {
            if (order.linea_pedidos[lineaKey].producto_campaña_id === id) {
                return order.linea_pedidos[lineaKey].cantidad_suscrito;
            }
        }
    }
    return 0;
}