import { throttle, debounce } from 'lodash';
import { searchGroups } from '../redux/groups';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';

export default (query) => {
  const groups = useSelector(state => state.groups.groups);
  const dispatch = useDispatch();
  const dispatchSearchGroups = (q) => {
    dispatch(searchGroups(q));
  };
  let searchDebounced = debounce(dispatchSearchGroups, 800);
  let searchThrottled = throttle(dispatchSearchGroups, 1000);
  
  const search = React.useCallback((q) => {
    if (q.length > 5) {
      searchDebounced(q);
    } else {
      searchThrottled(q);
    }
  }, []);

  React.useEffect(() => {
    search(query);
  }, [query]);
}