import AuthApi from './authApi';
import GroupsApi from './groupsApi';
import ProductsApi from './productsApi';
import OrdersApi from './ordersApi';
import MiniGroupsApi from './miniGroupsApi'
import PointManagementApi from './pointManagementApi'
import NotifcationsApi from './notificationsApi'
import ClaimsApi from './claimsApi'
import UserApi from './userApi'
import GroupManagementApi from './groupManagementApi'
import network from '../../utils/network';
import Constants from 'expo-constants';
import ProducerApi from './producerApi';
// const baseUrl = Constants.manifest.extra.compa_base_url;
let baseUrl = "https://backoffice.redcompa.com/";
// const 
if (__DEV__) {
    // baseUrl = "http://localhost:8080/";
}
const baseUrlApi = baseUrl + 'api';
export default {
    baseUrl: baseUrl,
    setApiToken(token) {
        if (token) {
            const tokenHeader = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            };
            console.log('setApiToken', token);
            this.auth.token = tokenHeader;
            this.groups.token = tokenHeader;
            this.products.token = tokenHeader;
            this.orders.token = tokenHeader;
            this.MiniGroups.token = tokenHeader;
            this.pointManagement.token = tokenHeader;
            this.notifications.token = tokenHeader;
            this.claims.token = tokenHeader;
            this.user.token = tokenHeader;
            this.groupManagement.token = tokenHeader;
            this.producer.token = tokenHeader;
        } else {
            this.auth.token = {};
            this.groups.token = {};
            this.products.token = {};
            this.orders.token = {};
            this.MiniGroups.token = {};
            this.pointManagement.token = {};
            this.notifications.token = {};
            this.claims.token = {};
            this.user.token = {};
            this.groupManagement.token = {};
            this.producer.token = {};
        }

    },
    generateImageUri (name, path) {
        if (name) {
            return baseUrl + (path ? path : 'img/') + name;
        }
    },
    auth: new AuthApi(baseUrlApi, network),
    groups: new GroupsApi(baseUrlApi, network),
    MiniGroups: new MiniGroupsApi(baseUrlApi, network),
    products: new ProductsApi(baseUrlApi, network),
    orders: new OrdersApi(baseUrlApi, network),
    pointManagement: new PointManagementApi(baseUrlApi, network),
    notifications: new NotifcationsApi(baseUrlApi, network),
    claims: new ClaimsApi(baseUrlApi, network),
    user: new UserApi(baseUrlApi,network),
    groupManagement: new GroupManagementApi(baseUrlApi, network),
    producer: new ProducerApi(baseUrlApi, network)
};

