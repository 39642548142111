import * as React from 'react';

import {
    View,
    Text
} from 'react-native';

import theme, { themeVariables } from '../theme/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getBalance } from '../redux/user';

import { displayOrderStatusName, totalizadorPeso, totalizadorPagos, totalizadorPrecio } from '../utils/helpers';

export default function CartResume (props) {
    const dispatch = useDispatch();
    const orders = useSelector(state => state.orders.orders);
    const localOrders = useSelector(state => state.localOrders.orders);
    const groups = useSelector(state => state.groups.groups);
    const user = useSelector(state => state.auth.user);
    const summary = useSelector(state => state.user.summary);
    const summaryTotal = useSelector(state => state.user.summaryTotal);
    let group = null;
    let point = props.pointParam;
    if (!point.id && orders && Object.keys(orders).length && groups && groups.length) {
      // FIXME necesito el objeto point del grupoo actual
      for (let index = 0; index < groups.length; index++) {
        group = groups[index];
        point = group.points.find(p => p.id == Object.keys(orders)[0]);
        if (point.id) {
          break;
        }
      }
      // point = { id: Object.keys(orders)[0] };
    }
    let total = 

    React.useEffect(() => {
        if (!summary) {
            dispatch(getBalance());
        }
    }, []);

    if (orders[point.id]) {
        
        return (
            <View style={{ backgroundColor: themeVariables.loginBackgroundColor, padding: themeVariables.gutter, paddingBottom: 5, borderTopColor: themeVariables.borderColor, borderTopWidth: 1 }}>
                {
                    props.title &&
                        <View style={{  }}>
                            <Text style={[theme.header4, { color: themeVariables.mainColor, marginTop: 0 }]}>
                                { props.title }
                            </Text>
                        </View>

                }
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 8 }}>
                <View style={{ alignItems: 'flex-end' }}>
                    <Text style={[theme.normalText, { fontWeight: 'bold' }]}>Estado: </Text>
                    <Text style={[theme.normalText, { fontWeight: 'bold' }]}>Cantidad de productos</Text>
                    <Text style={{ fontWeight: 'bold', color: themeVariables.mainColor }}>Peso total aprox.</Text>
                    <Text style={{ fontWeight: 'bold', color: themeVariables.mainColor, paddingTop: 5, fontSize: 16 }}>Total</Text>
                    {
                        summaryTotal < 0 &&
                            <Text style={{ fontWeight: 'bold', color: themeVariables.mainColor, paddingTop: 5, fontSize: 16 }}>Saldo por pagar</Text>
                    }
                </View>
                <View style={{ alignItems: 'flex-end', paddingLeft: 16, paddingRight: 8 }}>
                    <Text style={{ fontWeight: 'bold', color: themeVariables.mainColor }}>{ displayOrderStatusName(orders[point.id] ? orders[point.id].estado : 0) }</Text> 
                    <Text style={[theme.normalText]}>{orders[point.id] ? orders[point.id].linea_pedidos.filter(lp => lp.cantidad_pedido > 0).length : 0}</Text>
                    <Text style={{ color: themeVariables.mainColor }}>{orders[point.id] ? orders[point.id].linea_pedidos.reduce(totalizadorPeso, 0) : 0} kg</Text>
                    <Text style={{ fontWeight: 'bold', color: themeVariables.mainColor, paddingTop: 5, fontSize: 16 }}>$ {orders[point.id] ? orders[point.id].linea_pedidos.reduce(totalizadorPrecio, 0) : 0}</Text>
                    {
                        summaryTotal < 0 &&
                            <Text style={{ fontWeight: 'bold', color: 'red', paddingTop: 5, fontSize: 16 }}>
                                $ { -1 * summaryTotal }
                            </Text>
                    }
                </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                { props.children }
                </View>
            </View>
        );
    } else {
        return (<></>);
    }
}