import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import GruposScreen from '../screens/GruposScreen';
import GrupoScreen from '../screens/GrupoScreen';
import PointProductsScreen from '../screens/PointProductsScreen';
import PointManagementScreen from '../screens/PointManagement/PointManagementScreen';
import OrderDetailScreen from '../screens/PointManagement/UserOrderDetailScreen';
import ProductsScreen from '../screens/ProductsScreen';
import CartScreen from '../screens/CartScreen';
import OrderSelectionScreen from '../screens/OrderSelectionScreen';
import HomeScreen from '../screens/HomeScreen';
import NotificacionesScreen from '../screens/NotificacionesScreen';
import ProductScreen from '../screens/ProductScreen';
import MiniGroupInviteScreen from '../screens/MiniGroupInviteScreen';
import ConfirmAndPayScreen from '../screens/payment/ConfirmAndPayScreen';
import FormOfPaymentScreen from '../screens/payment/FormOfPaymentScreen';
import ReceiptOfPaymentScreen from '../screens/payment/ReceiptOfPaymentScreen';
import ClaimsScreen from '../screens/claims/ClaimsScreen'
import ClaimScreen from '../screens/claims/ClaimScreen'
import AddToClaimScreen from '../screens/claims/AddToClaimScreen';
import PastOrdersScreen from '../screens/PastOrdersScreen';
import BalanceScreen from '../screens/BalanceScreen';
import ClaimManagementScreen from '../screens/PointManagement/ClaimManagementScreen';
import DeliveryOverviewScreen from '../screens/PointManagement/DeliveryOverviewScreen';
import PastOrderDetailScreen from '../screens/PastOrderDetailScreen.js';
import GroupManagementScreen from '../screens/groupManagement/GroupManagementScreen'
import ProductManagementScreen from '../screens/groupManagement/ProductManagementScreen'
import ReplaceProductScreen from '../screens/groupManagement/ReplaceProductScreen'
import ManageMinigroupScreen from '../screens/minigroup/ManageMinigroupScreen'
import PointPaymentScreen from '../screens/PointManagement/PointPaymentScreen'
import { useSelector } from 'react-redux';
import {
  Image
} from 'react-native';
import {
  Icon
} from 'react-native-elements';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import LoginNavigator from './LoginNavigator';
import theme, { themeVariables } from '../theme/styles';
import ProfileScreen from '../screens/ProfileScreen';
import ProducerScreen from '../screens/producer/ProducerScreen';

const Tab = createBottomTabNavigator();

function getHeaderTitle(route) {
  // If the focused route is not found, we need to assume it's the initial screen
  // This can happen during if there hasn't been any navigation inside the screen
  // In our case, it's "Feed" as that's the first screen inside the navigator
  if (route) {
    console.log('Route name: ' + route.name);
    switch (route.name) {
      case 'CompaApp':
        return '';
    }
  }
  return 'Compa - Juntos es mejor';
}

function generateNavigationOptions(route, hideBottomBar) {
  return {
    headerTitle: (props) => ( // App Logo
      <Image
        style={{ width: 210, height: themeVariables.headerBarHeight - 10, marginTop: 10 }}
        source={require('../assets/logo-horizontal.png')}
        resizeMode='contain'
      />
    ),
    headerTitleStyle: {
      color: themeVariables.mainColor
    },
    headerStyle: theme.headerBottom
  };
}

function GrupoStack({ callCart }) {
  const GrupoStack = createStackNavigator();
  const user = useSelector((state) => state.auth.user);
  if (callCart && !user) {
    return <LoginNavigator />
  }
  return (
    <GrupoStack.Navigator
      screenOptions={({ route }) => {
        return generateNavigationOptions(route);
      }}
    >
      {
        callCart &&
        <GrupoStack.Screen
          name="OrderSelectionScreen"
          component={OrderSelectionScreen}
        />
      }
      <GrupoStack.Screen
        name="Grupos"
        component={GruposScreen}
        options={{
          headerStyle: theme.noHeaderBottom
        }}
      />
      <GrupoStack.Screen
        name="Grupo"
        component={GrupoScreen}
      />
      <GrupoStack.Screen
        name="PointProducts"
        component={PointProductsScreen}
        options={{
          headerStyle: theme.noHeaderBottom
        }}
      />
      <GrupoStack.Screen
        name="PointManagement"
        component={PointManagementScreen}
      />
      <GrupoStack.Screen
        name="OrderDetail"
        component={OrderDetailScreen}
      />
      <GrupoStack.Screen
        name="Productos"
        component={ProductsScreen}
      />
      <GrupoStack.Screen
        name="Product"
        component={ProductScreen}
      />
      <GrupoStack.Screen
        name="Cart"
        component={CartScreen}
      />

      <GrupoStack.Screen
        name="MinigroupInvite"
        component={MiniGroupInviteScreen}
      />
      <GrupoStack.Screen
        name="ConfirmAndPayScreen"
        component={ConfirmAndPayScreen}
      />
      <GrupoStack.Screen
        name="FormOfPaymentScreen"
        component={FormOfPaymentScreen}
      />
      <GrupoStack.Screen
        name="ReceiptOfPaymentScreen"
        component={ReceiptOfPaymentScreen}
      />
      <GrupoStack.Screen
        name="DeliveryOverviewScreen"
        component={DeliveryOverviewScreen}
      />
      <GrupoStack.Screen
        name="ClaimManagementScreen"
        component={ClaimManagementScreen}
      />
      <GrupoStack.Screen
        name="GroupManagementScreen"
        component={GroupManagementScreen}
      />
      <GrupoStack.Screen
        name="ProductManagementScreen"
        component={ProductManagementScreen}
      />
      <GrupoStack.Screen
        name="ReplaceProductScreen"
        component={ReplaceProductScreen}
      />
      <GrupoStack.Screen
        name="PointPaymentScreen"
        component={PointPaymentScreen}
      />
      <GrupoStack.Screen
        name="ProducerScreen"
        component={ProducerScreen}
      />
    </GrupoStack.Navigator>
  );
}

function PerfilStack() {
  const PerfilStack = createStackNavigator();
  const user = useSelector((state) => state.auth.user);
  if (!user) {
    return <LoginNavigator />
  }
  return (
    <PerfilStack.Navigator
      screenOptions={({ route, navigation }) => {
        return generateNavigationOptions(route);
      }}
    >
      <PerfilStack.Screen
        name="ProfileScreen"
        component={ProfileScreen}
      />
      <PerfilStack.Screen
        name="Home"
        component={HomeScreen}
      />
      <PerfilStack.Screen
        name="BalanceScreen"
        component={BalanceScreen}
      />

      <PerfilStack.Screen
        name="ClaimsScreen"
        component={ClaimsScreen}
      />
      <PerfilStack.Screen
        name="ClaimScreen"
        component={ClaimScreen}
      />
      <PerfilStack.Screen
        name="AddToClaimScreen"
        component={AddToClaimScreen}
      />
      <PerfilStack.Screen
        name="PastOrdersScreen"
        component={PastOrdersScreen}
      />
      <PerfilStack.Screen
        name="PastOrderDetailScreen"
        component={PastOrderDetailScreen}
      />

      <PerfilStack.Screen
        name="ManageMinigroupScreen"
        component={ManageMinigroupScreen}
      />
    </PerfilStack.Navigator>
  );
}


function NotificacionesStack() {
  const NotificacionesStack = createStackNavigator();
  const user = useSelector((state) => state.auth.user);
  if (!user) {
    return <LoginNavigator />
  }
  return (
    <NotificacionesStack.Navigator
      screenOptions={({ route, navigation }) => {
        return generateNavigationOptions(route);
      }}
    >
      <NotificacionesStack.Screen
        name="Notificaciones"
        component={NotificacionesScreen}
      />
    </NotificacionesStack.Navigator>
  );
}

export default function AppNavigator() {
  const user = useSelector(state => state.auth.user);
  const notificationCounts = user && Array.isArray(user.notificaciones) && user.notificaciones.filter(n => !n.leida).length;
  // let orderCount = 0;
  const orderCount = useSelector(state => state.orders.orderItemCounts);
  /* const localOrders = useSelector(state => state.localOrders.orders); */
  
  /* if (orders && orders.length > 0) {
    orders.forEach((o) => {
      orderCount += o.linea_pedidos.length;
    });
  } else {
    if (localOrders && localOrders.length > 0) {
      localOrders.forEach((o) => {
        orderCount += o.linea_pedidos.length;
      });
    }
  }
  console.log('orderCount', orderCount); */
  return (
    <Tab.Navigator
      tabBarOptions={{
        activeTintColor: themeVariables.mainComplementaryColor,
        style: { paddingTop: 8, marginBottom: 8 }
      }}
    >
      <Tab.Screen
        name="Home"
        component={GrupoStack}
        options={{
          tabBarLabel: 'Buscar',
          tabBarIcon: ({ color, size }) => (
            <Icon name="search" color={color} size={size} />
          ),
        }}
      />
      <Tab.Screen
        name="Carrito"
        component={GrupoStack.bind(null, { callCart: true })}
        options={{
          tabBarLabel: 'Carrito',
          tabBarIcon: ({ color, size }) => (
            <Icon name="shopping-cart" color={color} size={size} />
          ),
          tabBarBadge: orderCount > 0 ? orderCount : undefined
        }}
      />
      <Tab.Screen
        name="Notificaciones"
        component={NotificacionesStack}
        options={{
          tabBarLabel: 'Notificaciones',
          tabBarIcon: ({ color, size }) => (
            <Icon name="bell" type='font-awesome' color={color} size={size - 5} />
          ),
          tabBarBadge: notificationCounts > 0 ? notificationCounts : undefined
        }}
      />
      <Tab.Screen
        name="Settings"
        component={PerfilStack}

        options={{
          tabBarLabel: 'Perfil',
          tabBarIcon: ({ color, size }) => (
            <Icon name="person" color={color} size={size} />
          ),
        }}
      />
    </Tab.Navigator>

  );
}
