// Create an instance.
export default class ProducerApi {
  constructor(baseUrl, network) {
    this.baseUrl = baseUrl;
    this.token = null;
    this.network = network;
  }
  getProducts() {
    return new Promise((resolve, reject) => {
      this.network.get(this.baseUrl + '/productor/productos', null, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
  pauseProduct(data) {
    return new Promise((resolve, reject) => {
      this.network.post(this.baseUrl + '/productor/pausar', data, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
}