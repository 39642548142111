import * as React from 'react';
import { modifyProduct } from '../../redux/orders'
import { localModifyProduct, modifyLocalProduct } from '../../redux/localOrders'
import { CheckBox } from 'react-native-elements'
import { useDispatch, useSelector } from 'react-redux';
import theme, { themeVariables } from '../../theme/styles';

export default function ReplaceItemCheckbox({ item, order }) {
    const dispatch = useDispatch();
    const [replace, toggleReplace] = React.useState(!!item.acepta_sustitucion);
    const orderId = order && order.id;
    const user = useSelector(state => state.auth.user);
    const productPointId = item.producto_point_id ? item.producto_point_id : item.id; 

    return (
        <CheckBox
            title='No reemplazar'
            checked={replace}
            containerStyle={{
                backgroundColor: 'transparent',
                borderWidth: 0,
                marginLeft: 0,
                paddingLeft: 0
            }}
            onPress={() => {
                if (user) {
                    dispatch(modifyProduct(productPointId, 0, !replace))
                } else {
                    dispatch(modifyLocalProduct(item, item.point_id, item.id, 1, !replace));
                };
                toggleReplace(!replace);
            }}
        />
    );
}
