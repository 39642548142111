import compaApi from '../apis/compa';

export const USERLIST = 'USERLIST';

export const USERLIST_LOADING = `[${USERLIST}] userlists`;

export const USERLIST_LOADED = `[${USERLIST}] loaded`;

export const USERLIST_SET_RESULT_USERLIST = `[${USERLIST}] set result userlists`;



export const setResultUserList = (result) => ({
    type: USERLIST_SET_RESULT_USERLIST,
    payload: result
});

export const setUserListLoading = (isLoading) => ({
    type: USERLIST_LOADING,
    payload: isLoading
});

export const setUserListLoaded = (loaded) => ({
    type: USERLIST_LOADED,
    payload: loaded
});

export const searchUsers = (search) => {
    return (dispatch) => {
        dispatch(setUserListLoading(true));
        compaApi.MiniGroups
            .getUserList(search)
            .then((result) => {
                dispatch(setResultUserList(result));
                dispatch(setUserListLoading(false));
                dispatch(setUserListLoaded(true));
            })
            .catch(() => {
                dispatch(setUserListLoading(false));
            });
    };
};
export const inviteUser = (userId) => {
    let data = {
        invitado: userId
    }
    return (dispatch) => {
        dispatch(setUserListLoading(true));
        compaApi.MiniGroups
            .inviteUser(data)
            .then((result) => {
                //algo?
            })
            .catch(() => {
                dispatch(setUserListLoading(false));
            });
    };
};



const initialState = {
    userlist: null,
    loaded: false,
    isLoading: false
};

export function miniGroupInviteReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USERLIST_SET_RESULT_USERLIST:
            return { ...state, userlist: payload };
        case USERLIST_LOADED:
            return { ...state, loaded: payload };
        case USERLIST_LOADING:
            return { ...state, isLoading: payload };
        default:
            return state;
    }
}
