import { StyleSheet } from 'react-native';

const variables = {
    // mainColor: '#5c9cd6',
    mainColor: '#129290',
    mainComplementaryColor: '#de9416',
    defaultInputBorderColor: '#BBB',
    loginBackgroundColor: '#e5f4f4',
    dangerColor: '#911107',
    warningColor: '#f0ad4e',
    successColor: '#139e30',
    gutter: 15,
    defaultBackground: 'white',
    textColor: '#444',
    smallMargin: 2,
    mediumMargin: 5,
    bigMargin: 10,
    xlMargin: 15,
    lowBorderRadius: 5,
    largeBorderRadius: 10,
    actionIconsSize: 28,
    detailIconSize: 18,
    headerBarHeight: 64,
    endMargin: 80,
    borderColor: '#d8d8d9',
    badgedNewColor: '#de2416',
    badgedOfferColor: '#de9416',
    badgedOrganicColor: '#009265',
    badgedAgroEcologicColor: '#009265'
};

export const themeVariables = variables;

export default StyleSheet.create({
    loginInputContainerStyle: {
        backgroundColor: variables.loginBackgroundColor,
        borderRadius: variables.largeBorderRadius,
        marginVertical: 8,
        width: '90%',
        flexDirection: 'row'
    },
    loginInputStyle: {
        fontSize: 16,
        paddingVertical: 12,
        width: '100%',
        color: variables.mainColor
    },
    loginIconInputStyle: {
        paddingVertical: 12,
        paddingHorizontal: 16
    },
    header1: {
        fontSize: 30,
        fontWeight: 'bold',
        color: variables.mainComplementaryColor,
        marginVertical: 16
    },
    header2: {
        fontSize: 18,
        fontWeight: 'bold',
        color: variables.mainComplementaryColor,
        marginBottom: 16,
        marginTop: 24
    },
    header4: {
        fontSize: 16,
        fontWeight: 'bold',
        color: variables.mainComplementaryColor,
        marginVertical: 16
    },
    header5: {
        fontSize: 15,
        fontWeight: 'bold',
        color: variables.textColor,
        marginVertical: 6
    },
    header6: {
        fontSize: 16,
        fontWeight: 'bold',
        color: variables.mainComplementaryColor,
        marginVertical: variables.smallMargin
    },
    header7: {
        fontSize: 18,
        fontWeight: 'bold',
        color: variables.textColor,
        marginVertical: variables.smallMargin
    },
    header8: {
        fontSize: 24,
        fontWeight: 'bold',
        color: variables.textColor,
        marginVertical: variables.smallMargin
    },
    smallText: {
        fontSize: 12,
        color: variables.textColor, 
    },
    normalText: {
        fontSize: 14,
        color: variables.textColor, 
    },
    mediumText: {
        fontSize: 16,
        color: variables.textColor, 
    },
    leadingText: {
        fontSize: 20,
        color: variables.textColor, 
    },
    leadingHighlightText: {
        fontSize: 20,
        color: 'red',
        fontWeight: 'bold',
        marginTop: 10
    },
    highlightText: {
        fontSize: 15,
        color: variables.mainColor,
        fontWeight: 'bold' 
    },
    screenContainer: {
        flex: 1,
        backgroundColor: variables.defaultBackground
    },

    buttonSuccessButtonStyle: {
        backgroundColor: themeVariables.mainColor,
        borderRadius: 24
    },
    buttonSuccessContainerStyle: {
        marginVertical: 8
    },
    buttonSuccessTitleStyle: {
        color: 'white',
        textTransform: 'uppercase',
        fontSize: 14
    },

    buttonAddRemoveButtonStyle: {
        backgroundColor: 'transparent',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: themeVariables.mainComplementaryColor,
        paddingVertical: 8
    },
    buttonAddRemoveContainerStyle: {
    },
    buttonAddRemovesTitleStyle: {
    },
    badgedText: {
        backgroundColor: '#333',
        color: 'white',
        borderRadius: 16,
        paddingVertical: 4,
        paddingHorizontal: 6,
        fontSize: 12,
        width: 'auto',
        marginRight: 5
    },
    verticalContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    horizontalContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    fullWidth: {
        alignSelf: 'stretch',
        flex: 1
    },
    topCard: {
        backgroundColor: '#FFFFFF',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
    },
    bigShadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 12,
        },
        shadowOpacity: 0.08,
        shadowRadius: 16.00,
        elevation: 24,
    },
    logo: {
        width: 120,
        height: 80,
        resizeMode: 'contain',
        marginTop: 40,
        marginBottom: 20
    },
    registerButton: {
        color: themeVariables.mainColor, 
        paddingVertical: 8,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    headerBottom: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.15,
        shadowRadius: 3.84,
        elevation: 5,
        borderBottomWidth: 1,
        borderBottomColor: themeVariables.borderColor
    },
    noHeaderBottom: {
        shadowColor: 'transparent',
        borderBottomWidth: 0
    },
    activityIndicator: {
        flex: 1,
        justifyContent: 'center'
    }
});