import * as React from 'react';

import { FlatList, Text, View, StyleSheet, Image } from 'react-native';;
import {
    Button
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import compaApi from '../../apis/compa';
import { approveClaimLine, getPointClaims, setPointStateClaim } from '../../redux/pointManagement';
import { useDispatch, useSelector } from 'react-redux';
import Toast from 'react-native-toast-message';

export default function ClaimLineListItem({ item, navigation, admin=false }) {
    const dispatch = useDispatch();
    
    const stateClaim = useSelector(state => state.pointManagement.stateClaim);
    const loadedClaims = useSelector(state => state.pointManagement.loadedClaims)

    React.useEffect(() => {
        if (stateClaim && loadedClaims) {
            Toast.show({
                type: 'info',
                text1: 'CompaApp - Reclamos',
                text2: stateClaim
            });
            setTimeout(() => {
                // limpiamos el result para futuros usos
                dispatch(setPointStateClaim(null));
            }, 500);
        }
    }, [stateClaim, loadedClaims]);
    
    
    return (
        <View style={{ flexDirection: 'row', marginBottom: 15, paddingBottom: 15, borderBottomWidth: 1, borderBottomColor: '#CCC', paddingTop: 4, justifyContent: 'space-between' }}>
            {/* <Image
                source={{
                    uri: compaApi.generateImageUri(item.linea_pedidos.producto_campaña.logo) ? compaApi.generateImageUri(item.linea_pedidos.producto_campaña.logo) : require('../../assets/logo.png')
                }}
                style={{
                    width: 75,
                    height: 75,
                    borderRadius: themeVariables.lowBorderRadius
                }}
            /> */}
            <View>
                <Text style={ [theme.header5, {marginTop: 0}] }>{ item.linea_pedidos.producto_campaña.nombre }</Text>
                <Text style={theme.leadingText}>Cantidad: {item.linea_pedidos.cantidad_pedido}</Text>
                <Text style={ [theme.normalText, { color: 'red' }] }>Estado: {item.estado}</Text>
            </View>

            { admin && item.estado != 'aprobado' && <Button 
              title='Aprobar item'
              containerStyle={[theme.buttonSuccessContainerStyle]}
              buttonStyle={[theme.buttonSuccessButtonStyle]}
              titleStyle={[theme.buttonSuccessTitleStyle]}
              onPress={() => {
                  dispatch(approveClaimLine(item.id, "aprobado"));
                  dispatch(getPointClaims());
              }}
            />}
        </View>
    );
}
