// Create an instance.
export default class GroupsApi {
    constructor(baseUrl, network) {
      this.baseUrl = baseUrl;
      this.token = null;
      this.network = network;
      this.abortController = null;
    }
    setAbortController (controller) {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = controller;
    }
    cleanAbortController () {
      this.abortController = null;
    }
    close (data) {
        return new Promise((resolve, reject) => {
          let fetch = this.network.get(this.baseUrl + '/grupos/cercanos', data, {withAbortController: true})
          this.setAbortController(fetch.abortController);          
          fetch.promise.then((answer) => {
            resolve(answer);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.cleanAbortController();  
          });
        });
    }
    search (search) {
        return new Promise((resolve, reject) => {
          search = search ? '/' + search : search;
          let fetch = this.network.get(this.baseUrl + '/grupos/buscar' + search, null, {withAbortController: true});
          this.setAbortController(fetch.abortController);          
          fetch.promise.then((answer) => {
            resolve(answer);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.cleanAbortController();  
          });
        });
    }
}