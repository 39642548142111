import { Platform } from 'react-native';

const createFormData = (body) => {
    let hasFile = false;
    for (const key in body) {
        if (body.hasOwnProperty(key)) {
            const element = body[key];
            hasFile =
                element &&
                element.uri !== undefined &&
                element.type !== undefined;
            if (hasFile) {
                break;
            }
        }
    }
    if (hasFile) {
        let data = new FormData();
        Object.keys(body).forEach((key) => {
            if (body[key].uri !== undefined && body[key].type !== undefined) {
                let ext = body[key].uri.substring(
                    body[key].uri.lastIndexOf('.') + 1
                );
                data.append(key, {
                    name: new Date().getTime().toString() + `.${ext}`,
                    type:
                        Platform.OS === 'android'
                            ? body[key].type + `/${ext}`
                            : body[key].type,
                    uri:
                        Platform.OS === 'android'
                            ? body[key].uri
                            : body[key].uri.replace('file://', '')
                });
            } else {
                data.append(key, body[key]);
            }
        });
        return data;
    } else {
        return JSON.stringify(body);
    }
};
let timeoutFn = function (time, promise) {
    return new Promise(function (resolve, reject) {
        setTimeout(() => {
            reject(new Error('Request timed out.'));
        }, time);
        promise.then(resolve, reject).catch(reject);
    });
};

let functionNetworkGenerator = (method) => {
    return (url, data, config) => {
        const controller = new AbortController();
        const signal = controller.signal;
        let configFetch = {
            method: method,
            signal: signal,
            headers: {
                // Accept: 'application/json',
                'content-type': 'application/json;charset=UTF-8'
            }
        };
        if (config && config.headers) {
            configFetch.headers = Object.assign(
                configFetch.headers,
                config.headers
            );
        }
        if (data) {
            configFetch.body = createFormData(data);
            if (configFetch.body instanceof FormData == true) {
                configFetch.headers['Content-Type'] = 'multipart/form-data';
                if (method === 'PUT') {
                    // PUT method is not implemented for multipart/form-data. LARAVEL admit POST with _method = 'PUT' in body
                    configFetch.method = 'POST';
                    configFetch.body.append('_method', 'PUT');
                }
            }
        }

        let timeout = 10 * 1000;
        let withAbortController = false;
        if (config) {
            if (config.timeout) {
                timeout = config.timeout;
            }
            if (config.withAbortController) {
                withAbortController = config.withAbortController;
            }
        }
        let returnPromise = new Promise((resolve, reject) => {
            timeoutFn(timeout, fetch(url, configFetch))
                .then((response) => {
                    return response.json();
                })
                .then((json) => {
                    if (json.error !== undefined) {
                        reject(json);
                    } else {
                        resolve(json);
                    }
                })
                .catch((error) => {
                    console.log('network error', error);
                    console.log(url, JSON.stringify(configFetch));
                    reject(error);
                    // This can be because of request timed out
                    // so we abort the request for any case
                    controller.abort();
                });
        });
        if (withAbortController) {
            return {
                promise: returnPromise,
                abortController: controller
            }
        } else {
            return returnPromise;
        }
    };
};

let network = {
    post: new functionNetworkGenerator('POST'),
    get: new functionNetworkGenerator('GET'),
    put: new functionNetworkGenerator('PUT'),
    delete: new functionNetworkGenerator('DELETE')
};

export default network;