import * as React from 'react';

import {
    View,
    Text,
    Image,
    ImageBackground,
    Share,
    FlatList
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Icon,
    Button,
    Input
} from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClaims } from '../../redux/claims';
import theme, { themeVariables } from '../../theme/styles';
import ClaimListItem from '../../components/Claims/ClaimListItem';
import { getPastOrders } from '../../redux/orders';
import AddLineToClaimListItem from '../../components/Claims/AddLineToCLaimListItem';

export default function AddToClaimScreen({ navigation, route }) {
    const dispatch = useDispatch();
    const orderId = route.params && route.params.orderId ? route.params.orderId : (order ? order : {});
    const orders = useSelector(state => state.orders.pastOrders);
    const order = Object.values(orders).filter(item => item.id == orderId)[0];

    console.log("ad", orderId);
    console.log("or", order);

    React.useEffect(() => {
        dispatch(getPastOrders());
    }, []);

    return (
        <View style={{ paddingHorizontal: themeVariables.gutter }}>
            <FlatList
                data={order ? order.linea_pedidos : []}
                renderItem={({ item, index, separators }) => (
                    <AddLineToClaimListItem
                        navigation={navigation}
                        item={item}>
                    </AddLineToClaimListItem>
                )}
                keyExtractor={item => item.id.toString()}
            />
            
        </View>);
}