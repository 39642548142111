import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, View, Image, TouchableWithoutFeedback } from 'react-native';
import compaApi from '../../apis/compa';
import theme, { themeVariables } from '../../theme/styles';
import {
    Input
} from 'react-native-elements';


export default function DeliveryProductListItem ({ item, navigation, order, pointId, userMode = false, onInputChange }) {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);
    const group = useSelector(state => state.miniGroups.minigroup);
    const isAdmin = user ? user.superAdmin : false; // [TODO: check if the admin is the owner.]

     React.useEffect(() => {
        // dispatch(getMinigroup());
    }, []);


    return (
        <TouchableWithoutFeedback
            onPress={() => {
                if (userMode) {
                    navigation.push('Product', { productId: item.id, pointId: pointId });
                }
            }}
        >
            <View style={{ flexDirection: 'row', marginBottom: 15, paddingBottom: 15, borderBottomWidth: 1, borderBottomColor: '#CCC', paddingTop: 4 }}>
                <Image
                    source={{
                        uri: compaApi.generateImageUri(item.logo) ? compaApi.generateImageUri(item.logo) : require('../../assets/logo.png')
                    }}
                    style={{
                        width: 75,
                        height: 75,
                        borderRadius: themeVariables.lowBorderRadius
                    }}
                />
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', flex: 1, flexWrap: 'wrap', paddingLeft: 4  }}>
                    <View style={{ flex: 1 }}>
                        <Text style={[theme.normalText, { fontSize: 16, fontWeight: 'bold', textTransform: 'capitalize', marginTop: 5, flex: 1, flexWrap: 'wrap' }]}>
                            { item.producto_campaña.nombre }
                        </Text>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[theme.normalText, { flex: 1, alignItems: 'center' }]}>
                                Bultos pedidos: 
                            </Text>
                            <Input 
                                defaultValue={item.cantidad_pedido}
                                keyboardType='number-pad'
                                onChangeText={(text) => {
                                    if (onInputChange && !isNaN(parseInt(text, 10))) {
                                        onInputChange(item.producto_campaña.id, parseInt(text, 10));
                                    }
                                }}
                                containerStyle={{ flex: 1 }}
                            />
                        </View>
                        <Text style={theme.normalText}>
                            Peso: { item.cantidad_pedido * item.producto_campaña.peso_bulto} kg aprox.
                        </Text>
                       
                        <Text style={theme.leadingHighlightText}>$ {item.precio}</Text>
                    </View>
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
}
