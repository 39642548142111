
import compaApi from '../apis/compa';

import { authSetUserNotificationsReaded } from './auth'

export const NOTIFICATIONS = 'NOTIFICATIONS';

export const NOTIFICATIONS_LOADING = `[${NOTIFICATIONS}] NOTIFICATIONSs`;

export const NOTIFICATIONS_LOADED = `[${NOTIFICATIONS}] loaded`;

export const NOTIFICATIONS_SET_RESULT_NOTIFICATIONS = `[${NOTIFICATIONS}] set result NOTIFICATIONSs`;




export const setResultNotifications = (result) => ({
    type: NOTIFICATIONS_SET_RESULT_NOTIFICATIONS,
    payload: result
});

export const setNotificationsLoading = (isLoading) => ({
    type: NOTIFICATIONS_LOADING,
    payload: isLoading
});

export const setNotificationsLoaded = (loaded) => ({
    type: NOTIFICATIONS_LOADED,
    payload: loaded
});

export const getNotifications = () => {
    return (dispatch) => {
        dispatch(setNotificationsLoading(true));
        compaApi.notifications
            .get()
            .then((result) => {
                if (result) {
                    dispatch(setResultNotifications(result.data));
                    dispatch(setNotificationsLoading(false));
                    dispatch(setNotificationsLoaded(true));
                }
            })
            .catch(() => {
                dispatch(setNotificationsLoading(false));
            });
    };
};


export const markRead = () => {
    return (dispatch) => {
        compaApi.notifications
            .markRead()
            .then((result) => {
                dispatch(authSetUserNotificationsReaded());
            })
            .catch(() => {
            });
    };
};


const initialState = {
    notifications: null,
    loaded: false,
    isLoading: false
};

export function NotificationsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case NOTIFICATIONS_SET_RESULT_NOTIFICATIONS:
            return { ...state, notifications: payload };
        case NOTIFICATIONS_LOADED:
            return { ...state, loaded: payload };
        case NOTIFICATIONS_LOADING:
            return { ...state, isLoading: payload };
        default:
            return state;
    }
}
