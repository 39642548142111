import * as React from 'react';
import { View } from 'react-native';

import {
    Text,
    TextInput
} from 'react-native';
import {
    Button,
    Icon,
    Divider
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setCartPickupInfo } from '../../redux/cart';
import { displayOrderStatusName, totalizadorPeso, totalizadorPrecio, totalizadorBultos } from '../../utils/helpers';

export default function ConfirmAndPayScreen({ navigation, route }) {


    const order = useSelector(state => state.cart.order);
    const point = useSelector(state => state.cart.point);
    const group = useSelector(state => state.cart.group);
    const orders = useSelector(state => state.orders.orders);
    
    const dispatch = useDispatch();

    const [pickupInfo, setPickUpInfo] = React.useState('');
    const [changePickupInfo, setChangePickupInfo] = React.useState(false);


    return (
        <View>
            <View style={{ padding: 15 }}>
                <Text style={theme.header4}>Resumen del pedido</Text>
                <Text>
                    <Text>Cantidad de productos: </Text>
                    <Text style={{ fontWeight: 'bold' }}>{order ? order.linea_pedidos.filter(lp => lp.cantidad_pedido > 0).length : 0}</Text>
                </Text>
                <Text>
                    <Text>Peso total aprox.: </Text>
                    <Text style={{ fontWeight: 'bold' }}>{order ? order.linea_pedidos.reduce(totalizadorPeso, 0) : 0} kg</Text>
                </Text>
                <Text>
                    <Text>Tamaño aprox. del pedido: </Text>
                    <Text style={{ fontWeight: 'bold' }}>{order ? order.linea_pedidos.reduce(totalizadorBultos, 0) : 0} bultos</Text>
                </Text>
                <Text>
                    <Text>Point de retiro: </Text>
                    <Text style={{ fontWeight: 'bold' }}>{point.nombre}</Text>
                </Text>
                <Text style={{ fontSize: 20, marginTop: 10 }}>
                    <Text>Total pedido: </Text>
                    <Text style={{ fontWeight: 'bold', color: 'red' }}>$ {order ? order.linea_pedidos.reduce(totalizadorPrecio, 0) : 0}</Text>
                </Text>
            </View>


            <View style={{ paddingHorizontal: 15 }}>
                <Text style={theme.header4}>¿Quién retira el pedido?</Text>
                <Text>Retira: { orders[point.id].datos_retiro ? orders[point.id].datos_retiro : 'Usuario actual' }</Text>

                {
                    !changePickupInfo &&
                        <Button
                            title='Cambiar'
                            containerStyle={[theme.buttonSuccessContainerStyle]}
                            buttonStyle={[theme.buttonSuccessButtonStyle, { backgroundColor: 'rgb(32, 137, 220)' }]}
                            titleStyle={[theme.buttonSuccessTitleStyle]}
                            onPress={() => {
                                setChangePickupInfo(true);
                            }}
                        />
                }
                {
                    changePickupInfo &&
                        <>
                            <TextInput
                                style={[theme.loginInputStyle, { paddingLeft: 5, borderColor: '#CCC', borderWidth: 1, marginTop: 10 }]}
                                placeholderTextColor={themeVariables.mainColor}
                                placeholder="Ingrese nombre y DNI de quién va a retirar"
                                onChangeText={text => setPickUpInfo(text)}
                
                            />
                            <Button
                                title='Actualizar'
                                containerStyle={[theme.buttonSuccessContainerStyle]}
                                buttonStyle={[theme.buttonSuccessButtonStyle, { backgroundColor: 'rgb(32, 137, 220)' }]}
                                titleStyle={[theme.buttonSuccessTitleStyle]}
                                onPress={() => {
                                    dispatch(setCartPickupInfo(orders[point.id].id, pickupInfo));
                                }}
                            />
                        </>
                }
                <Divider style={{ marginVertical: 10 }} />
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: themeVariables.gutter }}>
                <Button
                    title='Seguir comprando'
                    type="clear"
                    containerStyle={[theme.buttonSuccessContainerStyle, { width: '45%', paddingLeft: 10 }]}
                    buttonStyle={[theme.buttonSuccessButtonStyle, { height: 44 }]}
                    titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                    icon={
                        <Icon
                            name='arrow-back'
                            color='white'
                            size={14}
                            style={{ paddingRight: 4 }}
                        />
                    }
                    onPress={() => {
                        navigation.pop();
                    }}
                />
                <Button
                    title='Metodo de pago'
                    containerStyle={[theme.buttonSuccessContainerStyle, { width: '45%', paddingLeft: 10 }]}
                    buttonStyle={[theme.buttonSuccessButtonStyle, { backgroundColor: themeVariables.mainComplementaryColor, height: 44 }]}
                    titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                    icon={
                        <Icon
                            name='arrow-forward'
                            color='white'
                            size={14}
                            style={{ paddingRight: 4 }}
                        />
                    }
                    onPress={() => {
                        navigation.push('FormOfPaymentScreen', { point: point })                    
                    }}
                />

            </View>
        </View>

    );
}