import {
    NavigationContainer,
    DefaultTheme,
    DarkTheme
} from '@react-navigation/native';
import * as React from 'react';
import MainStackNavigator from './MainStackNavigator';
import Toast from 'react-native-toast-message';

import { AuthProvider } from '../contexts/AuthContext';

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation(colorScheme) {
    return (
        <NavigationContainer
            //   linking={LinkingConfiguration}
            linking={{ 
                enabled: true,
                config: {
                    screens: {
                        Home: '*'
                    },
                }
            }}
            theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
        >
            <AuthProvider>
                <MainStackNavigator />
            </AuthProvider>
            <Toast ref={(ref) => Toast.setRef(ref)} />
        </NavigationContainer>
    );
}