// Create an instance.
export default class ProductsApi {
    constructor(baseUrl, network) {
      this.baseUrl = baseUrl;
      this.token = null;
      this.network = network;
    }
    getPointProducts(id) {
        return new Promise((resolve, reject) => {
          this.network.get(this.baseUrl + '/points/productos/' + id, null, null).then((json) => {
            resolve(json);
          })
          .catch((error) => {
            reject(error);
          });
        });
    }
}