import * as React from 'react';

import {
    View,
    Text,
    FlatList,
    ScrollView,
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Input,
    Button,
    Divider
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import { useDispatch, useSelector } from 'react-redux';
import CampaignProductListItem from '../../components/GroupManagement/CampaignProductListItem'
import { getGroup, getGroupCampaigns, getGroupProducts, replaceProduct } from '../../redux/groupManagement';

export default function ReplaceProductScreen({ navigation, route, changeTab }) {


    const dispatch = useDispatch();

    const product_id = route.params && route.params.productId ? route.params.productId : {};

    const group = useSelector(state => state.groupManagement.group);

    const productsLoaded = useSelector(state => state.groupManagement.loadedCampaigns)
    const campaigns = useSelector(state => state.groupManagement.campaigns);

    const selectedCampaign = useSelector(state => state.groupManagement.selectedCampaign);
    const products = selectedCampaign ? selectedCampaign.productos_campaña : null
    const product = products.filter(p => p.id == product_id)[0];


    const [selected, setSelected] = React.useState(null);
    const [quantity, setQuantity] = React.useState(0);
    React.useEffect(() => {
        dispatch(getGroup());
        dispatch(getGroupCampaigns());
    }, []);

    return (
        <View style={{ paddingHorizontal: themeVariables.gutter, flex: 1 }}>
            <Text style={theme.header4}>Reemplazo de producto por otro</Text>
            <ScrollView>
                { product && <Text>Producto a reemplazar</Text>}
                
                { product && <CampaignProductListItem
                    navigation={navigation}
                    item={product}
                /> }
                { selected && <Text>Seleccionado para el reemplazo</Text>}
                
                { selected && <CampaignProductListItem
                    navigation={navigation}
                    item={selected}
                /> }
                {/* TODO: laggy? */}
                <Input
                    label='Cantidad a reemplazar'
                    style={theme.TextInput}
                    keyboardType='numeric'
                    onChangeText={(text) => setQuantity(text)}
                    value={quantity}
                    maxLength={10}  //setting limit of input
                />
                <Button
                    title='Realizar reemplazo'
                    containerStyle={[theme.buttonSuccessContainerStyle]}
                    buttonStyle={[theme.buttonSuccessButtonStyle]}
                    titleStyle={[theme.buttonSuccessTitleStyle]}
                    onPress={() => {
                        dispatch(replaceProduct(product.id, selected.id, quantity))
                    }}
                />
                <Divider />
                <Text style={theme.header5}>Seleccione un producto para realizar el reemplazo</Text>
                {productsLoaded && products && <FlatList
                    data={products}
                    renderItem={({ item, index, separators }) => {
                        return (
                            <View>
                                <CampaignProductListItem
                                    navigation={navigation}
                                    item={item}
                                />
                                <Button
                                    title='Seleccionar'
                                    containerStyle={[theme.buttonSuccessContainerStyle]}
                                    buttonStyle={[theme.buttonSuccessButtonStyle]}
                                    titleStyle={[theme.buttonSuccessTitleStyle]}
                                    onPress={() => {
                                        setSelected(item);
                                    }}
                                />
                            </View>

                        )
                    }}
                    keyExtractor={item => item.id.toString()}
                />}
            </ScrollView>
        </View>
    );
}

