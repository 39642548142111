import * as React from 'react';

import {
    View,
    Text,
    Image,
    ImageBackground,
    Share,
    FlatList
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Icon,
    Button,
    Input
} from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClaims } from '../redux/claims';
import { getPastOrders } from '../redux/orders';
import theme, { themeVariables } from '../theme/styles';
import PastOrderListItem from '../components/PastOrderListItem';

export default function PastOrdersScreen({ navigation, route }) {
    const dispatch = useDispatch();
    const pastOrders = useSelector(state => state.orders.pastOrders);


    React.useEffect(() => {
        dispatch(getPastOrders());
    }, []);

    return (
        <View style={[theme.screenContainer, { paddingHorizontal: themeVariables.gutter }]}>
            <Text style={theme.header4}>Pedidos completados</Text>
            <FlatList
                style={{ paddingHorizontal: themeVariables.gutter, paddingBottom: themeVariables.endMargin }}
                data={Object.values(pastOrders)}
                renderItem={({ item, index, separators }) => (
                    <PastOrderListItem
                        item={item}
                        navigation={navigation}
                    />
                )}
                keyExtractor={item => item.id.toString()}
            />
        </View>);
}