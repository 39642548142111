import * as React from 'react';

import {
    View,
    Text,
    TouchableWithoutFeedback
} from 'react-native';
import {
    Button,
    Icon
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';

import { displayPaymentTypeName, displayPaymentStatusName } from '../../utils/helpers';

import dayjs from 'dayjs';

export default function PointPaymentListItem ({ item, navigation }) {
    return (
        <View style={{ marginBottom: 10 }}>
            <TouchableWithoutFeedback
              onPress={() => {
                navigation.push('PointPaymentScreen', { payment: item.id });
              }}
            >
                <View style={{ backgroundColor: 'white', padding: 5, borderWidth: 1, borderColor: '#CCC' }}>
                    <Text style={theme.header5}>Nombre usuario: {item.pedidos.users.name}</Text>
                    <Text style={theme.header5}>Monto: $ { item.monto }</Text>
                    <Text style={theme.header5}>Fecha: { dayjs(item.fecha).format('DD/MM/YYYY HH:mm') }</Text>
                    <Text style={theme.header5}>Método: { displayPaymentTypeName(item.metodo) }</Text>
                    <Text 
                        style={[theme.header5, { color: item.estado === 'pendiente' ? 'red' : '#444444' }]}
                    >
                        Estado: { displayPaymentStatusName(item.estado) }
                    </Text>
                    <Icon 
                        name='chevron-right'
                        color='#CCC'
                        size={24}
                        containerStyle={{ position: 'absolute', right: 5, top: 20 }}
                    />
                </View>
            </TouchableWithoutFeedback>
                
        </View>
    );
}
  