import * as React from 'react';
import { setCartGroup, setCartPoint, setCartOrder } from '../redux/cart'
import {
  View,
  ScrollView,
  Text,
} from 'react-native';
import {
  Icon,
  Button
} from 'react-native-elements';
import theme, { themeVariables } from '../theme/styles';
import { useSelector, useDispatch } from 'react-redux';
import ProductListItem from '../components/ProductListItem';

import { orderHasPendingPayments, displayOrderStatusName } from '../utils/helpers';


import CartResume from '../components/CartResume';

export default function CartScreen({ navigation, route }) {
  const pointParam = route.params && route.params.point ? route.params.point : {};
  const orders = useSelector(state => state.orders.orders);
  const localOrders = useSelector(state => state.localOrders.orders);
  const groups = useSelector(state => state.groups.groups);
  const user = useSelector(state => state.auth.user);
  
  let group = null;
  let point = pointParam;
  if (!point.id && orders && Object.keys(orders).length && groups && groups.length) {
    // FIXME necesito el objeto point del grupoo actual
    for (let index = 0; index < groups.length; index++) {
      group = groups[index];
      point = group.points.find(p => p.id == Object.keys(orders)[0]);
      if (point.id) {
        break;
      }
    }
    // point = { id: Object.keys(orders)[0] };
  }


  const order = user ? orders[point.id] : localOrders[point.id];
  
  const dispatch = useDispatch();

  const [pickupInfo, setPickUpInfo] = React.useState('');
  const [changePickupInfo, setChangePickupInfo] = React.useState(false);
  const [internalOrder, setInternalOrder] = React.useState(order);



  React.useEffect(() => {
    // dispatch(getAllOrders());
    dispatch(setCartGroup(group));
    dispatch(setCartPoint(point));
    dispatch(setCartOrder(orders[point.id]));
    console.log('orders initial', orders, localOrders);
    const order = user ? orders[point.id] : localOrders[point.id];
    setInternalOrder(order);
  }, []);

  React.useEffect(() => {
    console.log('orders changing', orders, localOrders);
    const order = user ? orders[point.id] : localOrders[point.id];
    setInternalOrder(order);
  }, [orders, localOrders]);

  return (
    <View style={theme.screenContainer}>
      {
        point.id > 0 && !!internalOrder &&
        <ScrollView>
          <View style={{ paddingHorizontal: 15, marginTop: 8 }}>
            <Text style={theme.header5}>Pedido # { internalOrder.id } - { point.nombre }</Text>
            <Text>Entre paréntesis valores solicitados a la espera de stock.</Text>
          </View>
          <View style={{
              padding: themeVariables.gutter, 
          }}>
          {
            internalOrder && Array.isArray(internalOrder.linea_pedidos) &&
            internalOrder.linea_pedidos.filter(lp => lp.cantidad_pedido > 0).map((item, index) => (

              <ProductListItem
                key={index}
                navigation={navigation}
                item={item}
                order={internalOrder}
                pointId={point.id}
                userMode={true}
                isCart={true}
              />
            ))
          }
          </View>
          {/* <FlatList
            style={{ 
              paddingHorizontal: themeVariables.gutter, 
              paddingTop: 26, 
              paddingBottom: themeVariables.endMargin 
            }}
            data={ orders[point.id] && Array.isArray(orders[point.id].linea_pedidos) ? 
                    orders[point.id].linea_pedidos.filter(lp => lp.cantidad_pedido > 0)
            : []}
            extraData={orders[point.id] ? orders[point.id] : localOrders[point.id]}
            renderItem={({ item, index, separators }) => {
              return (
                <ProductListItem
                  navigation={navigation}
                  item={item}
                  order={orders[point.id]}
                  pointId={point.id}
                  userMode={true}
                  isCart={true}
                />
              )
            }}
            keyExtractor={item => item.id.toString()}
          /> */}

          <CartResume navigation={navigation} pointParam={point}>
            
              <Button
                title='Seguir comprando'
                containerStyle={[theme.buttonSuccessContainerStyle, { width: '55%', paddingRight: 10 }]}
                buttonStyle={[theme.buttonSuccessButtonStyle, { height: 44 }]}
                titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 12, fontWeight: 'bold', alignItems: 'center', display: 'flex' }]}
                icon={
                  <Icon
                    name='arrow-back'
                    color='white'
                    size={14}
                    style={{ paddingRight: 4 }}
                  />
                }
                onPress={() => {
                  navigation.push('PointProducts', { point: point });
                }}
              />
              {
                !orderHasPendingPayments(internalOrder) &&
                  <Button
                    title='Pagar'
                    containerStyle={[theme.buttonSuccessContainerStyle, { width: '45%', paddingLeft: 10 }]}
                    buttonStyle={[theme.buttonSuccessButtonStyle, { backgroundColor: themeVariables.mainComplementaryColor, height: 44 }]}
                    titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                    icon={
                      <Icon
                        name='arrow-forward'
                        color='white'
                        size={14}
                        style={{ paddingRight: 4 }}
                      />
                    }
                    onPress={() => {
                      navigation.push('ConfirmAndPayScreen', { point: point })
                    }}
                  />
              }
              {
                orderHasPendingPayments(internalOrder) &&
                <View style={{ flexWrap: 'wrap', flex: 1 }}>
                  <Text style={{ color: 'red', flexWrap: 'wrap'  }}>Tienes un pago pendiente de aprobación. El point lo va a revisar proximamente.</Text>
                </View>
              }
          </CartResume>

        </ScrollView>
      }
      {
        !(Object.keys(orders).length > 0) &&
          <>
            <View style={theme.activityIndicator}>
              <Text style={{ textAlign: 'center' }}>Tu pedido se encuentra vacío.</Text>
            </View>
          </>
      }
    </View>
  )
}