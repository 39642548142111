import * as React from 'react';

import {
    View,
    Text,
    Image,
    ImageBackground,
    Share
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Icon,
    Button
} from 'react-native-elements';
import theme, { themeVariables } from '../theme/styles';
import IconTextItem from '../components/IconTextItem';
import HorarioItem from '../components/HorarioItem';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFavorite } from '../redux/favorites';

export default function PointScreen ({ navigation, route, changeTab }) {

    const point = route.params && route.params.point ? route.params.point : {};
    if (point && typeof point.horarios === 'string') {
        point.horarios = JSON.parse(point.horarios);
    }

    const dispatch = useDispatch();
    const favorites = useSelector(state => state.favorites.favorites);
    const isFavorited = Array.isArray(favorites) && favorites.filter(item => item.id == point.id).length > 0;

    const onShare = async (point) => {
        // FIXME
        try {
            let message = 'CompaApp - Te comparto este point de compra: ' + point.nombre;
            const shareData = {
                title: 'CompaApp',
                text: message,
                // url: 'https://developer.mozilla.org',
            };
            await window.navigator.share(shareData);
        } catch (error) {
            console.log('share error', error);
        }
    }

    return (
    <View style={{ paddingHorizontal: themeVariables.gutter }}>
        <Text style={theme.header1}>{ point.nombre }</Text>
        <Text style={theme.leadingText}>{ point.ubicacion }</Text>
        {/* TODO: revisar estos datos */}
        {/* <View style={{ flexDirection: 'row' }}>
            {
                point.pago_online && 
                <View style={{ flexDirection: 'row' }}>
                    <Icon
                    name={'attach-money'}
                    size={24} 
                    />
                    <Text>Pago Online</Text>
                </View>
            }
            {
                point.envios && 
                <View style={{ flexDirection: 'row' }}>
                    <Icon
                    name={'truck'}
                    size={24}
                    type='font-awesome'
                    />
                    <Text>Envios hasta punto de retiro</Text>
                </View>
            }
        </View> */}
        <View style={{ flexDirection: 'row' }}>
            <Button 
                type='clear'
                icon={
                    <Icon
                        name='share'
                        size={themeVariables.actionIconsSize}
                    />
                }
                onPress={() => {
                    onShare(point);
                }}
                containerStyle={{
                    marginRight: 24
                }}
            />
            <Button 
                type='clear'
                icon={
                    <Icon
                        name= {isFavorited ? 'heart': 'heart-o'}
                        type='font-awesome'
                        size={themeVariables.actionIconsSize}
                        color={themeVariables.textColor}
                    />
                }
                onPress={() => {
                    dispatch(toggleFavorite(point));
                }}
                containerStyle={{
                    marginRight: 24
                }}
            />
            {
                point.telefono &&
                    <Button 
                        type='clear'
                        icon={
                            <Icon
                                name='whatsapp'
                                type='font-awesome'
                                size={themeVariables.actionIconsSize}
                            />
                        }
                        onPress={() => {
                            let tel = point.telefono;
                            if (point.telefono.indexOf('+549') !== 0) {
                                tel = '+549' + tel;
                            }
                            Linking.openURL('https://wa.me/' + tel);
                        }}
                        containerStyle={{
                            marginRight: 24
                        }}
                    />
            }
        </View>
        {
            (point.datos_responsable || point.telefono || point.ubicacion) &&
                <View>
                    <Text style={theme.header4}>Datos de contacto</Text>
                    { point.datos_responsable && <IconTextItem iconName='person' text={point.datos_responsable} /> }
                    { point.telefono && <IconTextItem iconName='phone' text={point.telefono} /> }
                    { point.ubicacion && <IconTextItem iconName='map-marker' iconType='font-awesome' text={point.ubicacion} /> }
                </View>
        }
        { point.horarios && <Text style={theme.header4}>Horarios</Text> }
        { point.horarios && <View>
            <Text style={theme.header4}>Horarios</Text>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                    <HorarioItem label={'Lun'} time={point.horarios.lun} />
                    <HorarioItem label={'Mar'} time={point.horarios.mar} />
                    <HorarioItem label={'Mie'} time={point.horarios.mie} />
                    <HorarioItem label={'Jue'} time={point.horarios.jue} />
                    <HorarioItem label={'Vie'} time={point.horarios.vie} />
                </View>
                <View style={{ flex: 1 }}>
                    <HorarioItem label={'Sab'} time={point.horarios.sab} />
                    <HorarioItem label={'Dom'} time={point.horarios.dom} />
                </View>
            </View>
        </View>}
        
        {/* TODO: mapa */}

        <Button 
            title='Ver productos'
            onPress={changeTab}
            containerStyle={[theme.buttonSuccessContainerStyle]}
            buttonStyle={[theme.buttonSuccessButtonStyle]}
            titleStyle={[theme.buttonSuccessTitleStyle]}
        />
    </View>);
}