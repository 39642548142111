import * as React from 'react';
import { View } from 'react-native';

import {
    Text
} from 'react-native';
import {
    Button,
    Icon,
    CheckBox
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setPaymentType } from '../../redux/cart';


export default function FormOfPaymentScreen({ navigation, route }) {


    const order = useSelector(state => state.cart.order);
    const point = useSelector(state => state.cart.point);
    const group = useSelector(state => state.cart.group);
    const paymentType = useSelector(state => state.cart.paymentType);

    const dispatch = useDispatch();

    const totalizadorPrecio = (accumulator, currentValue) => accumulator + currentValue.precio;

    
    return (
        <View>
            <View style={{ paddingHorizontal: 15 }}>
                <Text style={theme.header4}>Seleccione la forma de pago</Text>
                {/* <CheckBox
                    title="Tarjeta de credito"
                    checked={paymentType == "credit"}
                    onPress={() => {
                        dispatch(setPaymentType("credit"));
                    }}
                /> */}
                <CheckBox
                    title="Transferencia bancaria"
                    checked={paymentType == "bank"}
                    containerStyle={{ backgroundColor: 'transparent', borderWidth: 0 }}
                    onPress={() => {
                        dispatch(setPaymentType("bank"));
                    }}
                />
                <CheckBox
                    title="Efectivo"
                    checked={paymentType == "cash"}
                    containerStyle={{ backgroundColor: 'transparent', borderWidth: 0 }}
                    onPress={() => {
                        dispatch(setPaymentType("cash"));
                    }}
                />
                <CheckBox
                    title="Mercado Pago"
                    checked={paymentType == "mercadoPago"}
                    containerStyle={{ backgroundColor: 'transparent', borderWidth: 0 }}
                    onPress={() => {
                        dispatch(setPaymentType("mercadoPago"));
                    }}
                />
            </View>
            <View style={{ paddingHorizontal: 15 }}>
                <Text style={{ fontSize: 20, marginTop: 10 }}>
                    <Text>Total pedido: </Text>
                    <Text style={{ fontWeight: 'bold', color: 'red' }}>$ {order ? order.linea_pedidos.reduce(totalizadorPrecio, 0) : 0}</Text>
                </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <Button
                    title='Seguir comprando'
                    type="clear"
                    containerStyle={[theme.buttonSuccessContainerStyle, { width: '46%' }]}
                    buttonStyle={[theme.buttonSuccessButtonStyle, { height: 44 }]}
                    titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                    icon={
                        <Icon
                            name='arrow-back'
                            color='white'
                            size={14}
                            style={{ paddingRight: 4 }}
                        />
                    }
                    onPress={() => {
                        navigation.pop();
                    }}
                />
                <Button
                    title='Continuar'
                    containerStyle={[theme.buttonSuccessContainerStyle, { width: '46%', paddingLeft: 10 }]}
                    buttonStyle={[theme.buttonSuccessButtonStyle, { backgroundColor: themeVariables.mainComplementaryColor, height: 44 }]}
                    titleStyle={[theme.buttonSuccessTitleStyle, { fontSize: 14, fontWeight: 'bold' }]}
                    disabled={!paymentType}
                    icon={
                        <Icon
                            name='arrow-forward'
                            color='white'
                            size={14}
                            style={{ paddingRight: 4 }}
                        />
                    }
                    onPress={() => {
                        navigation.push('ReceiptOfPaymentScreen');
                    }}
                />
            </View>
        </View>
    );
}