
import compaApi from '../apis/compa';

export const PRODUCTS = 'PRODUCTS';

export const PRODUCTS_LOADING = `[${PRODUCTS}] products`;

export const PRODUCTS_LOADED = `[${PRODUCTS}] loaded`;

export const PRODUCTS_SET_RESULT_PRODUCTS = `[${PRODUCTS}] set result Products`;



export const setResultProducts = (result) => ({
    type: PRODUCTS_SET_RESULT_PRODUCTS,
    payload: result
});

export const setProductsLoading = (isLoading) => ({
    type: PRODUCTS_LOADING,
    payload: isLoading
});

export const setProductsLoaded = (loaded) => ({
    type: PRODUCTS_LOADED,
    payload: loaded
});

export const getPointProducts = (id) => {
    return (dispatch) => {
        dispatch(setProductsLoading(true));
        compaApi.products
            .getPointProducts(id)
            .then((response) => {
                let result = {id: id, data: response.data ? response.data : []};
                dispatch(
                    setResultProducts(result)
                );
                dispatch(setProductsLoading(false));
                dispatch(setProductsLoaded(true));
            })
            .catch(() => {
                dispatch(setProductsLoading(false));
            });
    };
};


const initialState = {
    products: [],
    loaded: false,
    isLoading: false
};

export function ProductsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PRODUCTS_SET_RESULT_PRODUCTS:
            let productsList = Object.assign({}, state.products);
            productsList[payload.id] = payload.data;
            return { ...state, products: productsList };
        case PRODUCTS_LOADED:
            return { ...state, loaded: payload };
        case PRODUCTS_LOADING:
            return { ...state, isLoading: payload };
        default:
            return state;
    }
}
