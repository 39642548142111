import { Dimensions, Platform } from 'react-native';
import { onAction } from './middleware/event-sync';

export const CURRENT_DEVICE = 'CURRENT_DEVICE';
export const CURRENT_DEVICE_SET = `[${CURRENT_DEVICE}] set`;
export const CURRENT_DEVICE_LOADING = `[${CURRENT_DEVICE}] loading`;

const initialState = {
    width: 0,
    height: 0,
    platform: ''
};

export const deviceSet = (payload) => ({ type: CURRENT_DEVICE_SET, payload });

export function CurrentDeviceReducer(state = initialState, action) {
    switch (action.type) {
        case CURRENT_DEVICE_SET:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

/**
 * [TODO] Este código debe ir en otra parte
 */
export const getDeviceState = () => {
    const deviceWidth = Dimensions.get('window').width;
    const deviceHeight = Dimensions.get('window').height;
    const os = Platform.OS;
    return {
        width: deviceWidth,
        height: deviceHeight,
        os: os
    };
};

const deviceInitMiddleware = onAction(
    CURRENT_DEVICE_LOADING,
    (store, action) => {
        const device = getDeviceState();
        console.log('DEVICE__SET');
        store.dispatch(deviceSet(device));
    }
);


export const deviceMiddleware = [deviceInitMiddleware];
