import * as React from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import {
    Text,
    FlatList
} from 'react-native';

import {
    Button
} from 'react-native-elements';
import { getMinigroup, getMinigroupInvites, leaveMinigroup } from '../../redux/miniGroups';
import InviteListItem from '../../components/minigroup/InviteListItem';

export default function ManageMinigroupScreen({ navigation, route }) {

    const dispatch = useDispatch();
    const group = useSelector(state => state.miniGroups.minigroup);
    const invites = useSelector(state => state.miniGroups.minigroupInvites);


    React.useEffect(() => {
        dispatch(getMinigroup());
        dispatch(getMinigroupInvites());
    }, []);
    return (
        <View>
            { group && <Button
                title='Abandonar minigrupo'
                onPress={() => {
                    dispatch(leaveMinigroup())
                }}
            ></Button>}
            { !group && <FlatList
                data={invites}
                renderItem={({ item, index, separators }) => {
                    return (
                        <InviteListItem
                            navigation={navigation}
                            item={item}
                        />
                    )
                }}
                keyExtractor={item => item.id.toString()}
            />}


        </View>
    );
}