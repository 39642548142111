import * as React from 'react';

import {
    View,
    Text,
    Image,
    ImageBackground,
    Share,
    FlatList
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Icon,
    Button,
    Input
} from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClaims } from '../../redux/claims';
import theme, { themeVariables } from '../../theme/styles';
import ClaimListItem from '../../components/Claims/ClaimListItem';

export default function ClaimsScreen({ navigation, route }) {
    const dispatch = useDispatch();
    const claims = useSelector(state => state.claims.claims);


    React.useEffect(() => {
        dispatch(getAllClaims());
    }, []);

    return (
        <View style={[theme.screenContainer, { paddingHorizontal: themeVariables.gutter }]}>
            <Text style={theme.header4}>Reclamos</Text>
            <FlatList
                data={claims}
                renderItem={({ item, index, separators }) => (
                    <ClaimListItem
                        item={item}
                        navigation={navigation}
                    ></ClaimListItem>
                )}
                keyExtractor={item => item.id.toString()}
            />
        </View>);
}