import * as React from 'react';
import { Text, View, Image,TouchableWithoutFeedback } from 'react-native';
import compaApi from '../apis/compa';
import theme, { themeVariables } from '../theme/styles';

export default function FavouritesListItem ({ item, navigation, group }) {
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        navigation.push('PointProducts', { point: item });
      }}
    >
      <View style={{ flexDirection: 'column', marginBottom: themeVariables.smallMargin }}>
          <Image 
            source={{
              uri: group && compaApi.generateImageUri(group.logo) ? compaApi.generateImageUri(group.logo) :require('../assets/logo.png')
            }}
            style={{
              width: 300,
              height: 100,
              borderRadius: 10,
              marginBottom: themeVariables.smallMargin
            }}
            />
          <View style={{ flexDirection: 'row'}}>
            <Image 
              source={{
                uri: group && compaApi.generateImageUri(group.logo) ? compaApi.generateImageUri(group.logo) :require('../assets/logo.png')
              }}
              style={{
                width: 60,
                height: 60,
                borderRadius: 5
              }}
              />
            <View style={{ flexShrink: 1, marginHorizontal: 2 }}>
              <Text style={ theme.header6 }>{ item.nombre }</Text>
              <Text style={ [theme.smallText, { width: '100%', flexWrap: 'wrap'}] }>{ item.ubicacion }</Text>
            </View>
          </View>
      </View>
    </TouchableWithoutFeedback>
  );
}