import * as React from 'react';

import {
    View,
    Text
} from 'react-native';
import {
    Button,
    Divider
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import dayjs from 'dayjs';

export default function ClaimListItem ({ item, navigation,admin=false }) {
    return (
        <View style={{ backgroundColor: 'white', padding: 5, borderWidth: 1, borderColor: '#CCC', marginBottom: 5 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                <View style={{ flex: 1, flexGrow: 1}}>
                    <Text style={theme.header5}>Solicitante: {admin ? item.user.name : item.points.nombre}</Text>
                    <Text style={theme.header5}>Fecha: {dayjs(item.created_at).format('DD/MM/YY HH:mm')}</Text>
                    <Text style={ [theme.normalText, { color: 'red' }] }>Estado: {item.estado == 0 ? 'Pendiente' : (item.estado == 1 ? 'Finalizado' : 'Cancelado')}</Text>
                </View>
                <Button 
                title='Ver el reclamo'
                containerStyle={[theme.buttonSuccessContainerStyle, { alignSelf: 'center' }]}
                buttonStyle={[theme.buttonSuccessButtonStyle]}
                titleStyle={[theme.buttonSuccessTitleStyle]}
                onPress={() => {
                        if(admin) {
                            navigation.push('ClaimManagementScreen',{ claimId: item.id})
                        } else {
                            navigation.push('ClaimScreen', { claimId: item.id });
                        }
                    }}
                />
            </View>
        </View>
    );
}
  