// Create an instance.
export default class OrdersApi {
    constructor(baseUrl, network) {
      this.baseUrl = baseUrl;
      this.token = null;
      this.network = network;
    }

    /* set token (val) {
      console.log('setter tocken', val);
    } */

    addProduct(data) {
      return new Promise((resolve, reject) => {
        this.network.post(this.baseUrl + '/pedidos/agregarProducto', data, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      });
    }
    getAllOrders() {
      return new Promise((resolve, reject) => {
        this.network.get(this.baseUrl + '/pedidos/pedidos', null, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      });
    }
    getPastOrders() {
      return new Promise((resolve, reject) => {
        this.network.get(this.baseUrl + '/pedidos/antiguos', null, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      });
    }
    confirmOrder(data) {
      return new Promise((resolve, reject) => {
        this.network.post(this.baseUrl + '/pedidos/confirmar', data, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      }) 
    }
    deliverOrder(data) {
      return new Promise((resolve, reject) => {
        this.network.post(this.baseUrl + '/pedidos/marcarComoEntregado', data, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      }) 
    }
    uploadReceipt(data) {
      return new Promise((resolve, reject) => {
        this.network.post(this.baseUrl + '/pedidos/comprobante', data, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      }) 
    }
    confirmCashPayment(data) {
      return new Promise((resolve, reject) => {
        this.network.post(this.baseUrl + '/pedidos/pagoefectivo', data, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      }) 
    }

    changePickUpInfo(data){
      return new Promise((resolve, reject) => {
        this.network.post(this.baseUrl + '/pedidos/cambiarRetiro', data, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      }) 
    }
}