// Create an instance.
export default class MiniGroupsApi {
    constructor(baseUrl, network) {
      this.baseUrl = baseUrl;
      this.token = null;
      this.network = network;
    }

    getUserList(search){
      search = search ? "/" + search : search;
      return new Promise((resolve, reject) => {
        this.network.get(this.baseUrl + '/minigroups/userlist' + search, null, this.token).then((json) => {
          resolve(json);null
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    }

    getMiniGroup() {
      return new Promise((resolve, reject) => {
        this.network.get(this.baseUrl + '/minigroups/minigrupo', null, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      });
    }

    getMiniGroupInvites() {
      return new Promise((resolve, reject) => {
        this.network.get(this.baseUrl + '/minigroups/invitaciones', null, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      });
    }

    createMiniGroup(data) {
      return new Promise((resolve, reject) => {
        this.network.post(this.baseUrl + '/minigroups/crearMinigrupo', data, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      });
    }

    inviteUser(data) {
      return new Promise((resolve, reject) => {
        this.network.post(this.baseUrl + '/minigroups/invitar', data, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      });
    }
    acceptInvite(data) {
      return new Promise((resolve, reject) => {
        this.network.post(this.baseUrl + '/minigroups/aceptarInvitacion', data, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      });
    }

    leaveMiniGroup() {
      return new Promise((resolve, reject) => {
        this.network.post(this.baseUrl + '/minigroups/abandonar', null, this.token).then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
      });
    }
}