
import compaApi from '../apis/compa';

export const MINIGROUP = 'MINIGROUP';

export const MINIGROUP_LOADING = `[${MINIGROUP}] MINIGROUPs`;

export const MINIGROUP_LOADED = `[${MINIGROUP}] loaded`;

export const MINIGROUP_SET_RESULT_MINIGROUP = `[${MINIGROUP}] set result MINIGROUPs`;

export const MINIGROUP_SET_RESULT_MINIGROUP_INVITES = `[${MINIGROUP}] set result MINIGROUPs invites`;

export const setResultMinigroup = (result) => ({
    type: MINIGROUP_SET_RESULT_MINIGROUP,
    payload: result
});

export const setResultMinigroupInvites = (result) => ({
    type: MINIGROUP_SET_RESULT_MINIGROUP_INVITES,
    payload: result
});

export const setMinigroupLoading = (isLoading) => ({
    type: MINIGROUP_LOADING,
    payload: isLoading
});

export const setMinigroupLoaded = (loaded) => ({
    type: MINIGROUP_LOADED,
    payload: loaded
});

export const getMinigroup = () => {
    return (dispatch) => {
        dispatch(setMinigroupLoading(true));
        compaApi.MiniGroups
            .getMiniGroup()
            .then((result) => {
                dispatch(setResultMinigroup(result));
                dispatch(setMinigroupLoading(false));
                dispatch(setMinigroupLoaded(true));
            })
            .catch(() => {
                dispatch(setMinigroupLoading(false));
            });
    };
};
export const getMinigroupInvites = () => {
    return (dispatch) => {
        compaApi.MiniGroups
            .getMiniGroupInvites()
            .then((result) => {
                dispatch(setResultMinigroupInvites(result));
            })
            .catch(() => {
            });
    };
};
export const leaveMinigroup = () => {
    return (dispatch) => {
        compaApi.MiniGroups
            .leaveMiniGroup()
            .then((result) => {
                dispatch(getMinigroupInvites());
                dispatch(getMinigroup());
            })
            .catch(() => {
            });
    };
};
export const acceptMinigroupInvite = (minigroupId) => {
    return (dispatch) => {

        let data = {
            minigrupo: minigroupId
        }
        compaApi.MiniGroups
            .acceptInvite(data)
            .then((result) => {
                dispatch(getMinigroupInvites());
                dispatch(getMinigroup());
            })
            .catch(() => {
            });
    };
};
export const createMiniGroup = () => {
    return (dispatch) => {
        dispatch(setMinigroupLoading(true));
        compaApi.MiniGroups
            .createMiniGroup()
            .then((result) => {
                dispatch(setResultMinigroup(result));
                dispatch(setMinigroupLoading(false));
                dispatch(setMinigroupLoaded(true));
            })
            .catch(() => {
                dispatch(setMinigroupLoading(false));
            });
    };
};


const initialState = {
    minigroup: null,
    minigroupInvites: null,
    loaded: false,
    isLoading: false
};

export function MiniGroupsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case MINIGROUP_SET_RESULT_MINIGROUP:
            return { ...state, minigroup: payload };
        case MINIGROUP_SET_RESULT_MINIGROUP_INVITES:
            return { ...state, minigroupInvites: payload };
        case MINIGROUP_LOADED:
            return { ...state, loaded: payload };
        case MINIGROUP_LOADING:
            return { ...state, isLoading: payload };
        default:
            return state;
    }
}
