import * as React from 'react';
import { View } from 'react-native';
import { Button, Icon, Input } from 'react-native-elements';
import { setMapView, searchGroups } from '../redux/groups';
import { useSelector, useDispatch } from 'react-redux';
import theme, { themeVariables } from '../theme/styles';
import useThrottleDebounceSearchGroups from '../hooks/useThrottleDebounceSearchGroups.js';

export default function SearchGroup ({ iconName, iconType, text }) {
    const dispatch = useDispatch();
    const isMapView = useSelector(state => state.groups.mapView);
    const [search, setSearch] = React.useState('');
    useThrottleDebounceSearchGroups(search);

    return (
        <View 
        style={[theme.headerBottom, {
          flexDirection: 'row', 
          paddingTop: 8, 
          paddingBottom: 16,
          paddingHorizontal: themeVariables.gutter,
          zIndex: 1
        }]}
      >
        <Input
          placeholder='Buscar'
          onChangeText={setSearch}
          style={{
            fontSize: 15,
            width: '100%'
          }}
          containerStyle={{ 
            width: '85%',
            paddingHorizontal: 0,
            paddingRight: 10
          }}
          inputContainerStyle={{
            borderColor: '#DDD',
            borderWidth: 1,
            elevation:10,
            shadowColor: '#999',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.5,
            shadowRadius: 5,
            borderRadius: themeVariables.lowBorderRadius
          }}
          leftIcon={
            <Icon
              name='filter'
              type='font-awesome'
              size={20}
              color={themeVariables.mainColor}
              style={{ 
                marginLeft: 12,
                borderRightColor: '#DDD',
                borderRightWidth: 1,
                paddingRight: 10,
                height: 26,
                justifyContent: 'center',
                marginRight: 6
              }}
            />
          }
          rightIcon={
            <Button 
              type='clear'
              icon={
                <Icon
                  name='search'
                  type='font-awesome'
                  size={20}
                  color={themeVariables.mainComplementaryColor}
                />
              }
              onPress={() => { dispatch(searchGroups(search)) }}
            />
          }
        />
        <Button
          buttonStyle={{
            backgroundColor: themeVariables.mainColor,
            width: 50,
            height: 50,
            shadowColor: '#999',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.5,
            shadowRadius: 5
          }}
          icon={
            isMapView ?
            <Icon
                name='th'
                size={24} 
                type='font-awesome'
                color='white'
            /> : 
            <Icon
                name='map-marker'
                type='font-awesome'
                size={24} 
                color='white'
            /> 
          }
          onPress={() => dispatch(setMapView(!isMapView))}
        />
      </View> 
    );
}