import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, View, Image, TouchableWithoutFeedback } from 'react-native';
import compaApi from '../apis/compa';
import Badged from './ProductFeatures/Badged';
import ItemQuantityInput from './ProductFeatures/ItemQuantityInput';
import ItemPrice from './ProductFeatures/ItemPrice';
import ItemWeight from './ProductFeatures/ItemWeight';
import ReplaceItemCheckbox from './ProductFeatures/ReplaceItemCheckbox';
import theme, { themeVariables } from '../theme/styles';
import { getMinigroup } from '../redux/miniGroups';
import dayjs from 'dayjs';

export default function ProductListItem ({ item, navigation, order, pointId, userMode = false, campaingClosed, isCart }) {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);
    const group = useSelector(state => state.miniGroups.minigroup);
    const isAdmin = user ? user.superAdmin : false; // [TODO: check if the admin is the owner.]

     React.useEffect(() => {
        // dispatch(getMinigroup());
    }, [])
    // TODO: mostrar bultos restantes
    const goToProductDetail = () => {
        if (userMode) {
            let productPointId = item.producto_point_id ? item.producto_point_id : item.id;
            navigation.push('Product', { productId: productPointId, pointId: pointId });
        }
    }
    if (item.producto_campaña) {
        // item.id --> producto point id
        // item.producto_campaña_id
        // console.log('RENDEEEEEEEEER .............');
        return (
            <View>
                <View style={{ flexDirection: 'row', marginBottom: 15, paddingBottom: 15, borderBottomWidth: 1, borderBottomColor: '#CCC', paddingTop: 4 }}>
                    
                    <TouchableWithoutFeedback onPress={goToProductDetail}>
                        <Image
                            source={{
                                uri: compaApi.generateImageUri(item.producto_campaña.imagen, 'images/') ? compaApi.generateImageUri(item.producto_campaña.imagen, 'images/') : require('../assets/logo.png')
                            }}
                            style={{
                                width: 75,
                                height: 75,
                                borderRadius: themeVariables.lowBorderRadius
                            }}
                        />
                    </TouchableWithoutFeedback>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-around', flex: 1, flexWrap: 'wrap', paddingLeft: 4  }}>
                        <View style={{ flex: 1 }}>
                            <View style={{paddingTop: 2}}>
                                <Badged item={item} />
                            </View>
                            <Text 
                                style={[theme.normalText, { fontSize: 16, fontWeight: 'bold', textTransform: 'capitalize', marginTop: 5, flex: 1, flexWrap: 'wrap' }]}
                                onPress={goToProductDetail}
                            >
                                { item.producto_campaña.nombre }
                            </Text>
                            {
                                item.producto_campaña.fecha_cierre &&
                                    <>
                                        <Text>
                                            Fecha de cierre: {dayjs(item.producto_campaña.fecha_cierre).format('DD/MM/YY')} 
                                            { dayjs(item.producto_campaña.fecha_cierre).format() < dayjs().format() ? ' (CERRADA)' : '' }
                                        </Text>
                                    </>
                            }
                            <Text style={theme.normalText}>
                                { item.producto_campaña.peso_bulto > 0 &&
                                    <Text>Bulto: { item.producto_campaña.peso_bulto } kg aprox. </Text>
                                }
                                { item.producto_campaña.precio_referencia_kilo > 0 &&
                                    <Text>Kilo: $ { item.producto_campaña.precio_referencia_kilo } aprox.</Text>
                                }
                            </Text>
                            {
                                item.producto_campaña.divisible > 1 && item.precio_fraccion &&
                                    <Text>Fraccionado: $ {item.precio_fraccion}</Text>
                            }
                            {
                                !(item.producto_campaña.bultos_restantes > 0) &&
                                    <Text style={theme.leadingHighlightText}>Sin stock</Text>
                            }
                            {
                                !isCart &&
                                    <>
                                    </>
                            }
                            {
                                item.precio > 0 && item.producto_campaña.divisible == 1 && 
                                    <Text>Precio: $ { isCart ? item.producto_point.precio : item.precio }</Text>
                            }
                            {
                                item.precio > 0 && item.producto_campaña.divisible > 1 && 
                                    <Text>
                                        Precio: $ { isCart ? item.producto_point.precio : item.precio }. 
                                        Precio fracción mínima: $ { ((isCart ? item.producto_point.precio : item.precio) / item.producto_campaña.divisible) + (item.producto_point ? item.producto_point.precio_fraccion : 0) }
                                    </Text>
                            }
                            {
                                userMode && item.precio > 0 && 
                                    <ItemPrice item={item} order={order} isCart={isCart}  />
                            }
                            {
                                userMode && item.precio > 0 && 
                                    <ItemWeight item={item} order={order}  />
                            }
                            {group ? 
                            <Text style={theme.smallText}>Solicitado por: {item.solicito ? item.solicito.name : ''}</Text>
                            : <></> }
                            
                            { isAdmin && !userMode &&
                                <>
                                    <Text style={theme.leadingHighlightText}>Restantes: {item.bultos_restantes}</Text>
                                    <Text style={theme.leadingHighlightText}>Pedidos: {item.bultos_pedidos}</Text>
                                </>
                            }
                            { userMode &&
                                <ReplaceItemCheckbox order={order} item={item} />
                            }
                        </View>
                        { userMode && !campaingClosed && /* !dayjs(item.producto_campaña.fecha_cierre).format() < dayjs().format() && */
                            <ItemQuantityInput item={item} order={order}  isCart={isCart}  />
                        }
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
}
