import * as React from 'react';
import { FlatList, Text, View, StyleSheet, Image } from 'react-native';
import {
  Button,
  Icon
} from 'react-native-elements';
import compaApi from '../apis/compa';
import theme, { themeVariables } from '../theme/styles';

export default function SelectPointListItem ({ item, navigation, topLeftRoundBorder }) {
  return (
    <View style={{ flexDirection: 'row', marginBottom: themeVariables.gutter, paddingTop: themeVariables.gutter }}>
        <Image 
          source={{
            uri: item && compaApi.generateImageUri(item.logo) ? compaApi.generateImageUri(item.logo) :require('../assets/logo.png')
          }}
          style={{
            width: 120,
            height: 120,
            borderRadius: themeVariables.lowBorderRadius,
            marginBottom: themeVariables.gutter,
            borderTopLeftRadius: topLeftRoundBorder
          }}
        />
        <View style={{ marginLeft: themeVariables.gutter, flexShrink: 1 }}>
          <Text style={ [theme.header5, {marginTop: 0}] }>Point: { item && item.nombre }</Text>
          <Text style={ [{marginVertical: 3}] }>Organizador de compra: { item && item.grupo && item.grupo.nombre }</Text>
          <View style={{ height: 8 }}></View>
          
          <Button 
            type='clear'
            title='Ver orden'
            buttonStyle={{
                justifyContent: 'flex-start',
                marginTop: 8
            }}
            titleStyle={[theme.normalText, {
                color: themeVariables.mainColor,
                textTransform: 'uppercase',
                marginLeft: 5,
                fontWeight: 'bold'
            }]}
            icon={
              <Icon
                name="arrow-right"
                type='font-awesome'
                size={15}
                color={themeVariables.mainColor}
              />
            }
            onPress={() => {
              navigation.push('Cart', { point: item.id });
            }}
          />
        </View>
    </View>
  );
}