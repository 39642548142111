import * as React from 'react';

import {
    View,
    Text,
    Image,
    ImageBackground,
    Share,
    FlatList
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Icon,
    Button,
    Input
} from 'react-native-elements';
import theme, { themeVariables } from '../theme/styles';
import IconTextItem from '../components/IconTextItem';
import HorarioItem from '../components/HorarioItem';
import { useDispatch, useSelector } from 'react-redux';
import { searchUsers } from '../redux/miniGroupInvite';
import UserInviteListItem from '../components/UserInviteListItem';
import useThrottleDebounceSearchUsers from '../hooks/useThrottleDebounceSeachUsers';


export default function InviteUsersScreen ({ navigation, route }) {
    const dispatch = useDispatch();
    const userlist = useSelector(state => state.miniGroupInvite.userlist)
    const [search, setSearch] = React.useState("");
    
    
    React.useEffect(() => {
        dispatch(searchUsers(''));
    }, []);
    useThrottleDebounceSearchUsers(search);

    return (
    <View style={{ paddingHorizontal: themeVariables.gutter }}>
      <Input
        placeholder="Buscar usuarios"
        value={search}
        onChangeText={text => {
            setSearch(text);
        }}
      ></Input> 
      <FlatList
          data={userlist}
          renderItem={({ item, index, separators }) => (
              <UserInviteListItem
                  navigation={navigation}
                  user={item}
              />
          )}
          keyExtractor={item => item.id.toString()}
      />
    </View>);
}