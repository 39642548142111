import * as React from 'react';
import {
    View,
    Text,
    Image,
    ImageBackground,
    Share,
    FlatList,
    Platform
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Icon,
    Button,
    Input,
    Divider
} from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClaims } from '../../redux/claims';
import theme, { themeVariables } from '../../theme/styles';
import ClaimLineListItem from '../../components/Claims/ClaimLineListItem';
import ClaimImagePicker from '../../components/Claims/ClaimImagePicker';
export default function ClaimScreen({ navigation, route }) {
    const dispatch = useDispatch();
    const claimId = route.params && route.params.claimId ? route.params.claimId : null;
    const orderId = route.params && route.params.orderId ? route.params.orderId : null;


    const claims = useSelector(state => state.claims.claims);
    const claim = claimId ? claims.filter(c => c.id == claimId)[0] : claims.filter(c => c.pedido_id == orderId)[0];

   
    React.useEffect(() => {
        dispatch(getAllClaims());
    }, []);

    return (
        <View style={{ padding: themeVariables.gutter }}>
            <Text>Point: {claim ? claim.points.nombre : ""}</Text>
            
            <Button
                title='Agregar producto al reclamo'
                containerStyle={[theme.buttonSuccessContainerStyle]}
                buttonStyle={[theme.buttonSuccessButtonStyle]}
                titleStyle={[theme.buttonSuccessTitleStyle]}
                onPress={() => {
                    navigation.push('AddToClaimScreen', { orderId: claim.pedido_id });
                }}
            />
            <Divider />
            <FlatList
                data={claim ? claim.linea_reclamos : []}
                renderItem={({ item, index, separators }) => (
                    <ClaimLineListItem
                        item={item}
                        navigation={navigation}>
                    </ClaimLineListItem>
                )}
                keyExtractor={item => item.id.toString()}
            />
            <ClaimImagePicker
                claimId={claim ? claim.id : null}
            ></ClaimImagePicker>
        </View>);
}