
import compaApi from '../apis/compa';

import { setResultOrders } from './orders';

export const CART = 'CART';

export const CART_LOADING = `[${CART}] loading`;

export const CART_LOADED = `[${CART}] loaded`;

export const CART_SET_ORDER =  `[${CART}] set order`;
export const CART_SET_POINT =  `[${CART}] set point`;
export const CART_SET_GROUP =  `[${CART}] set group`;
export const CART_SET_PAYMENT_TYPE =  `[${CART}] set payment type`;

export const CART_PAYMENT_LOADING =  `[${CART}] set payment loading`;
export const CART_PAYMENT_RESULT =  `[${CART}] set payment result`;

export const setCartOrder = (order) => {
    return (dispatch) => {
        dispatch(setOrder(order));
    };
};
export const setCartPoint = (point) => {
    return (dispatch) => {
        dispatch(setPoint(point));
    };
};
export const setCartGroup = (group) => {
    return (dispatch) => {
        dispatch(setGroup(group));
    };
};
export const setCartPaymentType = (paymentType) => {
    return (dispatch) => {
        dispatch(setPaymentType(paymentType));
    };
};
export const setCartPickupInfo = (order,pickupInfo) => {
    return (dispatch) => {
        let data = {
            retiro: pickupInfo,
            pedido: order
        };
        compaApi.orders
            .changePickUpInfo(data)
            .then((response) => {
            })
            .catch(() => {
            });
    };
};
export const uploadReceipt = (order,type,amount,receipt) => {
    return (dispatch) => {
        dispatch(setCartPaymentLoading(true));
        dispatch(setCartPaymentResult(null));
        let data = {
            comprobante: receipt,
            metodo: type,
            pedido: order,
            monto: amount
        };
        compaApi.orders
            .uploadReceipt(data)
            .then((response) => {
                dispatch(setCartPaymentLoading(false));
                dispatch(setCartPaymentResult('El pago fue enviado con éxito.'));
                if (Array.isArray(response)) {
                    let orden = response.find(o => o.id == order.id);
                    dispatch(setResultOrders(response));
                    dispatch(setCartOrder(orden));
                }
            })
            .catch(() => {
                dispatch(setCartPaymentLoading(false));
                dispatch(setCartPaymentResult('Ocurrió un error al procesar el pago.'));
                
            });
    };
};
export const confirmCashPayment = (order,type,amount) => {
    return (dispatch) => {
        let data = {
            metodo: type,
            pedido: order,
            monto: amount
        };
        compaApi.orders
            .confirmCashPayment(data)
            .then((response) => {
                if (Array.isArray(response)) {
                    let orden = response.find(o => o.id == order.id);
                    dispatch(setResultOrders(response));
                    dispatch(setCartOrder(orden));
                }
            })
            .catch(() => {
            });
    };
};

export const setOrder = (result) => ({
    type: CART_SET_ORDER,
    payload: result
});
export const setPoint = (result) => ({
    type: CART_SET_POINT,
    payload: result
});
export const setGroup = (result) => ({
    type: CART_SET_GROUP,
    payload: result
});
export const setPaymentType = (result) => ({
    type: CART_SET_PAYMENT_TYPE,
    payload: result
});
export const setCartLoading = (isLoading) => ({
    type: CART_LOADING,
    payload: isLoading
});

export const setCartLoaded = (loaded) => ({
    type: CART_LOADED,
    payload: loaded
});


export const setCartPaymentLoading = (loading) => ({
    type: CART_PAYMENT_LOADING,
    payload: loading
});

export const setCartPaymentResult = (result) => ({
    type: CART_PAYMENT_RESULT,
    payload: result
});

const initialState = {
    order: null,
    point: null,
    group: null,
    paymentType: null,
    loaded: false,
    isLoading: false,
    paymentLoading: false,
    paymentResult: null
};

export function CartReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CART_SET_POINT:
            return {...state, point: payload}
        case CART_SET_GROUP:
            return {...state, group: payload}
        case CART_SET_ORDER:
            return { ...state, order: payload };
        case CART_SET_PAYMENT_TYPE:
            return { ...state, paymentType: payload };
        case CART_LOADED:
            return { ...state, loaded: payload };
        case CART_LOADING:
            return { ...state, isLoading: payload };
        case CART_PAYMENT_LOADING:
            return { ...state, paymentLoading: payload };
        case CART_PAYMENT_RESULT:
            return { ...state, paymentResult: payload };
        default:
            return state;
    }
}
