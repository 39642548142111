import * as React from 'react';
import { View } from 'react-native';

import {
  Text,
  TextInput,
  FlatList,
  ScrollView
} from 'react-native';

import {
  Button,
  Image,
  Icon,
} from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import theme, { themeVariables } from '../theme/styles';
import { useForm } from 'react-hook-form';
import { changeProfile } from '../redux/user';
import { doLogout } from '../redux/auth';
import PointListItem from '../components/PointListItem';
import AsyncStorage from '@react-native-async-storage/async-storage';


export default function ProfileScreen({ navigation, route }) {

  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const favorites = useSelector(state => state.favorites.favorites);


  //el hook esta bueno para esto tambien, se puede modificar
  const { register, handleSubmit, setValue, errors, getValues } = useForm();
  React.useEffect(() => {
    register('name', { required: false });
    register('email', { required: false, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i });
    register('phone', { required: false, pattern:  /\+?(549)?[0-9]{10}/i });
  }, [register]);

  React.useEffect(() => {
    setValue('name', user.name);
    setValue('email', user.email);
    setValue('phone', user.telefono);
  }, [user])

  const onSubmit = (data) => {
    console.log('onSubmit');
    dispatch(changeProfile(data));
  };

  return (
    <ScrollView contentContainerStyle={{ backgroundColor: 'white' }}>
      <View style={{ alignItems: "center" }}>
        <Image
          source={{
            uri: user.photo && compaApi.generateImageUri(user.photo) ? compaApi.generateImageUri(user.photo) : require('../assets/logo.png')
          }}
          style={{
            width: 120,
            height: 120,
            borderRadius: themeVariables.lowBorderRadius,
            marginBottom: themeVariables.gutter,
          }}
        />
        <Text style={theme.header6}>{user.name}</Text>
      </View>
      <View style={{ paddingHorizontal: 15 }}>
        <Text style={theme.header6}>Mis datos</Text>
        <View style={theme.loginInputContainerStyle}>
          <Icon
            name='person'
            size={24}
            color={themeVariables.mainColor}
            style={theme.loginIconInputStyle}
          ></Icon>
          <TextInput
            style={theme.loginInputStyle}
            placeholderTextColor={themeVariables.mainColor}
            onChangeText={text => setValue('name', text)}
            placeholder="Nombre completo"
            textContentType="name"
            autoCompleteType="name"
            defaultValue={user.name}
          ></TextInput>
        </View>
        {
          errors.name && 
            <Text style={{ color: 'red' }}>El nombre de estar completo.</Text>
        }
        <View style={theme.loginInputContainerStyle}>
          <Icon
            name='email'
            size={24}
            color={themeVariables.mainColor}
            style={theme.loginIconInputStyle}
          ></Icon>
          <TextInput
            style={theme.loginInputStyle}
            placeholderTextColor={themeVariables.mainColor}
            onChangeText={text => setValue('email', text)}
            placeholder="Email"
            textContentType="emailAddress"
            autoCompleteType="email"
            defaultValue={user.email}
          ></TextInput>
        </View>
        {
          errors.email && !getValues('email') && 
            <Text style={{ color: 'red' }}>El email de estar completo.</Text>
        }
        {
          errors.email && getValues('email') && 
            <Text style={{ color: 'red' }}>El email ingresado es inválido.</Text>
        }
        <View style={theme.loginInputContainerStyle}>
          <Icon
            name='phone'
            size={24}
            color={themeVariables.mainColor}
            style={theme.loginIconInputStyle}
          ></Icon>
          <TextInput
            style={theme.loginInputStyle}
            placeholderTextColor={themeVariables.mainColor}
            onChangeText={text => setValue('phone', text)}
            placeholder="Telefono"
            textContentType="telephoneNumber"
            autoCompleteType="tel"
            defaultValue={user.telefono}
          ></TextInput>
        </View>
        {
          errors.phone && !getValues('phone') && 
            <Text style={{ color: 'red' }}>El teléfono de estar completo.</Text>
        }
        {
          errors.phone && getValues('phone') && 
            <Text style={{ color: 'red' }}>El teléfono ingresado es inválido. Complete su número sin guiones o espacios, puede incluir o no 549.</Text>
        }
        <Button
          containerStyle={[theme.buttonSuccessContainerStyle, { marginBottom: 30, borderRadius: 30 }]}
          buttonStyle={[theme.buttonSuccessButtonStyle, { paddingVertical: 16, paddingRight: 26, fontWeight: 'bold' }]}
          titleStyle={[theme.buttonSuccessTitleStyle]}
          icon={
            <Icon
              name='arrow-forward'
              size={14}
              color='white'
              style={{
                paddingTop: 2,
                paddingRight: 12
              }}
            />
          }
          onPress={() => {
            console.log(register);
            console.log('handleSubmit', handleSubmit(onSubmit));
            handleSubmit(onSubmit)();
          }}
          title="Cambiar datos"
        />
      </View>

      <View style={{ paddingHorizontal: 15, justifyContent: 'center' }}>
        <Text style={theme.header6}>Acciones</Text>
        <Button
          title='Ver mi balance'
          containerStyle={[theme.buttonSuccessContainerStyle, { marginBottom: 30, borderRadius: 30 }]}
          buttonStyle={[theme.buttonSuccessButtonStyle, { paddingVertical: 16, paddingRight: 26, fontWeight: 'bold' }]}
          titleStyle={[theme.buttonSuccessTitleStyle]}
          onPress={() => {
            navigation.push('BalanceScreen');
          }}
        />
        <Button
          title='Historial de pedidos'
          containerStyle={[theme.buttonSuccessContainerStyle, { marginBottom: 30, borderRadius: 30 }]}
          buttonStyle={[theme.buttonSuccessButtonStyle, { paddingVertical: 16, paddingRight: 26, fontWeight: 'bold' }]}
          titleStyle={[theme.buttonSuccessTitleStyle]}
          onPress={() => {
              navigation.navigate('PastOrdersScreen');
          }}
        />
        <Button
          title='Reclamos'
          containerStyle={[theme.buttonSuccessContainerStyle, { marginBottom: 30, borderRadius: 30 }]}
          buttonStyle={[theme.buttonSuccessButtonStyle, { paddingVertical: 16, paddingRight: 26, fontWeight: 'bold' }]}
          titleStyle={[theme.buttonSuccessTitleStyle]}
          onPress={() => {
              navigation.navigate('ClaimsScreen');
          }}
        />
        <Button
          title='Cerrar sesión'
          containerStyle={[theme.buttonSuccessContainerStyle, { marginBottom: 30, borderRadius: 30 }]}
          buttonStyle={[theme.buttonSuccessButtonStyle, { paddingVertical: 16, paddingRight: 26, fontWeight: 'bold', backgroundColor: '#666' }]}
          titleStyle={[theme.buttonSuccessTitleStyle]}
          onPress={() => {
              AsyncStorage.clear();
              dispatch(doLogout());
              // navigation.navigate('ClaimsScreen');
              navigation.navigate('Home');
          }}
        />
      </View>
      {
        (user.grupo_id > 0 || user.point_id > 0 || user.proveedor_id > 0 || user.es_productor > 0 || user.superAdmin > 0) &&
          <View style={{ paddingHorizontal: 15, justifyContent: 'center' }}>
            <Text style={[theme.header6, { marginBottom: 10 }]}>Administración</Text>
            {
              user.grupo_id > 0 && 
                <Button
                    title='Administración Org. de Compra'
                    containerStyle={[theme.buttonSuccessContainerStyle, { marginBottom: 30, borderRadius: 30 }]}
                    buttonStyle={[theme.buttonSuccessButtonStyle, { paddingVertical: 16, paddingRight: 26, fontWeight: 'bold', backgroundColor: '#666' }]}
                    titleStyle={[theme.buttonSuccessTitleStyle]}
                    onPress={() => {
                        navigation.navigate('GroupManagementScreen');
                    }}
                />
            }
            {
              (user.proveedor_id > 0) &&
                <Button
                    title='Administración Productor'
                    containerStyle={[theme.buttonSuccessContainerStyle, { marginBottom: 30, borderRadius: 30 }]}
                    buttonStyle={[theme.buttonSuccessButtonStyle, { paddingVertical: 16, paddingRight: 26, fontWeight: 'bold', backgroundColor: '#666' }]}
                    titleStyle={[theme.buttonSuccessTitleStyle]}
                    onPress={() => {
                        navigation.navigate('ProducerScreen');
                    }}
                />
            }
            {
              user.point_id > 0 &&
                <Button
                    title='Administración Point'
                    containerStyle={[theme.buttonSuccessContainerStyle, { marginBottom: 30, borderRadius: 30 }]}
                    buttonStyle={[theme.buttonSuccessButtonStyle, { paddingVertical: 16, paddingRight: 26, fontWeight: 'bold', backgroundColor: '#666' }]}
                    titleStyle={[theme.buttonSuccessTitleStyle]}
                    onPress={() => {
                        navigation.navigate('PointManagement');
                    }}
                />
            }
          </View>

      }
      {
        favorites && favorites.length > 0 &&
          <View style={{ paddingHorizontal: 15 }}>
            <Text style={theme.header6}>Grupos favoritos</Text>
            <FlatList
              data={favorites}
              renderItem={({ item, index, separators }) => (
                <PointListItem
                  navigation={navigation}
                  item={item}
                />
              )}
              keyExtractor={item => item.id.toString()}
            />
          </View>
      }
    </ScrollView>

  );
}