import * as React from 'react';

import {
    Text,
    View,
    ActivityIndicator,
    FlatList
} from 'react-native';

import { useSelector, useDispatch } from 'react-redux';
import { getNotifications, markRead } from '../redux/notifications';
import theme, { themeVariables } from '../theme/styles';
import NotificationListItem from '../components/NotificationListItem';
import dayjs from 'dayjs'

export default function NotificacionesScreen() {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notifications.notifications);
    const loaded = useSelector(state => state.notifications.loaded);
    
    const todayNotifications = notifications ? notifications.filter(n => dayjs().diff(dayjs(n.fecha), 'hour') < 24) : [];
    const weekNotifications = notifications ? notifications.filter(n => dayjs().diff(dayjs(n.fecha), 'day') < 7 && dayjs().diff(dayjs(n.fecha), 'day') > 1) : [];
    const olderNotifications = notifications ? notifications.filter(n => dayjs().diff(dayjs(n.fecha), 'day') > 7) : [];

    React.useEffect(() => {
        dispatch(getNotifications());
    }, []);
    React.useEffect(() => {
        if (notifications && notifications.length) {
            dispatch(markRead());
        }
    }, [notifications]);
    return (
        <View style={[theme.screenContainer, { padding: themeVariables.gutter}]}>
            <Text style={[theme.header4, { marginTop: 0 }]}>Notificaciones</Text>
            {
                loaded && notifications && notifications.length > 0 &&
                <>

                    { todayNotifications && todayNotifications.length > 0 &&
                        <View>
                            <Text style={[theme.header4, { marginTop: 0 }]}>Hoy</Text>
                            <FlatList
                                data={todayNotifications}
                                renderItem={({ item, index, separators }) => {
                                    return (
                                        <NotificationListItem
                                            item={item}
                                        />
                                    )
                                }}
                                keyExtractor={item => item.id.toString()}
                            />
                        </View>}
                    { weekNotifications && weekNotifications.length > 0 &&
                        <View>
                            <Text style={[theme.header4, { marginTop: 0 }]}>Esta semana</Text>
                            <FlatList
                                data={weekNotifications}
                                renderItem={({ item, index, separators }) => {
                                    return (
                                        <NotificationListItem
                                            item={item}
                                        />
                                    )
                                }}
                                keyExtractor={item => item.id.toString()}
                            />
                        </View>}
                    { olderNotifications && olderNotifications.length > 0 &&
                        <View>
                            <Text style={[theme.header4, { marginTop: 0 }]}>Anteriores</Text>
                            <FlatList
                                data={olderNotifications}
                                renderItem={({ item, index, separators }) => {
                                    return (
                                        <NotificationListItem
                                            item={item}
                                        />
                                    )
                                }}
                                keyExtractor={item => item.id.toString()}
                            />
                        </View>}
                </>
            }
            {
                loaded && (!notifications || notifications.length == 0) &&
                    <Text>No has recibido notificaciones aún.</Text>
            }
            {
                !loaded &&
                <View style={theme.activityIndicator}>
                    <ActivityIndicator size='large' color={themeVariables.mainColor} />
                </View>
            }
        </View>
    );
}