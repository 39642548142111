import * as React from 'react';
import compaApi from '../../apis/compa';
import {
  View,
  Text,
  TouchableWithoutFeedback,
  Image
} from 'react-native';
import {
  Icon
} from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import { displayOrderStatusName, totalizadorPeso } from '../../utils/helpers';
import dayjs from 'dayjs';

// Component for showing a user order to a point manager
export default function UserOrderListItem({ item, navigation, touchable=true}) {
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (touchable) {
          navigation.push('OrderDetail', { order: item.id });;
        }
      }}
    >
      <View style={{ flexDirection: 'row', marginBottom: themeVariables.gutter, backgroundColor: 'white', padding: 5, borderWidth: 1, borderColor: '#CCC', marginBottom: 5 }}>
        {/* <Image
          source={{
            uri: item && compaApi.generateImageUri(item.photo) ? compaApi.generateImageUri(item.photo) : require('../../assets/logo.png')
          }}
          style={{
            width: 120,
            height: 120,
            borderRadius: themeVariables.lowBorderRadius,
            marginBottom: themeVariables.gutter,
          }}
        /> */}
        <View style={{ flexShrink: 1 }}>
          <Text>Pedido <Text style={{ fontWeight: 'bold' }}># {item.id}</Text></Text>
          <Text>Usuario: <Text style={{ fontWeight: 'bold' }}>{item.users.name}</Text></Text>
          <Text>Fecha: <Text style={{ fontWeight: 'bold' }}>{ dayjs(item.fecha).format('DD/MM/YYYY HH:mm') }</Text></Text>
          <Text>Cantidad productos: <Text style={{ fontWeight: 'bold' }}>{item.linea_pedidos.length}</Text></Text>
          
          <Text>Peso total aprox.: <Text style={{ fontWeight: 'bold' }}>{item.linea_pedidos.reduce(totalizadorPeso, 0)} kg.</Text></Text>
          {/* <Text style={[theme.normalText, { width: '100%', flexWrap: 'wrap', marginBottom: 4 }]}>{item.}</Text> */}
          {/* <Text style={[theme.normalText, { width: '100%', flexWrap: 'wrap', marginBottom: 4 }]}>{item.}</Text> */}
          <Text>Estado: <Text style={{ fontWeight: 'bold' }}>{ item ? displayOrderStatusName(item.estado) : '' }</Text></Text>

        </View>
        {
          touchable && 
            <Icon 
              name='chevron-right'
              color='#CCC'
              size={24}
              containerStyle={{ position: 'absolute', right: 5, top: 20 }}
            />
        }
      </View>
    </TouchableWithoutFeedback>
  );
}
