import * as React from 'react';
import { View } from 'react-native';

import {
    Text
} from 'react-native';

import {
    Button
} from 'react-native-elements';

export default function HomeScreen({ navigation, route }) {
    return (
        <View >
            <Text>Pantalla intermedia a remover</Text>
            <Button
                title='Ir a perfil'
                onPress={() => {
                    navigation.push('ProfileScreen');
                }}
                ></Button>
            <Button
                title='Ir a Productor'
                onPress={() => {
                    navigation.push('ProducerScreen');
                    navigation.navigate('Home');
                }}
            ></Button>
            <Button
                title='Administrar mini grupo'
                onPress={() => {
                    navigation.push('GroupManagementScreen');
                    navigation.navigate('Home');
                }}
            ></Button>
            <Button
              title='Mini grupo'
              // containerStyle={[theme.buttonSuccessContainerStyle, { marginBottom: 30, borderRadius: 30 }]}
              // buttonStyle={[theme.buttonSuccessButtonStyle, { paddingVertical: 16, paddingRight: 26, fontWeight: 'bold' }]}
              // titleStyle={[theme.buttonSuccessTitleStyle]}
              onPress={() => {
                navigation.navigate('ManageMinigroupScreen');
              }}
            />
        </View>
    );
}