// Create an instance.

// import { downloadFile, DownloadDirectoryPath } from 'react-native-fs';

export default class PointManagementApi {
  constructor(baseUrl, network) {
    this.baseUrl = baseUrl;
    this.token = null;
    this.network = network;
  }
  getPoint (id) {
    return new Promise((resolve, reject) => {
      this.network.get(this.baseUrl + '/points/' + id, null, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getAllPointOrders(campaing_id) {
    return new Promise((resolve, reject) => {
      this.network.get(this.baseUrl + '/points/admin/pedidos' + (campaing_id ? ('?campaña_id=' + campaing_id) : ''), null, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPointProducts(campaing_id) {
    return new Promise((resolve, reject) => {
      this.network.get(this.baseUrl + '/points/admin/productos' + (campaing_id ? ('?campaña_id=' + campaing_id) : ''), null, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getPointClaims(campaing_id) {
    return new Promise((resolve, reject) => {
      this.network.get(this.baseUrl + '/points/admin/reclamos' + (campaing_id ? ('?campaña_id=' + campaing_id) : ''), null, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getPointPayments(campaing_id) {
    return new Promise((resolve, reject) => {
      this.network.get(this.baseUrl + '/points/admin/pagos' + (campaing_id ? ('?campaña_id=' + campaing_id) : ''), null, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPointCampaings() {
    return new Promise((resolve, reject) => {
      this.network.get(this.baseUrl + '/points/admin/campañas', null, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
  downloadReceipt(paymentId) {
    return new Promise((resolve, reject) => {
      // this.network.get(this.baseUrl + '/pagos/descargarComprobante/' + paymentId, null, this.token).then((json) => {
      //   resolve(json);
      // })
      //   .catch((error) => {
      //     reject(error);
      //   });

      downloadFile({
        fromUrl: this.baseUrl + '/pagos/descargarComprobante/' + paymentId,
        headers: {
          "Authorization": "Bearer " + this.token
        },
        toFile: DownloadDirectoryPath
      })
    });
  }
  createPayment(data) {
    return new Promise((resolve, reject) => {
      this.network.post(this.baseUrl + '/pagos/crearPago', data, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
  approvePayment(data) {
    return new Promise((resolve, reject) => {
      this.network.post(this.baseUrl + '/pagos/aprobarPago', data, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
  approveClaimLine(data) {
    return new Promise((resolve, reject) => {
      this.network.post(this.baseUrl + '/points/admin/reclamos/aprobarlinea', data, this.token).then((json) => {
        resolve(json);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }
}