import * as React from 'react';
import PointScreen from './PointScreen';
import ProductsScreen from './ProductsScreen';
import { Text, View, ScrollView } from 'react-native';
import Tabs from 'react-native-tabs';
import theme, { themeVariables } from '../theme/styles';
import CartResume from '../components/CartResume';

export default function PointProductsScreen ({ route, navigation }) {
    const point = route.params && route.params.point ? route.params.point : null;
    const [tabSelected, setTabSelected] = React.useState('products');
    return (
        <View style={theme.screenContainer}>
            <Tabs 
                selected={tabSelected} 
                style={{
                    backgroundColor: 'white', 
                    top: 0,
                    borderTopWidth: 0,
                    borderTopColor: themeVariables.mainComplementaryColor,
                    borderBottomWidth: 1,
                    borderBottomColor: themeVariables.mainComplementaryColor
                }}
                selectedStyle={{
                    borderBottomWidth: 0, 
                    borderBottomColor: themeVariables.mainComplementaryColor, 
                    color: themeVariables.mainComplementaryColor,
                    fontWeight: 'bold'
                }}
                selectedIconStyle={{
                    borderBottomWidth: 1, 
                    borderBottomColor: themeVariables.mainComplementaryColor, 
                    color: themeVariables.mainComplementaryColor
                }}
                onSelect={el => setTabSelected(el.props.name)}
            >
                <Text name="point">Información</Text>
                <Text name="products">Comprar</Text>
            </Tabs>
            <ScrollView style={{ marginTop: 50 }}>
                { tabSelected === 'point' &&
                    <PointScreen route={route} navigation={navigation} changeTab={() => setTabSelected('products')} />
                }
                { tabSelected === 'products' &&
                    <View style={{ flex: 1 }}>
                        <CartResume navigation={navigation} pointParam={point} title='Resumen de compra' />
                        <ProductsScreen route={route} navigation={navigation} point={point} />
                    </View>
                }
            </ScrollView>
        </View>
    );
}