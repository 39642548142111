import * as React from 'react';
import { getPointProducts } from '../redux/products'
import { getAllOrders,  modifyProduct } from '../redux/orders'
import { createMiniGroup, getMinigroup } from '../redux/miniGroups';
import { useSelector, useDispatch } from 'react-redux';
import { FlatList, Text, View, StyleSheet, Image } from 'react-native';
import ProductListItem from '../components/ProductListItem';
import theme, { themeVariables } from '../theme/styles';
import {
  Icon,
  Button
} from 'react-native-elements';
import dayjs from 'dayjs';
import { ActivityIndicator } from 'react-native';

export default function ProductsScreen ({ navigation, route, point }) {
    const products = useSelector(state => state.products.products);
    const productsLoaded = useSelector(state => state.products.loaded);
    const productsLoading = useSelector(state => state.products.isLoading);
    const orders = useSelector(state => state.orders.orders);
    const localOrders = useSelector(state => state.localOrders.orders);
    const loaded = useSelector(state => state.orders.loaded);
    const ordersLoading = useSelector(state => state.orders.isLoading);
    const group = useSelector(state => state.miniGroups.minigroup);
    const groups = useSelector(state => state.groups.groups);
    const shopGroup = groups.find(g => g.id === point.grupo_id);

    const [ productsWithStock, setProductsWithStock] = React.useState([]);
    const [ productsWithoutStock, setProductsWithoutStock] = React.useState([]);

    const loading = (productsLoading || ordersLoading);

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getPointProducts(point.id));
        dispatch(getAllOrders());
        // dispatch(getMinigroup());
    }, []);
    React.useEffect(() => {
      console.log('products', point.id, products);
      if (products && products[point.id] && products[point.id].length) {
        // bultos_restantes
        let _productsWithStock = products[point.id].filter(p => p.bultos_restantes > 0);
        let _productsWithoutStock = products[point.id].filter(p => !(p.bultos_restantes > 0));
        setProductsWithStock(_productsWithStock);
        setProductsWithoutStock(_productsWithoutStock);
        console.log('products ....', _productsWithStock, _productsWithoutStock);
      }
    }, [products]);

    return (
        <View style={[theme.screenContainer, { paddingHorizontal: themeVariables.gutter, paddingVertical: 10 }]}>
        
            {/* { !group ? <Button 
              title='Crear grupo de compra'
              containerStyle={[theme.buttonSuccessContainerStyle]}
              buttonStyle={[theme.buttonSuccessButtonStyle, {backgroundColor: themeVariables.mainComplementaryColor}]}
              titleStyle={[theme.buttonSuccessTitleStyle]}
              onPress={() => {
                dispatch(createMiniGroup())
                }}
            /> :
              <Button 
              title='Invitar a minigrupo'
              containerStyle={[theme.buttonSuccessContainerStyle]}
              buttonStyle={[theme.buttonSuccessButtonStyle]}
              titleStyle={[theme.buttonSuccessTitleStyle]}
              onPress={() => {
                navigation.push('MinigroupInvite');
                }}
            /> } */}
            <Text style={[theme.header4, {color: themeVariables.mainColor}]}>
              Productos disponibles en { point.nombre }
              {
                dayjs(shopGroup.campañas_activas[0].fin).format() < dayjs().format() ? ' (CAMPAÑA CERRADA)' : '' 
              }
            </Text>
            {
              loading && 
                <ActivityIndicator size='large' style={{ marginVertical: 15, alignSelf: 'center' }} />
            }
            {
              !loading && productsLoaded && productsWithStock && productsWithStock.length > 0 &&
                <FlatList
                    data={productsWithStock}
                    renderItem={({ item, index, separators }) => {
                      return (
                        <ProductListItem
                          navigation={navigation}
                          item={item}
                          order={orders[point.id] ? orders[point.id] : localOrders[point.id]}
                          pointId={point.id}
                          userMode={true}
                          campaingClosed={dayjs(shopGroup.campañas_activas[0].fin).format() < dayjs().format() }
                        />
                      )
                    }}
                    extraData={orders}
                    keyExtractor={item => item.id.toString()}
                />
            }
            {
              !loading && productsLoaded && productsWithoutStock && productsWithoutStock.length > 0 &&
                <Text style={[theme.header4, {color: 'red'}]}>Productos sin stock</Text>
            }
            {
              !loading && productsLoaded && productsWithoutStock && productsWithoutStock.length > 0 &&
                <FlatList
                    data={productsWithoutStock}
                    renderItem={({ item, index, separators }) => {
                      return (
                        <ProductListItem
                          navigation={navigation}
                          item={item}
                          order={orders[point.id] ? orders[point.id] : localOrders[point.id]}
                          pointId={point.id}
                          userMode={true}
                          campaingClosed={dayjs(shopGroup.campañas_activas[0].fin).format() < dayjs().format() }
                        />
                      )
                    }}
                    extraData={orders[point.id] ? orders[point.id] : localOrders[point.id]}
                    keyExtractor={item => item.id.toString()}
                />
            }
        </View>
    )
}




  

