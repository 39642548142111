import * as React from 'react';
import { 
  Text, 
  View, 
  Pressable, 
  Image 
} from 'react-native';
import {
  Button,
  Icon
} from 'react-native-elements';
import dayjs from 'dayjs';
import compaApi from '../apis/compa';
import theme, { themeVariables } from '../theme/styles';

export default function GrupoListItem ({ item, navigation, topLeftRoundBorder }) {
  return (
    <View style={{ flexDirection: 'row', marginBottom: themeVariables.gutter }}>
        <Pressable
          onPress={() => {
            navigation.push('Grupo', { group: item });
          }}
        >
          <Image 
            source={{
              uri: item && compaApi.generateImageUri(item.logo) ? compaApi.generateImageUri(item.logo) :require('../assets/logo.png')
            }}
            style={{
              width: 120,
              height: 120,
              borderRadius: themeVariables.lowBorderRadius,
              marginBottom: themeVariables.gutter,
              borderTopLeftRadius: topLeftRoundBorder
            }}
            onPress={() => {
              navigation.push('Grupo', { group: item });
            }}
          />

        </Pressable>
        <View style={{ marginLeft: themeVariables.gutter, flexShrink: 1 }}>
          <Text 
            style={ [theme.header5, {marginTop: 0}] }
            onPress={() => {
              navigation.push('Grupo', { group: item });
            }}
          >
            { item.nombre }
          </Text>
          <Text style={ [theme.normalText, { width: '100%', flexWrap: 'wrap', marginBottom: 4}] }>{ item.descripcion }</Text>
          {
            item.campañas_activas && item.campañas_activas.length > 0 && 
              <Text style={ [theme.normalText, { color: 'red' }] }>
                Fecha cierre: {dayjs(item.campañas_activas[0].fin).format('DD/MM/YY')}
                {dayjs(item.campañas_activas[0].fin).format() < dayjs().format() ? ' (CERRADA)' : '' }
              </Text>
          }
          <View style={{ height: 8 }}></View>
          {
            item.pago_online && 
              <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 3 }}>
                <Icon
                  name={'attach-money'}
                  size={16} 
                  color={themeVariables.successColor}
                />
                <Text style={[theme.normalText, { color: themeVariables.successColor, fontSize: 12, marginLeft: 3 }]}>Pago Online</Text>
              </View>
          }
          {
            item.envios && 
              <View style={{ flexDirection: 'row' }}>
                <Icon
                  name={'truck'}
                  size={16}
                  type='font-awesome'
                  color={themeVariables.dangerColor}
                />
                <Text style={[theme.normalText, { color: themeVariables.dangerColor, fontSize: 12, marginLeft: 3 }]}>Envios hasta punto de retiro</Text>
              </View>
          }
          <Button 
            type='clear'
            title='Ver más'
            buttonStyle={{
                justifyContent: 'flex-start',
                marginTop: 8
            }}
            titleStyle={[theme.normalText, {
                color: themeVariables.mainColor,
                textTransform: 'uppercase',
                marginLeft: 5,
                fontWeight: 'bold'
            }]}
            icon={
              <Icon
                name="arrow-right"
                type='font-awesome'
                size={15}
                color={themeVariables.mainColor}
              />
            }
            onPress={() => {
              navigation.push('Grupo', { group: item });
            }}
          />
        </View>
    </View>
  );
}