import * as React from 'react';
import { useSelector } from 'react-redux';
import { Text, View, Image, TouchableWithoutFeedback } from 'react-native';
import compaApi from '../../apis/compa';
import Badged from '.././ProductFeatures/Badged';
import theme, { themeVariables } from '../../theme/styles';

export default function ProductListItem ({ item, index, navigation }) {
    const user = useSelector((state) => state.auth.user);
    const isAdmin = user ? user.superAdmin : false; // [TODO: check if the admin is the owner.]
    return (
        
        <TouchableWithoutFeedback
            onPress={() => {
                navigation.push("ProductManagementScreen", { productId: item.id })
            }}
        >
            <View style={{ flexDirection: 'row', marginBottom: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#CCC', paddingTop: 4, borderTopWidth: index === 0 ? 1 : 0 }}>
                <Image
                    source={{
                        uri: compaApi.generateImageUri(item.imagen) ? compaApi.generateImageUri(item.imagen) : require('../../assets/logo.png')
                    }}
                    style={{
                        width: 75,
                        height: 75,
                        borderRadius: themeVariables.lowBorderRadius,
                        marginRight: 5
                    }}
                />
                <View style={{ flexDirection: 'row', justifyContent: 'flex-start', flex: 1  }}>
                    <View>
                        <Text style={[theme.normalText, { fontSize: 16, fontWeight: 'bold', textTransform: 'capitalize', marginTop: 5 }]}>
                            { item.nombre }
                        </Text>
                        {
                            item.bultos_restantes == 0 &&
                                <Text style={theme.leadingHighlightText}>Sin stock</Text>
                        }
                        { 
                            <>
                                <Text style={theme.leadingHighlightText}>Stock: {item.bultos_disponibles}</Text>
                            </>
                        }

                    </View>
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
}
