export function calcCenter (coords, defaultCenter) {
    if (coords && coords.length) {
      const bounds = new google.maps.LatLngBounds();
      coords.forEach((c) => {
        bounds.extend(new google.maps.LatLng(c.latitude, c.longitude));
      });
      const latlng = bounds.getCenter();
      return {
        latitude: latlng.lat(),
        longitude: latlng.lng()
      };
    } else {
      return defaultCenter;
    }
}