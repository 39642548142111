import AsyncStorage from '@react-native-async-storage/async-storage';
import { onAction } from "./event-sync";


export const STORAGE = 'STORAGE';
export const STORAGE_SET = `[${STORAGE}] set`;
export const STORAGE_GET = `[${STORAGE}] get`;

export const storageSet = (key, payload) => ({ type: STORAGE_SET, payload: { key, data: payload } });
export const storageGet = (key, success) => ({ type: STORAGE_GET, payload: { key, success } });

const storageSetMiddleware = onAction(STORAGE_SET, (store, action) => {
    const { key, data } = action.payload;
    const payload = JSON.stringify(data);
    AsyncStorage.setItem(key, payload);
})

const storageGetMiddleware = onAction(STORAGE_GET, ({ dispatch }, action) => {
    const { key, success } = action.payload;
    AsyncStorage.getItem(key).then((value) => {
        const data = JSON.parse(value);
        if (data) {
            dispatch({ type: success, payload: data });
        }
    })
})

export const asyncStorageMiddleware = [
    storageGetMiddleware,
    storageSetMiddleware
]