import * as React from 'react';
import { View } from 'react-native';

import {
    Text
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import {
    Button
} from 'react-native-elements';
import { getProducts } from '../../redux/producer';
import { FlatList } from 'react-native';
import theme, { themeVariables } from '../../theme/styles';
import ProducerProductListItem from '../../components/producer/ProducerProductListItem';
import { ScrollView } from 'react-native';
export default function ProducerScreen({ navigation, route }) {

    const dispatch = useDispatch();

    const products = useSelector(state => state.producer.products).sort((a, b) => (a.bultos_pedidos > b.bultos_pedidos) ? -1 : 1)


    React.useEffect(() => {
        dispatch(getProducts());
    }, []);

    return (
        <View style={{ flex: 1, backgroundColor: 'white', padding: themeVariables.gutter }}>
            <ScrollView>

                <Text style={theme.header6}>Pedidos de productos en campaña</Text>
                {
                    products && products.length > 0 &&
                        <FlatList
                            data={products}
                            renderItem={({ item, index, separators }) => {
                                return (
                                    <ProducerProductListItem
                                        navigation={navigation}
                                        item={item} />
                                )
                            }}
                            keyExtractor={item => item.id.toString()}
                        />
                }
                {
                    !(products && products.length > 0) &&
                        <Text>No tienes productos en la campaña actual.</Text>
                }
            </ScrollView>
            
        </View>

    );
}