import * as React from 'react';
import theme, { themeVariables } from '../theme/styles';
import {
    View,
    Text
} from 'react-native';

export default function HorarioItem ({ label, time }) {
    return (
        <View style={{ flexDirection: 'row' }}>
            <Text style={[theme.normalText, { width: 40, fontWeight: 'bold' }]}>{ label }</Text>
            { time &&
                <Text style={{ flex: 1 }}>{ time }</Text> 
            }
            { !time &&
                <Text style={{ flex: 1, color: 'red' }}>Cerrado</Text> 
            }
        </View>
    );
}