import * as React from 'react';

import {
    View,
    Text,
    FlatList
} from 'react-native';
import * as Linking from 'expo-linking';
import {
    Icon,
    Button,
    Input,
    Divider
} from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClaims } from '../../redux/claims';
import theme, { themeVariables } from '../../theme/styles';
import ClaimLineListItem from '../../components/Claims/ClaimLineListItem';


export default function ClaimManagementScreen({ navigation, route }) {
    const dispatch = useDispatch();
    const claims = useSelector(state => state.pointManagement.claims);
    const claimId = route.params && route.params.claimId ? route.params.claimId : null;
    const claim = claims.filter(claim => claim.id == claimId)[0];


    React.useEffect(() => {
        dispatch(getAllClaims());
    }, []);

    return (
        <View style={{ paddingHorizontal: themeVariables.gutter, flex: 1, backgroundColor: 'white' }}>
            <Text style={ theme.header4 }>Solicitante: {claim && claim.user ? claim.user.name : ""}</Text>
            <Divider style={{ marginVertical: 5 }} />
            <FlatList
                data={claim ? claim.linea_reclamos : []}
                renderItem={({ item, index, separators }) => (
                    <ClaimLineListItem
                        item={item}
                        navigation={navigation}
                        admin={true}>
                    </ClaimLineListItem>
                )}
                keyExtractor={item => item.id.toString()}
            />
        </View>
    );
}