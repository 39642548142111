import * as React from 'react';

import {
    Text,
    View,
    ActivityIndicator,
    FlatList
} from 'react-native';

import { useSelector, useDispatch } from 'react-redux';
import theme, { themeVariables } from '../theme/styles';
import { getBalance } from '../redux/user';
import  SummaryListItem  from '../components/summaryListItem';
export default function BalanceScreen({ navigation, route }) {
    const dispatch = useDispatch();

    const summary = useSelector(state => state.user.summary);
    const summaryTotal = useSelector(state => state.user.summaryTotal);

    const pagos = summary && summary.pagos ? summary.pagos : [];
    const reclamos = summary && summary.reclamos ? summary.reclamos : [];
    const pedidos = summary && summary.pedidos ? summary.pedidos : [];

    pagos.forEach(pago => {
        pago.type = "pago";
    });
    reclamos.forEach(reclamo => {
        reclamo.type = "reclamo";
    });
    pedidos.forEach(pedido => {
        pedido.type = "pedido";
    });
    const list = [...pagos, ...reclamos, ...pedidos].sort((a, b) => {
        if (a.created_at < b.created_at) return 1;
        if (a.created_at > b.created_at) return -1;
        return 0;
    });

    React.useEffect(() => {
        dispatch(getBalance());
    }, []);

    return (
        <View style={[theme.screenContainer, { padding: themeVariables.gutter }]}>
            <Text style={theme.header4}>Cuenta corriente (saldo)</Text>
            <Text style={theme.header8}>$ { summaryTotal }</Text>
            <Text style={theme.header4}>Últimos movimientos</Text>
            <FlatList
                data={list}
                renderItem={({ item, index, separators }) => {
                    return (
                        <SummaryListItem
                            navigation={navigation}
                            item={item}
                        />
                    )
                }}
                keyExtractor={item => item.id.toString()}
            />
        </View>
    );
}