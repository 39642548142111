export const APP = 'APP';

export const APP_INIT = `[${APP}] init`;
export const APP_COMPLETED = `[${APP}] completed`;

export const appInit = () => ({ type: APP_INIT, payload: {} });
export const appCompleted = () => ({ type: APP_COMPLETED, payload: {} });

const initialState = {
    loaded: false
};

export function AppReducer(state = initialState, action) {
    switch (action.type) {
        case APP_COMPLETED:
            return {
                ...state,
                loaded: true
            };
        default:
            return state;
    }
}
