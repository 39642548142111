import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import { onAction } from './middleware/event-sync';
// import { useFonts, Lato_400Regular, Lato_700Bold, Lato_400Regular_Italic } from '@expo-google-fonts/lato';
/* export default () => {
    let [fontsLoaded] = useFonts();*/

export const RESOURCES = 'RESOURCES';
export const RESOURCES_LOADING = `[${RESOURCES}] loading`;
export const RESOURCES_LOADED = `[${RESOURCES}] loaded`;

export const resourceLoaded = () => ({ type: RESOURCES_LOADED, payload: {} });

const fonts = {
    /* Lato_400Regular,
    Lato_400Regular_Italic,
    Lato_700Bold */
}

const resourceLoading = onAction(RESOURCES_LOADING, (store, action) => {
    Font.loadAsync({
        ...Ionicons.font,
        ...fonts
    }).then(() => {
        console.log('RESOURCES_LOADED');
        store.dispatch(resourceLoaded());
    }).then(() => {
        console.log('failed RESOURCES_LOADED');
    });
});

export const resourceMiddleware = [resourceLoading];
