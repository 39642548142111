import * as React from 'react';

import {
    View,
    Text,
    TouchableWithoutFeedback
} from 'react-native';
import {
    Button,
    Icon
} from 'react-native-elements';
import theme, { themeVariables } from '../theme/styles';
import { useDispatch, useSelector } from 'react-redux';
import {createClaim, getAllClaims } from '../redux/claims';

import { displayOrderStatusName } from '../utils/helpers';

export default function PastOrderListItem ({ item, navigation }) {
    const dispatch = useDispatch();
    if (item) {
      return (
          <View>
              <TouchableWithoutFeedback
                onPress={() => {
                  navigation.push('PastOrderDetailScreen', { order: item.id });
                }}
              >
                <View>
                  <View>
                      <Text style={theme.header5}>Point: {item.points && item.points.nombre}</Text>
                      <Text style={theme.highlightText}>Estado: { displayOrderStatusName(item.estado) }</Text>
                      <Text style={theme.leadingHighlightText}>Total pedido: $ {item.precio_total}</Text>
                  </View>
                  <Icon 
                    name='chevron-right'
                    size={24}
                    color='#BBB'
                    containerStyle={{
                      position: 'absolute',
                      top: 20,
                      right: 10
                    }}
                  />
                </View>
              </TouchableWithoutFeedback>
                  
              <Button 
                title='Reclamar sobre este pedido'
                containerStyle={[theme.buttonSuccessContainerStyle]}
                buttonStyle={[theme.buttonSuccessButtonStyle]}
                titleStyle={[theme.buttonSuccessTitleStyle]}
                onPress={() => {
                  dispatch(createClaim(item.id));
                  navigation.push('ClaimScreen', { orderId: item.id });
                }}
              />
          </View>
          
      );
        
    }
    return <></>;
}
  