import * as React from 'react';
import { Text, View, Image, TouchableWithoutFeedback,Button } from 'react-native';
import theme, { themeVariables } from '../../theme/styles';
import { useSelector, useDispatch } from 'react-redux';
import { acceptMinigroupInvite } from '../../redux/miniGroups';

export default function InviteListItem({ item, navigation }) {
    const dispatch = useDispatch();
    return (
        <View style={{ flexDirection: 'row', marginBottom: 15, paddingBottom: 15, borderBottomWidth: 1, borderBottomColor: '#CCC', paddingTop: 4 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start', flex: 1 }}>
                <View>
                    <Text style={[theme.header5, { marginTop: 0 }]}>{item.idusuariogrupo.name}</Text>
                </View>
            </View>
            <Button
                title='aceptar'
                onPress={() => {
                    dispatch(acceptMinigroupInvite(item.idUsuarioGrupo));
                }}
            ></Button>
            <Button
                title='rechazar'
                onPress={() => {
                    //TODO:
                }}
            ></Button>
        </View>
    );
}
