import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FlatList, Text, View, StyleSheet, Image } from 'react-native';
import theme, { themeVariables } from '../../theme/styles';
import DeliveryOrderListItem from '../../components/PointManagement/DeliveryOrderListItem';
import { getAllPointOrders } from '../../redux/pointManagement'

import {
  Icon,
  Button
} from 'react-native-elements';
import UserOrderListItem from '../../components/PointManagement/UserOrderListItem';

import dayjs from 'dayjs';

export default function DeliveryOverviewScreen({ navigation, route }) {

  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);
  const pointOrders = useSelector(state => state.pointManagement.orders);
  const pointOrdersLoaded = useSelector(state => state.pointManagement.loadedOrders);

  React.useEffect(() => {
    // dispatch(getAllPointOrders(user.point_id));
  }, []);

  return (
    <View style={[theme.screenContainer, { paddingHorizontal: themeVariables.gutter, paddingVertical: 10 }]}>
      <Text style={theme.header4}>Campaña { pointOrders && pointOrders.length > 0 && ( ' - Cierra el: ' + dayjs(pointOrders[0].campaña.fin).format('DD/MM/YYYY')) }</Text>
      <Text style={theme.header4}>Por Entregar</Text>
      { pointOrdersLoaded && <FlatList
        data={pointOrders.filter(o => o.estado !== 3)}
        renderItem={({ item, index, separators }) => {
          return (
            <UserOrderListItem
              navigation={navigation}
              item={item}
            />
          )
        }}
        keyExtractor={(item, index) => index.toString()}
      />}
      <Text style={theme.header4}>Entregados</Text>
      { pointOrdersLoaded && <FlatList
        data={pointOrders.filter(o => o.estado === 3)}
        renderItem={({ item, index, separators }) => {
          return (
            <UserOrderListItem
              navigation={navigation}
              item={item}
            />
          )
        }}
        keyExtractor={(item, index) => index.toString()}
      />}
    </View>
  )
}






