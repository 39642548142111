import * as React from 'react';
import {Text} from 'react-native';
import theme, { themeVariables } from '../../theme/styles';
export default function IconTextItem ({ item }) {
    return (
        <Text style={{marginBottom: 10}}>
            {
                item.producto_campaña.es_oferta > 0 &&
                    <Text style={[theme.badgedText, {backgroundColor: themeVariables.badgedOfferColor}]}>Oferta</Text>
            }
            {
                item.producto_campaña.es_nuevo > 0 &&
                    <Text style={[theme.badgedText, {backgroundColor: themeVariables.badgedNewColor}]}>Novedad</Text>
            }
            {
                item.producto_campaña.es_organico > 0 &&
                    <Text style={[theme.badgedText, {backgroundColor: themeVariables.badgedOrganicColor}]}>Orgánico</Text>
            }
            {
                item.producto_campaña.es_agroecologico > 0 &&
                    <Text style={[theme.badgedText, {backgroundColor: themeVariables.badgedAgroEcologicColor}]}>Agroecológico</Text>
            }
        </Text>
    );
}