import * as React from 'react';

import {
    View,
    Text
} from 'react-native';
import {
    Button
} from 'react-native-elements';
import theme, { themeVariables } from '../theme/styles';

import dayjs from 'dayjs';

export default function SummaryListItem ({ item, navigation }) {
    const price = item.type == "pedido" || item.type == "reclamo" ? item.precio_total : item.monto;
    const estoSuma = (item.type == "reclamo" || item.type == "pago");
    return (
        <View style={[theme.horizontalContainer, { justifyContent: 'space-between' }]}>
            <View>
                <Text style={[theme.header5, { textTransform: 'capitalize' }]}>
                    <Text>{ item.type }</Text>
                </Text>
                <Text style={theme.normalText}>{ dayjs(item.fecha).format('DD/MM/YY HH:mm') }</Text>
            </View>
            <Text style={[theme.leadingHighlightText, { color: estoSuma ? 'green' : 'red' }]}>
                <Text>$ {estoSuma ? '' : '-'}</Text>
                <Text>{price}</Text>
            </Text>
        </View>
    );
}
  