import React from 'react';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import { Provider, useSelector } from 'react-redux';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Navigation from './navigation';


import store from './redux';
import { appInit } from './redux/app';


// import * as Analytics from 'expo-firebase-analytics';

import { getDeviceState, deviceSet } from './redux/device';

import {
    Dimensions
} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

function AppWithStore() {
    const isLoaded = useSelector((state) => state.app.loaded);
    if (isLoaded) {
        SplashScreen.hideAsync();
        return (
            <SafeAreaProvider>
                <Navigation />
                <StatusBar backgroundColor={'#333'} />
            </SafeAreaProvider>
        );
    }
    return <></>;
}

export default function App() {
    // firebase.logout();
    // AsyncStorage.clear();
    AsyncStorage.removeItem('series_cache_timestamp');
    SplashScreen.preventAutoHideAsync();
    store.dispatch(appInit());

    Dimensions.addEventListener('change', () => {
        const device = getDeviceState();
        console.log('DEVICE__SET');
        store.dispatch(deviceSet(device));
    });
    return (
        <Provider store={store}>
            <AppWithStore />
        </Provider>
    );
}
/*

<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-172691720-1"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-172691720-1');
</script>
*/