import {getAllPointOrders} from './pointManagement.js'
import compaApi from '../apis/compa';
import { PRODUCTS_SET_RESULT_PRODUCTS } from './products';

export const ORDER = 'ORDER';

export const ORDER_LOADING = `[${ORDER}] orders`;

export const ORDER_LOADED = `[${ORDER}] loaded`;

export const ORDER_SET_RESULT_ORDER = `[${ORDER}] set result orders`;

export const ORDER_SET_RESULT_PAST_ORDERS = `[${ORDER}] set result past orders`;

export const ORDER_SET_CHANGE_ORDER_STATE = `[${ORDER}] set new order state`;

export const ORDER_SET_DELIVER_RESULT = `[${ORDER}] set deliver result`;

export const PRODUCT = 'PRODUCT'

export const PRODUCT_ADDED = `[${PRODUCT}] added`

export const PRODUCT_SET_RESULT_PRODUCT = `[${PRODUCT}] set result product`

export const setResultOrders = (result, data) => ({
    type: ORDER_SET_RESULT_ORDER,
    payload: { result, data }
});

export const setResultPastOrders = (result) => ({
    type: ORDER_SET_RESULT_PAST_ORDERS,
    payload: result
});

export const setOrdersLoading = (isLoading) => ({
    type: ORDER_LOADING,
    payload: isLoading
});

export const setOrdersLoaded = (loaded) => ({
    type: ORDER_LOADED,
    payload: loaded
});

export const setResultProduct = (loaded) => ({
    type: PRODUCT_SET_RESULT_PRODUCT,
    payload: loaded
});

export const setProductAdded = (loaded) => ({
    type: PRODUCT_ADDED,
    payload: loaded
});

export const setOrderNewState = (orderId, newState) => ({
	type: ORDER_SET_CHANGE_ORDER_STATE,
	payload: {orderId, newState}
});


export const setDeliverResult = (result) => ({
	type: ORDER_SET_DELIVER_RESULT,
	payload: result
});


export const getAllOrders = () => {
    return (dispatch) => {
        dispatch(setOrdersLoading(true));
        compaApi.orders
            .getAllOrders()
            .then((result) => {
                dispatch(
                    setResultOrders(result)
                );
                dispatch(setOrdersLoading(false));
                dispatch(setOrdersLoaded(true));
            })
            .catch(() => {
                dispatch(setOrdersLoading(false));
            });
    };
};

export const getPastOrders = () => {
    return (dispatch) => {
        dispatch(setOrdersLoading(true));
        compaApi.orders
            .getPastOrders()
            .then((result) => {
                dispatch(
                    setResultPastOrders(result)
                );
                dispatch(setOrdersLoading(false));
                dispatch(setOrdersLoaded(true));
            })
            .catch(() => {
                dispatch(setOrdersLoading(false));
            });
    };
};

export const confirmOrder = (orderId) => {
    return (dispatch) => {
        let data = {
            pedido: orderId
        }
        compaApi.orders
            .confirmOrder(data)
            .then((result) => {
                //TODO: dispatchs
            })
            .catch(() => {
            });
    };
};


export const deliverOrder = (orderId, orderLines) => {
    return (dispatch) => {
        dispatch(setOrdersLoading(true));
        let data = {
            pedido: orderId,
            lineas: orderLines
        };
        compaApi.orders
            .deliverOrder(data)
            .then((result) => {
                dispatch(setOrderNewState(orderId, 3));
                dispatch(getAllPointOrders());
            	dispatch(setOrdersLoading(false));
            	dispatch(setOrdersLoaded(true));
                dispatch(setDeliverResult('Los datos fueron procesados correctamente.'));
                
            })
            .catch((err) => {
                console.log(err);
            	dispatch(setOrdersLoading(false));
                dispatch(setDeliverResult('Hubo un error al marcar como entregado el pedido.'));
            });
    };
};

export const modifyProduct = (productId, amount, replace) => {
    return (dispatch) => {
        let data = {
            producto: productId,
            unidades: amount,
            sustitucion: replace
        }
        compaApi.orders
            .addProduct(data)
            .then((result) => {;
                // dispatch(getAllOrders());
                dispatch(setResultOrders(result, data));
            })
            .catch(() => {
            });
    };
};


const initialState = {
    orders: [],
    pastOrders: [],
    loaded: false,
    isLoading: false,
    deliveredResult: null
};

export function OrdersReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ORDER_SET_RESULT_ORDER:
            let ordersByPoint = {};
            let itemsCount = 0;
            let _result = payload.result;
            let _data = payload.data;
            for(let key in _result) {
                ordersByPoint[_result[key].point_id] = _result[key];
                if (_result[key].estado != 3 && _result[key].estado != 2) {
                    itemsCount += _result[key].linea_pedidos.length;
                }
            }     
            return { ...state, orders: ordersByPoint, orderItemCounts: itemsCount };
        case ORDER_SET_RESULT_PAST_ORDERS:
            let pastOrdersByPoint = {};
            for(let key in payload){
                pastOrdersByPoint[payload[key].point_id] = payload[key];
            }            
            return { ...state, pastOrders: pastOrdersByPoint };
        case ORDER_LOADED:
            return { ...state, loaded: payload };
        case ORDER_LOADING:
            return { ...state, isLoading: payload };
        case ORDER_SET_DELIVER_RESULT:
            return { ...state, deliveredResult: payload };
        case ORDER_SET_CHANGE_ORDER_STATE:
			 let _orders = Array.isArray(state.orders) ? state.orders.slice(0) : [];
             for (let point_id in _orders) {
             	if (Object.hasOwnProperty.call(_orders, point_id)) {
             		let _order = _orders[point_id];
             		if (_order.id === payload.orderId) {
             			_order.estado = payload.newState;
             		}
             	}
             }
          	 return { ...state, orders: _orders };
        default:
            return state;
    }
};
