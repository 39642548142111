import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, View, Image, TouchableWithoutFeedback, Button } from 'react-native';
import compaApi from '../../apis/compa';
import theme, { themeVariables } from '../../theme/styles';
import { pauseProduct } from '../../redux/producer';

export default function ProducerProductListItem({ item, navigation, order, pointId, userMode = false }) {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);

    React.useEffect(() => {
        // dispatch(getMinigroup());
    }, [])

    return (

        <View style={{ flexDirection: 'row', marginBottom: 15, paddingBottom: 15, borderBottomWidth: 1, borderBottomColor: '#CCC', paddingTop: 4 }}>
            <Image
                source={{
                    uri: compaApi.generateImageUri(item.logo) ? compaApi.generateImageUri(item.logo) : require('../../assets/logo.png')
                }}
                style={{
                    width: 75,
                    height: 75,
                    borderRadius: themeVariables.lowBorderRadius
                }}
            />
            <View style={{ flexDirection: 'row', justifyContent: 'start', flex: 1, flexWrap: 'wrap', paddingLeft: 4 }}>
                <View style={{ flexDirection: 'column', justifyContent: 'start', flex: 1, flexWrap: 'wrap', paddingLeft: 4 }}>
                    <Text style={theme.header6}>{item.nombre}</Text>
                    <Text>Bultos pedidos: {item.bultos_pedidos}</Text>
                    <Text>Peso pedido: {item.bultos_pedidos * item.peso_bulto} kg.</Text>
                </View>
            </View>
            <Button
                title={item.pausado ? "Activar" : "Desactivar"}
                onPress={() => {
                    dispatch(pauseProduct(item.id));
                }}
            ></Button>
        </View>

    );
}
