
function dispatchCallbackWrapper(callback, store, action) {
    if (typeof callback === 'function') {
        callback(store, action);
    } else if (typeof callback === 'string') {
        store.dispatch({ type: callback });
    } else if (Array.isArray(callback)) {
        callback.forEach((event) => {
            store.dispatch({ type: event });
        })
    }
}

export function onAction(eventType, callback) {
    return store => next => action => {
        next(action);
        const { type, payload, meta } = action;
        if (type === eventType) {
            dispatchCallbackWrapper(callback, store, action);
        }
    }
}


export function onEveryAction(eventTypes, callback) {
    function initialState() {
        const eventOccurence = eventTypes.reduce((acc, event) => { acc[event] = false; return acc; }, {});
        return eventOccurence;
    }
    let eventOccurence = initialState();
    return store => next => action => {
        const { type, payload, meta } = action;
        next(action);

        if (type in eventOccurence) {
            eventOccurence[type] = true;

            const values = Object.values(eventOccurence);
            const allOccurs = values.every(b => b);

            if (allOccurs) {
                eventOccurence = initialState();
                dispatchCallbackWrapper(callback, store, action);
                // callback(store, action);
            }
        }
    }
}