import React, { useEffect } from 'react';
import { doLogin, authSetResult } from '../../redux/auth';
import { Text, View, Image, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon } from 'react-native-elements';
import theme, { themeVariables } from '../../theme/styles';
import Toast from 'react-native-toast-message';

export default function LoginScreen ({ navigation }) {
    const dispatch = useDispatch();

    const { register, handleSubmit, setValue, errors } = useForm();
    useEffect(() => {
        register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i });
        register('password', { required: true });
    }, [register]);

    const onSubmit = (data) => {
      console.log("submit");
      dispatch(doLogin(data.email, data.password));
    };

    const loginResult = useSelector(state => state.auth.result);
    useEffect(() => {
      console.log('loginResult', loginResult);
      if (loginResult && loginResult.status === 'error') {
          
          Toast.show({
            type: 'error',
            text1: 'CompaApp - Login ',
            text2: loginResult.message
          });
          setTimeout(() => {
            dispatch(authSetResult(null));
          }, 500);
      }
    }, [loginResult]);



    
    return (
    <ScrollView style={theme.verticalContainer, theme.fullWidth}>
      <View style={[theme.verticalContainer, theme.topCard, theme.bigShadow, theme.fullWidth, { minHeight: 500 }]}>
        <Image 
          source={{ uri: require('../../assets/logo-square.png') }}
          style={theme.logo}
        />
        <Text style={[theme.header2]}>
          Login
        </Text>
        <View style={theme.loginInputContainerStyle}>
          <Icon
            name='email'
            size={24} 
            color={themeVariables.mainColor}
            style={theme.loginIconInputStyle}
          ></Icon>
          <TextInput
            style={theme.loginInputStyle}
            placeholderTextColor={themeVariables.mainColor}
            onChangeText={text => setValue('email', text)}
            placeholder="Email"
            textContentType="emailAddress"
            autoCompleteType="email"
            ></TextInput>
        </View>
        <View style={theme.loginInputContainerStyle}>
          <Icon
            name='lock'
            size={24} 
            color={themeVariables.mainColor}
            style={theme.loginIconInputStyle}
          ></Icon>
          <TextInput
            style={theme.loginInputStyle}
            placeholderTextColor={themeVariables.mainColor}
            onChangeText={text => setValue('password', text)}
            placeholder="Contraseña"
            secureTextEntry={true}
            textContentType="password"
            autoCompleteType="password"
            ></TextInput>
        </View>
        <View style={{flex: 1}}>
          { errors && errors.email &&
            <Text style={{ color: 'red' }}>El email ingresado no es válido.</Text>
          }
          { errors && errors.password &&
            <Text style={{ color: 'red' }}>La contraseña no puede estar vacía.</Text>
          }
        </View>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate('RememberPassword')
          }
        >
            <Text
              style={[theme.normalText, {color: themeVariables.mainColor, marginTop: 20}]}
            >
              ¿Olvidó su contraseña?
            </Text>
        </TouchableOpacity>
        <Button
          containerStyle={[theme.buttonSuccessContainerStyle, {width: "90%", marginBottom: 30, borderRadius: 30}]}
          buttonStyle={[theme.buttonSuccessButtonStyle, {paddingVertical: 16, paddingRight: 26, fontWeight: 'bold'}]}
          titleStyle={[theme.buttonSuccessTitleStyle]}
          icon={
            <Icon 
              name='arrow-forward'
              size={14} 
              color='white'
              style={{
                paddingTop: 2,
                paddingRight: 12
              }}  
            />
          }
          onPress={handleSubmit(onSubmit)}
          title="Ingresar"
        />
      </View>
      <View style={{marginVertical: 28}}>
        <TouchableOpacity
          onPress={() =>
            navigation.push('Register')
          }
        >
          <Text
            style={[theme.normalText, theme.registerButton]}
          >
            Crear cuenta
          </Text>
        </TouchableOpacity>
      </View>
    </ScrollView>);
}


function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(String(email).toLowerCase());
}